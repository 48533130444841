/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import FavoriteIcon from '@mui/icons-material/Favorite';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import RoutesNames from "helpers/enums/routes-map.enum";
import proposalPDF from "assets/files/Predlog-za-partnere.pdf";

import { useTranslation } from "react-i18next";


function Footer({ company, links }) {
  const { t, i18n } = useTranslation();
  const { href, name } = company;
  const { size } = typography;

  const defaultLinks = [
    { href: RoutesNames.HOME, name: t('navbar.home')},
    { href: RoutesNames.ABOUT_US, name: t('navbar.about') },
    { href: proposalPDF, name: t('navbar.proposalForPartners') },
    { href: RoutesNames.HOME, name: t('navbar.contact') }, // TODO
  ];

  const renderLinks = () =>
    defaultLinks.map((link) => (
      <SoftBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </SoftTypography>
        </Link>
      </SoftBox>
    ));

  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {t('footer.madeWithLove')}
        <SoftBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <FavoriteIcon />
        </SoftBox>
        {t('footer.by')}
        <Link href={href} target="_blank">
          <SoftTypography variant="button" fontWeight="medium">
            &nbsp;{name}&nbsp;
          </SoftTypography>
        </Link>
      </SoftBox>
      {/* <SoftBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </SoftBox> */}
    </SoftBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: RoutesNames.HOME, name: "MoveOn" },
  links: [],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
