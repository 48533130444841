/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

// @mui icons
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Data
// Images
import RoutesNames from "helpers/enums/routes-map.enum";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreateGetRequest } from "helpers/back-request/helpers";
import { Link } from "react-router-dom";
import HeaderMenu from "components/common/HeaderMenu";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import Sidenav from "../side-nav";
import BusinessItemCard from "examples/Cards/BusinessItemCard";
import AddIcon from '@mui/icons-material/Add';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useTranslation } from "react-i18next";
import { BusinessItemStatus } from "helpers/enums/business-item.enum";
import breakpoints from "assets/theme/base/breakpoints";
import loader from "assets/images/moveon/loader.gif";
import placeholderMainImage from "assets/images/moveon/placeholder-main-image.svg";

function BusinessList() {
  const { t, i18n } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);

  const [activeBusinessItems, setActiveBusinessItems] = useState([]);
  const [pendingBusinessRequests, setPendingBusinessRequests] = useState([]);
  const [businessArchive, setBusinessArchive] = useState([]);

  const [tabValue, setTabValue] = useState('active-businesses-tab');
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");


  const getBusinessItems = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEMS_FOR_USER + localStorage.getItem("userId"));
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
      // openErrorSB(GetErrorMessage(error), true);
    }
  };

  const updateAfterDelete = async (deletedBusinessItemId) => {
    const deletedBusinessItem = activeBusinessItems.find((item) => item.id == deletedBusinessItemId);
    deletedBusinessItem.status = BusinessItemStatus.DELETED;
    businessArchive.push(deletedBusinessItem);
    setBusinessArchive(businessArchive);
    setActiveBusinessItems(activeBusinessItems.filter((item) => item.id != deletedBusinessItemId));
  };

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const businessItemsArray = await getBusinessItems();
      let arrayActiveBusinesses = [];
      let arrayPendingBusinessRequests = [];
      let archivedBusinessesArray = [];
      if (businessItemsArray) {
        for (let i = 0; i < businessItemsArray.length; i++) {
          if(businessItemsArray[i].mediaPayload?.mainImage) {
            const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessItemsArray[i].mediaPayload?.mainImage, { responseType: 'blob' });
            businessItemsArray[i].image = URL.createObjectURL(imageResponse.data);
          } else {
            businessItemsArray[i].image = placeholderMainImage;
          }
          switch (businessItemsArray[i].status) {
            case BusinessItemStatus.ACTIVE:
              arrayActiveBusinesses.push(businessItemsArray[i]);
              break;
            case BusinessItemStatus.PENDING_APPROVAL:
              arrayPendingBusinessRequests.push(businessItemsArray[i]);
              break;
            case BusinessItemStatus.REJECTED:
            case BusinessItemStatus.DELETED:
              archivedBusinessesArray.push(businessItemsArray[i]);
              break;
          }
        }
        setActiveBusinessItems(arrayActiveBusinesses);
        setPendingBusinessRequests(arrayPendingBusinessRequests);
        setBusinessArchive(archivedBusinessesArray);
      }
      setShowLoader(false);
    })();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <PageLayout>
      <SoftBox xs={12} md={12} lg={12} align="center">
        <HeaderMenu />
      </SoftBox>
      <SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
      })}>
        {!showLoader &&
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <SoftBox mb={3}>
                <Card>
                  <SoftBox p={3} pb={0}>
                    <SoftTypography variant="h5">{t('businessList.title')}</SoftTypography>
                  </SoftBox>
                  <SoftBox p={3}>
                    <TabContext value={tabValue}>
                      <Tabs value={tabValue} onChange={handleTabChange} orientation={tabsOrientation} variant="scrollable">
                        <Tab label={t('businessList.activeBusinesses')} value="active-businesses-tab" />
                        <Tab label={t('businessList.pandingBusinessRequests')} value="pending-business-requests-tab" />
                        <Tab label={t('businessList.archive')} value="business-archive-tab" />
                      </Tabs>
                      <TabPanel value="active-businesses-tab">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} xl={3}>
                            <Link to={RoutesNames.NEW_BUSINESS}>
                              <PlaceholderCard title={{ variant: "h5", text: t('businessList.addNew') }} outlined icon={<AddIcon />} />
                            </Link>
                          </Grid>
                          {activeBusinessItems && activeBusinessItems?.map((businessItem, index) => (
                            <Grid item xs={12} md={6} xl={3} key={index}>
                              <BusinessItemCard
                                id={businessItem.id}
                                image={businessItem.image}
                                name={businessItem.name}
                                status={businessItem.status}
                                type={businessItem.type}
                                showActions
                                updateAfterDelete={updateAfterDelete}
                              />
                            </Grid>
                          ))}

                        </Grid>
                      </TabPanel>
                      <TabPanel value="pending-business-requests-tab">
                        {pendingBusinessRequests && pendingBusinessRequests.length == 0 &&
                          <SoftTypography textAlign="center" variant="body2" m={2}>{t('businessList.noData')}</SoftTypography>}
                        <Grid container spacing={3}>
                          {pendingBusinessRequests && pendingBusinessRequests?.map((businessItem, index) => (
                            <Grid item xs={12} md={6} xl={3} key={index}>
                              <BusinessItemCard
                                id={businessItem.id}
                                image={businessItem.image}
                                name={businessItem.name}
                                status={businessItem.status}
                                type={businessItem.type}
                                showActions
                              />
                            </Grid>
                          ))}

                        </Grid>
                      </TabPanel>
                      <TabPanel value="business-archive-tab">
                        {businessArchive && businessArchive.length == 0 &&
                          <SoftTypography textAlign="center" variant="body2" m={2}>{t('businessList.noData')}</SoftTypography>}
                        <Grid container spacing={3}>
                          {businessArchive && businessArchive?.map((businessItem, index) => (
                            <Grid item xs={12} md={6} xl={3} key={index}>
                              <BusinessItemCard
                                id={businessItem.id}
                                image={businessItem.image}
                                name={businessItem.name}
                                status={businessItem.status}
                                type={businessItem.type}
                                showActions
                                showStatus
                              />
                            </Grid>
                          ))}

                        </Grid>
                      </TabPanel>
                    </TabContext>

                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
          </Grid>
        }
        {showLoader &&
          <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
            <img src={loader} alt="Loading..." className="loader" />
          </SoftBox>}
        {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
        {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default BusinessList;
