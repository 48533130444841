import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

function ButtonsSection({activeStep, isLastStep, handleBack, isSubmitting}) {
    return (
        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
        {activeStep === 0 ? (
          <SoftBox />
        ) : (
          <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
            back
          </SoftButton>
        )}
        <SoftButton
          variant="gradient"
          color="dark"
          disabled={isSubmitting}
          type="submit"
        >
          {isLastStep ? "send" : "next"}
        </SoftButton>
      </SoftBox>
    );
}

export default ButtonsSection;
