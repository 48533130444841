/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import WavedBlogCard from "examples/Cards/BlogCards/WavedBlogCard";

// Soft UI Dashboard PRO React example components
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Images
import teretana1 from "assets/images/moveon/teretane/teretana1.jpg";
import teretana2 from "assets/images/moveon/teretane/teretana2.jpg";
import teretana3 from "assets/images/moveon/teretane/teretana3.jpg";
import teretana4 from "assets/images/moveon/teretane/teretana4.jpg";
import teretana5 from "assets/images/moveon/teretane/teretana5.jpg";

function BlogContent() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

  return (
    <SoftBox position="relative"
             zIndex={0} mt={9}
             px={{ xs: 1, sm: 0 }}
             width="100%"
             align="justify">
        <Grid item xs={12} md={12} lg={12} padding="40px">
            <SoftTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                Nove aktivnosti
            </SoftTypography>
            <SoftBox mb={2}>
                <SoftTypography variant="body2" align="center" color="text">
                    Ovde mozete pogledati nova desavanja, interesantne stvari vezano za sport...
                </SoftTypography>
            </SoftBox>
        </Grid>


        <Grid item xs={12} md={12} lg={12} padding="40px">
            <Grid item xs={12} md={6} lg={10} >
                <SoftBox mt={2}>
                    <Carousel responsive={responsive}>
                        <div style={{margin:15}}><Grid item xs={12} md={6} lg={2}>
                            <WavedBlogCard
                                image={teretana1}
                                title="Odbojka u vodi"
                                description="One of the most beautiful and complex dashboards built by the team behind Creative Tim. That's pretty impressive."
                                action={{
                                    type: "internal",
                                    route: "/somewhere",
                                    color: "info",
                                    label: "Detaljnije"
                                }}
                            />
                        </Grid></div>
                        <div style={{margin:15}}><Grid item xs={12} md={6} lg={2}>
                            <WavedBlogCard
                                image={teretana2}
                                title="Fudbal po cokoladi"
                                description="One of the most beautiful and complex dashboards built by the team behind Creative Tim. That's pretty impressive."
                                action={{
                                    type: "internal",
                                    route: "/somewhere",
                                    color: "info",
                                    label: "Detaljnije"
                                }}
                            />
                        </Grid></div>
                        <div style={{margin:15}}><Grid item xs={12} md={6} lg={2}>
                            <WavedBlogCard
                                image={teretana3}
                                title="Plivanje u zelatinu"
                                description="One of the most beautiful and complex dashboards built by the team behind Creative Tim. That's pretty impressive."
                                action={{
                                    type: "internal",
                                    route: "/somewhere",
                                    color: "info",
                                    label: "Detaljnije"
                                }}
                            />
                        </Grid></div>
                        <div style={{ margin:15}}><Grid item xs={12} md={6} lg={2}>
                            <WavedBlogCard
                                image={teretana4}
                                title="Trcanje sa preponama unazad"
                                description="One of the most beautiful and complex dashboards built by the team behind Creative Tim. That's pretty impressive."
                                action={{
                                    type: "internal",
                                    route: "/somewhere",
                                    color: "info",
                                    label: "Detaljnije"
                                }}
                            />
                        </Grid></div>
                        <div style={{ margin:15}}><Grid item xs={12} md={6} lg={2}>
                            <WavedBlogCard
                                image={teretana5}
                                title="Ginisov rekord u jedenju loptica"
                                description="One of the most beautiful and complex dashboards built by the team behind Creative Tim. That's pretty impressive."
                                action={{
                                    type: "internal",
                                    route: "/somewhere",
                                    color: "info",
                                    label: "Detaljnije"
                                }}
                            />
                        </Grid></div>
                    </Carousel>
                </SoftBox>
            </Grid>
        </Grid>

    </SoftBox>
  );
}

// Typechecking props for the PricingCards
BlogContent.propTypes = {};

export default BlogContent;
