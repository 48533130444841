/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Icon from "@mui/material/Icon";
import {styled} from "@mui/material/styles";

export default styled(Icon)(({theme, ownerState}) => {
  const {typography} = theme;
  const {size} = ownerState;

  const {fontWeightBold, size: fontSize} = typography;

  return {
    fontWeight: fontWeightBold,
    fontSize: size === "small" && `${fontSize.md} !important`,
  };
});
