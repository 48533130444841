/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import runnerImg from "assets/images/auth-images/runner.jpg";
import RoutesNames from "helpers/enums/routes-map.enum";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";


function AccountActivation() {
    let params = useParams()
    const [isSuccessful, setIsSuccessful] = useState(false);

    const activateAccount = async () => {
        try {
            await CreatePostRequest(BackRequests.ACTIVATE_ACCOUNT + params.confirmationCode);
            setIsSuccessful(true)
        } catch (error) {
            setIsSuccessful(false)
        }
    };

    useEffect(() => {
        (async () => {
            await activateAccount();
        })();
    }, []);

    return (
        <BasicLayout
            title="Activated account!"
            image={runnerImg}
        >
            <Card>
                <SoftBox p={3} mb={1} textAlign="center">
                    <SoftTypography variant="h4" fontWeight="medium">
                        Welcome
                    </SoftTypography>
                </SoftBox>
                {isSuccessful ? (
                    <SoftBox>
                        <SoftBox mb={2} pl={3} pr={3}>
                            <SoftTypography fontWeight="regular">
                                You successfully confirmed Your email address and activated MoveOn account! Please sign in.
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox p={3} textAlign="center">
                            <SoftButton
                                component={Link}
                                to={RoutesNames.SIGN_IN}
                                variant="gradient"
                                color="success"
                                size="medium"
                                circular
                            >
                                Sign in
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                ) : (
                    <SoftBox mb={2} pl={3} pr={3}>
                        <SoftTypography fontWeight="regular">
                            An error occurred while activating the account. Please check your email again and follow the link from email message.
                        </SoftTypography>
                    </SoftBox>
                )}
            </Card>
        </BasicLayout>
    );
}

export default AccountActivation;