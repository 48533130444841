/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { FieldArray } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";

// Form schemas
import { formFields } from "layouts/pages/business/edit-business/schemas/form";

// Helpers
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import { useTranslation } from "react-i18next";

function WorkingHoursAndScheduleEdit({ values, errors, touched, type, setFieldValue }) {
  const { t, i18n } = useTranslation();
  const [selectedTypeValue, setSelectedTypeValue] = useState(BusinessItemTypes.PERSONAL_COACH);

  const renderAdditionalData = (values, errors, touched, setFieldValue) => {
    switch (selectedTypeValue) {
      case BusinessItemTypes.SPORT_FACILITY:
        return (
          <SoftBox >
            <FieldArray
              name={formFields.workingHours.name}
              error={errors.workingHours}
              render={arrayHelpers => (
                <SoftBox>
                  {values.workingHours?.map((workingHoursElem, index) => (
                    <Grid container columnSpacing={3} key={index}>
                      <Grid item xs={12} sm={4}>
                        <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {formFields.workingHours.props.day.label}
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          name={`workingHours[${index}].day`}
                          defaultValue={values.workingHours[index] ?
                            values.workingHours[index]?.day
                            : formFields.workingHours.props.day.default}
                          options={formFields.workingHours.props.day.options}
                          onChange={(event) => {
                            setFieldValue(`workingHours[${index}].day`, event);
                          }}
                          sx={{ overflow: "visible", zIndex: 999 }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormField
                          type={formFields.workingHours.props.startTime.type}
                          label={formFields.workingHours.props.startTime.label}
                          name={`workingHours[${index}].startTime`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormField
                          type={formFields.workingHours.props.endTime.type}
                          label={formFields.workingHours.props.endTime.label}
                          name={`workingHours[${index}].endTime`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} sx={{"textAlign": "center"}}>
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t('businessItem.editPage.action')}
                          </SoftTypography>
                        </SoftBox>
                        <Tooltip title="Remove" placement="bottom" arrow>
                          <SoftBox>
                            <IconButton variant="gradient" color="primary" size="medium"
                              disabled={values.workingHours.length <= 1}
                              onClick={() => {
                                if (values.workingHours.length > 1) {
                                  arrayHelpers.remove(index)
                                }
                              }}>
                              <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                            </IconButton>
                          </SoftBox>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                  <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                    <Tooltip title="Add" placement="bottom" arrow>
                      <SoftButton color="secondary" size="small"
                        onClick={() => arrayHelpers.push({
                          day: formFields.workingHours.props.day.default,
                          startTime: "",
                          endTime: ""
                        })}
                      >
                        + {t('businessItem.editPage.addButton')}
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              )}
            />
          </SoftBox>)
      case BusinessItemTypes.GROUP_ACTIVITY:
        return (
          <SoftBox >
            <FieldArray
              name={formFields.schedule.name}
              error={errors.schedule}
              render={arrayHelpers => (
                <SoftBox>
                  {values.schedule?.map((scheduleElem, index) => (
                    <Grid container spacing={3} key={index}>
                      <Grid item xs={12} sm={5}>
                        <FormField
                          type={formFields.schedule.props.description.type}
                          label={formFields.schedule.props.description.label}
                          name={`schedule[${index}].description`}
                          placeholder={formFields.schedule.props.description.placeholder}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormField
                          type={formFields.schedule.props.startTime.type}
                          label={formFields.schedule.props.startTime.label}
                          name={`schedule[${index}].startTime`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormField
                          type={formFields.schedule.props.endTime.type}
                          label={formFields.schedule.props.endTime.label}
                          name={`schedule[${index}].endTime`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t('businessItem.editPage.action')}
                          </SoftTypography>
                        </SoftBox>
                        <Tooltip title="Remove" placement="bottom" arrow>
                          <SoftBox>
                            <IconButton variant="gradient" color="primary" size="medium"
                              disabled={values.schedule.length <= 1}
                              onClick={() => {
                                if (values.schedule.length > 1) {
                                  arrayHelpers.remove(index)
                                }
                              }}>
                              <DeleteIcon sx={{ fontWeight: "bold" }}>{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>
                            </IconButton>
                          </SoftBox>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                  <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                    <Tooltip title="Add" placement="bottom" arrow>
                      <SoftButton color="secondary" size="small"
                        onClick={() => arrayHelpers.push({
                          description: "",
                          startTime: "",
                          endTime: ""
                        })}
                      >
                        + {t('businessItem.editPage.addButton')}
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              )}
            />
          </SoftBox>)
      default:
        break;
    }
  }

  useEffect(() => {
    const loadData = async () => {
      if (type) {
        setSelectedTypeValue(type);
      }
    }
    loadData();
  }, []);

  return (
    <SoftBox p={3} display="grid">
      {selectedTypeValue == BusinessItemTypes.SPORT_FACILITY && (
        <SoftTypography variant="h5">{t('businessItem.editPage.workingHours')}</SoftTypography>
      )}
      {selectedTypeValue == BusinessItemTypes.GROUP_ACTIVITY && (
        <SoftTypography variant="h5">{t('businessItem.editPage.schedule')}</SoftTypography>
      )}
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderAdditionalData(values, errors, touched, setFieldValue)}
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default WorkingHoursAndScheduleEdit;
