/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import { countryOptions } from "layouts/pages/business/business-form-helpers";
import { dayOptions, genderOptions } from "../../business-form-helpers";

const businessItemOptions = [
  { value: BusinessItemTypes.PERSONAL_COACH, label: "Personal Coach" },
  { value: BusinessItemTypes.SPORT_FACILITY, label: "Sport Facility" },
  { value: BusinessItemTypes.GROUP_ACTIVITY, label: "Group Activity" },
  { value: BusinessItemTypes.EVENT, label: "Event" },
];

// [CHANGE COUNTRY TO LIST] Expand the list
const currencyOptions = [
  { value: "rsd", label: "RSD" },
  // { value: "btc", label: "BTC" },
  // { value: "cny", label: "CNY" },
  // { value: "eur", label: "EUR" },
  // { value: "gbp", label: "GBP" },
  // { value: "inr", label: "INR" },
  // { value: "use", label: "USD" },
];

const formFields = {
  type: {
    name: "type",
    default: businessItemOptions[0].value,
  },
  name: {
    name: "name",
    label: "name *",
    type: "text",
    placeholder: "Name",
    errorMsg: "Name is required.",
  },
  description: {
    name: "description",
    placeholder: "Something about business",
  },
  eventDateStart: {
    name: "eventDateStart",
    label: "Date start",
    type: "date",
    errorMsg: "Date start is required.",
  },
  eventDateFinish: {
    name: "eventDateFinish",
    label: "Date finish",
    type: "date",
    errorMsg: "Date finish is required.",
    errorMsgInvalid: "End date cannot be before start date",
  },
  yearOfBirth: {
    name: "yearOfBirth",
    label: "Year of birth",
    type: "number",
    errorMsg: "Year of birth must be number greater than 1900"
  },
  aboutMe: {
    name: "aboutMe",
    label: "Aboute me *",
    placeholder: "Something aboute me",
    errorMsg: "This field should not be empty"
  },
  aboutPrograms: {
    name: "aboutPrograms",
    label: "About programs *",
    placeholder: "Something aboute programs",
    errorMsg: "This field should not be empty"
  },
  ageCategory: {
    name: "ageCategory",
    label: "Age category",
  },
  gender: {
    name: "gender",
    label: "Gender",
    options: genderOptions,
    default: genderOptions[0],
  },
  skillLevel: {
    name: "skillLevel",
    label: "Skill level",
  },
  sport: {
    name: "sport",
    label: "Sport",
  },
  numberOfFreeTrainings: {
    name: "numberOfFreeTrainings",
    label: "Number of free trainings",
    type: "number",
    placeholder: "0",
    errorMsg: "Number of free trainings must be positive number"
  },
  workingHours: {
    name: "workingHours",
    errorMsg: "Must have working hours",
    minimumErrorMsg: "You have to define at least 1 working hour",
    props: {
      day: {
        name: "day",
        label: "Day *",
        options: dayOptions,
        default: dayOptions[0],
        errorMsg: "Day is required",
      },
      startTime: {
        name: "startTime",
        label: "Start time *",
        type: "time",
        errorMsg: "Start time is required"
      },
      endTime: {
        name: "endTime",
        label: "End time *",
        type: "time",
        errorMsg: "End time is required",
        minErrorMsg: "End time cannot be before start time."
      },
    }
  },
  schedule: {
    name: "schedule",
    errorMsg: "Must have schedule",
    minimumErrorMsg: "You have to define at least 1 schedule element",
    props: {
      description: {
        name: "description",
        label: "Description *",
        type: "text",
        errorMsg: "Description is required",
      },
      startTime: {
        name: "startTime",
        label: "Start time *",
        type: "time",
        errorMsg: "Start time is required"
      },
      endTime: {
        name: "endTime",
        label: "End time *",
        type: "time",
        errorMsg: "End time is required",
        minErrorMsg: "End time cannot be before start time."
      },
    }
  },
  addresses: {
    name: "addresses",
    minimumErrorMsg: "You have to define at least 1 address",
    props: {
      addressLine: {
        name: "addressLine",
        label: "Address line *",
        type: "text",
        errorMsg: "Address line is required.",
      },
      addressNumber: {
        name: "addressNumber",
        label: "Address number *",
        type: "text",
        errorMsg: "Address number is required.",
      },
      city: {
        name: "city",
        label: "City *",
        type: "text",
        errorMsg: "City is required.",
      },
      country: {
        name: "country",
        label: "Country *",
        options: countryOptions,
        default: countryOptions[0].label, // [CHANGE COUNTRY TO LIST] countryOptions[0]
        errorMsg: "Country is required.",
      },
      zipCode: {
        name: "zipCode",
        label: "ZIP/postcode *",
        type: "text",
        errorMsg: "ZIP code is required.",
        errorMsgInvalid: "ZIP code is invalid."
      },
    }
  },
  additionals: {
    name: "additionals",
  },
  mainImage: {
    name: "mainImage",
    accept: "image/*",
    errorMsg: "Main image is required.",
  },
  coverImage: {
    name: "coverImage",
    accept: "image/*"
  },
  galleryImages: {
    name: "galleryImages",
    accept: "image/*"
  },
  website: {
    name: "website",
    label: "website",
    type: "text",
    placeholder: "",
    errorMsg: "Website should be valid URL.",
  },
  email: {
    name: "email",
    label: "email",
    type: "email",
    placeholder: "",
    errorMsg: "Email address is invalid",
  },
  contactPhone: {
    name: "contactPhone",
    label: "contact phone",
    type: "text",
    placeholder: "+000 00 000000",
    invalidMsg: "Contact phone should be valid phone number"
  },
  instagramAccount: {
    name: "instagramAccount",
    label: "instagram account",
    type: "text",
    placeholder: "https://...",
    errorMsg: "Instagram account should be valid URL.",
  },
  facebookAccount: {
    name: "facebookAccount",
    label: "facebook account",
    type: "text",
    placeholder: "https://...",
    errorMsg: "Facebook account should be valid URL.",
  },
  twitterAccount: {
    name: "twitterAccount",
    label: "twitter account",
    type: "text",
    placeholder: "https://...",
    errorMsg: "Twitter account should be valid URL.",
  },
  tiktokAccount: {
    name: "tiktokAccount",
    label: "tiktok account",
    type: "text",
    placeholder: "https://...",
    errorMsg: "Tiktok account should be valid URL.",
  },
  pricingList: {
    name: "pricingList",
    errorMsg: "Must have pricing list",
    minimumErrorMsg: "You have to define at least 1 pricing list",
    props: {
      description: {
        name: "description",
        label: "Description *",
        type: "text",
        placeholder: "Price list element description",
        errorMsg: "Pricing list element description is required"
      },
      price: {
        name: "price",
        label: "Price *",
        type: "number",
        errorMsg: "Price must be greater that 1",
        minimumErrorMsg: "Price must be greater than 1"
      },
      currency: {
        name: "currency",
        label: "Currency *",
        options: currencyOptions,
        default: currencyOptions[0].label // [CHANGE CURRENCY TO LIST] currencyOptions[0]
      },
      discount: {
        name: "discount",
        label: "Discount(%) *",
        type: "number",
        errorMsg: "Discount is required",
        minimumErrorMsg: "Discount must be greater than 1" // TODO
      },
      discountedPrice: {
        name: "discountedPrice",
        label: "Moveon price *",
        type: "text",
      }
    }
  },
};

// [CHANGE CURRENCY TO LIST] remove this
const getCurrencyValue = (label) => {
  return currencyOptions.find((country) => country.label == label).value
};


export {
  formFields,
  getCurrencyValue
};
