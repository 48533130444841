/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from "@mui/material/Grid";
import { React } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Helpers
import { BusinessItemTypes } from "helpers/enums/business-item.enum";


function ProductInfo({ businessType, businessDescription, personalCoachPayload, personalCoachImage}) {
  const frameOptions = [
    { value: "aluminium", label: "Aluminium" },
    { value: "carbon", label: "Carbon" },
    { value: "steel", label: "Steel" },
    { value: "wood", label: "Wood" },
  ];

  const colorOptions = [
    { value: "black", label: "black" },
    { value: "blue", label: "blue" },
    { value: "gray", label: "gray" },
    { value: "pink", label: "pink" },
    { value: "red", label: "red" },
    { value: "white", label: "white" },
  ];

  return (
    (() => {
      if (businessType === BusinessItemTypes.PERSONAL_COACH) {
        return (
          <div>
            <SoftBox>
              <SoftTypography variant="body2" textAlign="justify">
                        <SoftBox
                            component="img"
                            src={personalCoachImage} 
                            alt="Sport Image"
                            shadow="lg"
                            borderRadius="lg"
                            width="100%"
                            style={{ width: "30%", float: 'left', margin: "0 20px 20px 0" }}
                          />
                          <div className="post__content" dangerouslySetInnerHTML={{__html: personalCoachPayload?.aboutMe}}></div>
                    </SoftTypography>

              <SoftBox mt={3} p={5}>
                <Grid container spacing={3}>
                  <SoftBox p={3}>
                    <Grid item sx={{ mx: "auto", padding: "0 0 15px 0", backgroundColor: "#FFFFFF" }}>
                      <SoftBox >
                        <SoftTypography variant="h7" fontWeight="medium">
                          <b><u>Godiste: {personalCoachPayload?.yearOfBirth}</u></b>
                        </SoftTypography><br></br><br></br>
                      </SoftBox>
                      <SoftTypography variant="body2" textAlign="justify" p={4}>
                        <div className="post__content" dangerouslySetInnerHTML={{ __html: businessDescription }}></div>
                      </SoftTypography>
                    </Grid>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </SoftBox>

          </div>)
      } else if(businessType === BusinessItemTypes.GROUP_ACTIVITY ) {
        return(
            <div className="post__content" dangerouslySetInnerHTML={{ __html: businessDescription }}></div>
        )
      }
      else {
        return (<SoftBox>
          <SoftBox mb={1}>
            <SoftTypography variant="h3" fontWeight="bold">
              {//businessItemData?.name
              }
            </SoftTypography>
          </SoftBox>

          <SoftBox mt={3} mb={1} ml={0.5}>
          </SoftBox>
          <SoftTypography variant="body2" textAlign="justify">
            <div className="post__content" dangerouslySetInnerHTML={{ __html: businessDescription }}></div>
          </SoftTypography>
        </SoftBox>)
      }
    })()
  );
}

export default ProductInfo;
