/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for the WavedBlogCard
import WavedBlogCardWavesRoot from "examples/Cards/BlogCards/WavedBlogCard/WavedBlogCardWavesRoot";
import SoftButton from "components/SoftButton";
import RoutesNames from "helpers/enums/routes-map.enum";

function WavedBlogCard({ image, title, description, action, eventID }) {
  return (
    <Card style={{ Height:'36vh'}}>
      <SoftBox component="img" src={image} alt={title} width="100%"/>
      <SoftBox position="relative" height="3.125rem" overflow="hidden" zIndex={2} mt={-6.25}>
        <SoftBox position="absolute" width="100%" top={0} zIndex={1}>
          <SoftBox
            component="svg"
            position="relative"
            width="100%"
            height="3.125rem"
            minHeight="3.125rem"
            maxHeight="3.125rem"
            mb={-0.875}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 40"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="card-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <WavedBlogCardWavesRoot>
              <use xlinkHref="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30" />
              <use xlinkHref="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)" />
              <use xlinkHref="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)" />
              <use xlinkHref="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)" />
              <use xlinkHref="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)" />
              <use xlinkHref="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)" />
            </WavedBlogCardWavesRoot>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox p={1}>
        <SoftTypography pl={1.5} pr={1.5} variant="h6" fontWeight="bold"
          component={Link}
          to={RoutesNames.EVENT} state={{ id: eventID }}>
          {title}
        </SoftTypography>
        <SoftBox pl={1.5} pr={1.5} mt={2} mb={1.5}>
          <SoftTypography variant="body2" component="div" color="text" line-height="normal" lineHeight="1.3">
            <div className="post__content" dangerouslySetInnerHTML={{__html: description.substring(0,180) + "..."}}></div>
          </SoftTypography>
        </SoftBox>
        {action && 
        <SoftBox mt="auto" display="flex" justifyContent="end">
            <SoftButton color="primary" variant="text" size="small" component={Link} to={action.route} state={{ id: eventID }}>
              {action.label}
            </SoftButton>
        </SoftBox> }
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the WavedBlogCard
WavedBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
    ]).isRequired,
  }).isRequired,
  eventID: PropTypes.string,
};

export default WavedBlogCard;
