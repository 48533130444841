/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React Button Styles
import root from "assets/theme/components/button/root";
import contained from "assets/theme/components/button/contained";
import outlined from "assets/theme/components/button/outlined";
import buttonText from "assets/theme/components/button/text";

const button = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {...root},
    contained: {...contained.base},
    containedSizeSmall: {...contained.small},
    containedSizeLarge: {...contained.large},
    containedPrimary: {...contained.primary},
    containedSecondary: {...contained.secondary},
    outlined: {...outlined.base},
    outlinedSizeSmall: {...outlined.small},
    outlinedSizeLarge: {...outlined.large},
    outlinedPrimary: {...outlined.primary},
    outlinedSecondary: {...outlined.secondary},
    text: {...buttonText.base},
    textSizeSmall: {...buttonText.small},
    textSizeLarge: {...buttonText.large},
    textPrimary: {...buttonText.primary},
    textSecondary: {...buttonText.secondary},
  },
};

export default button;
