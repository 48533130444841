/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import BusinessItemCard from "examples/Cards/BusinessItemCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import RoutesNames from "helpers/enums/routes-map.enum";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";

function Ownership({ id, businessItems }) {
    const { t, i18n } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    return (
        <Card id="profile">
            <SoftBox p={3} pb={0} lineHeight={1}>
                <SoftBox>
                    <SoftTypography variant="h5">
                        {t('user.settings.ownershipSection.title')}
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
                <Grid container spacing={3}>
                    {businessItems && businessItems?.slice(0, 3).map((businessItem, index) => (
                        <Grid item xs={12} md={6} xl={3} key={index}>
                            <BusinessItemCard
                                id={businessItem.id}
                                image={businessItem.mediaPayload.mainImageUrl}
                                name={businessItem.name}
                                status={businessItem.status}
                                type={businessItem.type}
                                showStatus
                                isAdmin
                            />
                        </Grid>
                    ))}
                    {!showAll && businessItems.length > 3 && 
                        <Grid item xs={12} md={6} xl={3} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <SoftBox onClick={() => setShowAll(true)}>
                                <PlaceholderCard title={{ variant: "h5", text: t('user.settings.ownershipSection.showMore')}} icon={<ExpandMoreIcon fontSize="large"/>} />
                            </SoftBox>
                        </Grid>}
                    {showAll && businessItems.length > 3 && 
                        <Grid item xs={12} md={6} xl={3} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <SoftBox onClick={() => setShowAll(false)}>
                                <PlaceholderCard title={{ variant: "h5", text: t('user.settings.ownershipSection.showLess') }} icon={<ExpandLessIcon fontSize="large"/>} />
                            </SoftBox>
                        </Grid>}
                    {
                        showAll &&
                        businessItems && businessItems?.slice(3, businessItems.length).map((businessItem, index) => (
                            <Grid item xs={12} md={6} xl={3} key={index}>
                                <BusinessItemCard
                                    id={businessItem.id}
                                    image={businessItem.mediaPayload.mainImageUrl}
                                    name={businessItem.name}
                                    status={businessItem.status}
                                    type={businessItem.type}
                                    showStatus
                                    isAdmin
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </SoftBox>
        </Card>
    );
}

export default Ownership;
