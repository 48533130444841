/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import PlaceIcon from '@mui/icons-material/Place';
import StarIcon from '@mui/icons-material/Star';

// Images
import routes from "../../../../../routes";
import PublicNavbar from "examples/Navbars/PublicNavbar";
import moveon_logo_text from "assets/images/moveon/moveon_tennisball_orange.png";
import TranslateSelect from "components/common/TranslateSelect";

import breakpoints from "assets/theme/base/breakpoints";

// Helpers
import { useTranslation } from "react-i18next";
import HeaderMenu from "components/common/HeaderMenu";

function PageHeader({ businessName, businessRating, businessRatingCount, businessCoverImage, businessMainImage, isAdmin }) {
  const { t, i18n } = useTranslation();
  const [elementsAlignment, setElementsAlignment] = useState("left");

  const getSocialMediaAlignment = () => {
    if(elementsAlignment == "center") {
      return "left";
    } else {
      return "center";
    }
  }

  useEffect(() => {
    function handleElementsAlignment() {
      return window.innerWidth <= breakpoints.values.lg
        ? setElementsAlignment("center")
        : setElementsAlignment("left");
    }

    window.addEventListener("resize", handleElementsAlignment);

    handleElementsAlignment();

    return () => window.removeEventListener("resize", handleElementsAlignment);
  }, [elementsAlignment]);

  return (
    <SoftBox position="relative" >
      {!isAdmin &&
        <HeaderMenu/>
      }
      <SoftBox width="100%"
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        // borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `url(${businessCoverImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
      </SoftBox>
      <Card
        sx={{
          width:"85%",
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={1.5} justifyContent={elementsAlignment}>
          <Grid item display="flex">
            <SoftAvatar
              src={businessMainImage}
              alt="profile-image"
              variant="rounded"
              shadow="sm"
              style={{width:"150px", height:"150px", border: "1px solid grey", margin:"-13px 0 -10px -13px"}}
            />
            </Grid>
            <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              {businessName && (<SoftTypography variant="h3" fontWeight="medium" textAlign={elementsAlignment}>
                {businessName}
              </SoftTypography>)}
              <SoftBox display="flex" alignItems="center" pt={1} px={1}>
                <PlaceIcon color="secondary" fontSize="small" />
                <SoftTypography variant="body2" fontWeight="medium" textTransform="capitalize" ml={0.5}>
                  Adresa
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" pt={1} px={1}>
                <StarIcon color="secondary" fontSize="small" />
                <SoftTypography variant="body2" fontWeight="medium" textTransform="capitalize" ml={0.5}>
                  {businessRatingCount > 0 ? Number(businessRating) + "/5 (" + businessRatingCount + (businessRatingCount == 1 ? " review)" :  " reviews)") : "(0 reviews)"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            </Grid>
            <Grid item xs={12} md={12} lg={2} sx={{ ml: "auto" }} display="flex" justifyContent={getSocialMediaAlignment} alignItems="center">
                <SoftBox display="flex" justifyContent="center" px={1}>
                  <a href="https://www.instagram.com/"><SoftBox mr={0.5}><InstagramIcon color="secondary" fontSize="large"/></SoftBox></a>
                  <a href="https://www.facebook.com/"><SoftBox mr={0.5}><FacebookIcon color="secondary" fontSize="large"/></SoftBox></a>
                  <a href="https://twitter.com/"><SoftBox mr={0.5}><TwitterIcon color="secondary" fontSize="large"/></SoftBox></a>
                </SoftBox>
            </Grid>
          </Grid>
      </Card>
    </SoftBox>
  );
}

export default PageHeader;
