import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import FormNames from "layouts/pages/home/schemas/form-names.enum"

const businessItemOptions = [
  { value: BusinessItemTypes.PERSONAL_COACH, label: "Personal Coach" },
  { value: BusinessItemTypes.SPORT_FACILITY, label: "Sport Facility" },
  { value: BusinessItemTypes.GROUP_ACTIVITY, label: "Group Activity" },
  { value: BusinessItemTypes.EVENT, label: "Event" },
];

const genderOptions = [
  { value: "all", label: "All" },
  { value: "man", label: "Men only" },
  { value: "woman", label: "Women only" },
];

const ratingScoreOptions = [
  { value: 4, label: "4+" },
  { value: 3, label: "3+" },
  { value: 2, label: "2+"},
  { value: 1, label: "1+" },
  { value: 0, label: "Unrated" },
];

const fields = {
  [FormNames.SEARCH]: {
    businessName: {
      name: "businessName",
      label: "Bussines name",
      type: "text",
    },
    location: {
      name: "location",
      label: "Location (city)",
      type: "text",
    },
    category: {
      name: "category",
      label: "Category",
      options: businessItemOptions,
      default: businessItemOptions[0].value,
    },
    searchGender: {
      name: "gender",
      label: "Gender policy",
      options: genderOptions,
    },
    searchAdditionals: {
      name: "additionals",
      label: "Additionals",
    },
    searchSkillLevel: {
      name: "skillLevel",
      label: "Skill level",
    },
    searchAgeCategory: {
      name: "ageCategory",
      label: "Age category",
    },
    searchSport: {
      name: "sport",
      label: "Sport",
    },
    reviewScore: {
      name: "reviewScore",
      label: "Review score",
      options: ratingScoreOptions
    }
  },
  [FormNames.MAKE_RESERVATION]: {
    firstName: {
      name: "firstName",
      label: "first name",
      type: "text",
      placeholder: "First name",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "last name",
      type: "text",
      placeholder: "Last name",
      errorMsg: "Last name is required.",
    },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "Email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    service: {
      name: "service",
      label: "Choose service",
      errorMsg: "Service is required.",
    },
  }
}

export default fields;

