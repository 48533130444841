/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreateGetRequest } from "helpers/back-request/helpers";
import { useEffect, useState } from "react";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';

function BusinessOwner({ businessItemId }) {
  const [ownerData, setOwnerData] = useState();

  const getOwnerData = async () => {
    try {
      console.log("getOwnerData")
      const response = await CreateGetRequest(BackRequests.GET_OWNER_FOR_BUSINESS_ITEM + businessItemId);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const ownerData = await getOwnerData();
      setOwnerData(ownerData);
    }
    loadData();
  }, []);

  return (
    <Card id="delete-account">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Business owner</SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <PersonIcon color="secondary" fontSize="small"/>
          <SoftTypography variant="body2" ml={1}  color="text" fontWeight="regular">
          {ownerData?.firstName} {ownerData?.lastName}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <EmailIcon color="secondary" fontSize="small" />
          <SoftTypography variant="body2" ml={1}  color="text" fontWeight="regular">
            {ownerData?.email ? ownerData?.email : "/"}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <LocalPhoneIcon color="secondary" fontSize="small" />
          <SoftTypography variant="body2" ml={1}  color="text" fontWeight="regular">
            {ownerData?.phoneNumber ? ownerData?.phoneNumber : "/"}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default BusinessOwner;
