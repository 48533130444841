/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const formFields = {
  goodMessage: {
    name: "goodMessage",
    label: "What did you like?",
    placeholder: "Write your review...",
    type: "text",
  },
  badMessage: {
    name: "badMessage",
    label: "What didn't you like?",
    placeholder: "Write your review...",
    type: "text",
  },
  rating: {
    name: "rating",
    label: "How was your experience?",
    errorMsgForMin: "Rating must be at least one",
  },
};

export {
  formFields,
};
