/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import FormField from "components/common/FormField";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { CreatePostRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";

const initialValues = {
  ["email"]: "",
};

const validations = Yup.object().shape({
  ["email"]: Yup.string()
    .required("Email address is required.")
    .email("Your email address is invalid"),
});

function ResetPasswordInit() {
  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  const handleSubmit = async (values, actions) => {
    try {
      setIsSubmited(true)
      await CreatePostRequest(BackRequests.PASSWORD_RESET_INIT, {
        email: values.email,
      });
      openSuccessSB("Password reset initiated");
    } catch (error) {
      openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <PageLayout background="light">
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ minHeight: "75vh" }}
      >
        <Grid item xs={10} md={6} lg={4}>
          <SoftBox mt={32} mb={3} px={{ xs: 0, lg: 2 }}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id="reset-password-form" autoComplete="off">
                  <Card>
                    <SoftBox pt={3} px={3} pb={1} textAlign="center">
                      <SoftTypography
                        variant="h4"
                        fontWeight="bold"
                        textGradient
                        mb={2}
                      >
                        Forgot your password?
                      </SoftTypography>
                      <SoftTypography variant="body2" color="text">
                        Please enter your email address. You will receive a link to create a new password via email.
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox p={3} pt={0}>
                      <SoftBox>
                        <SoftBox>
                          <FormField
                            type="email"
                            name="email"
                            label=""
                            value={values.email}
                            placeholder="Email"
                            error={errors.email && touched.email}
                            disabled={isSubmited}
                          />
                        </SoftBox>
                        <SoftBox mt={5} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            size="large"
                            fullWidth
                            disabled={isSubmited}
                          >
                            send
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </SoftBox>
        </Grid>
      </Grid>
      { ErrorMessageSnackBar({title: "Reset password error", errorMessage, errorSB, closeErrorSB}) }
      { SuccessMessageSnackBar({title: "Success", successMessage, successSB, closeSuccessSB}) }
    </PageLayout>
  );
}

export default ResetPasswordInit;
