/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React icons
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import GridViewIcon from '@mui/icons-material/GridView';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RoutesNames from "helpers/enums/routes-map.enum";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

function Sidenav() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const sidenavItems = [
    { icon: <GridViewIcon color="primary" fontSize="medium" />, label: t('businessProfile.sideNav.dashboard'), href: RoutesNames.BUSINESS_DASHBOARD },
    { icon: <PlaylistAddIcon color="primary" fontSize="medium" />, label: t('businessProfile.sideNav.businessList'), href: RoutesNames.BUSINESS_LIST },
    { icon: <ConfirmationNumberIcon color="primary" fontSize="medium" />, label: t('businessProfile.sideNav.reservations'), href: RoutesNames.BUSINESS_RESERVATIONS },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MenuItem
        key={itemKey}
        pt={key === 0 ? 0 : 1}
        component={Link}
        to={href}
        sx={({
          borders: { borderRadius },
          functions: { pxToRem },
          palette: { light },
          transitions,
        }) => ({
          display: "flex",
          alignItems: "center",
          borderRadius: borderRadius.md,
          padding: `${pxToRem(10)} ${pxToRem(16)}`,
          transition: transitions.create("background-color", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
          }),

          "&:hover": {
            backgroundColor: light.main,
          },
          ...(href === 'aborted' && {
            color: color1,
            backgroundColor: bgcolor1,
          }),
        })}
      >
        <SoftBox display="flex" alignItems="center" >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          <SoftTypography variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize" ml={1}>{label}</SoftTypography>
        </SoftBox>
      </MenuItem>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

export default Sidenav;
