/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form, FieldArray } from "formik";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";

// Form schemas
import {validations, preventMinus, preventPasteNegative} from "layouts/pages/business/new-business/schemas/validations";
import {fields} from "layouts/pages/business/new-business/schemas/form";
import {initial} from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import { getCurrencyValue } from "../../schemas/form";
import { calculateDiscountedPrice } from "layouts/pages/business/business-form-helpers";

function Pricing({activeStep, isLastStep, handleNext, handleBack}) {
  // Form data
  const formValidation = validations[FormNames.PRICING_FORM];
  const formFields = fields[FormNames.PRICING_FORM];
  const initialValues = initial[FormNames.PRICING_FORM];

  const handleSubmit = async (values, actions) => {
    try {
      console.log("PRICING HANDLE SUBMIT")
      console.log(values)

      if(values.pricingList.length < 1) {
        // TODO
        // openErrorSB(GetErrorMessage(error));
        return;
      }

      handleNext({
        pricelist: values.pricingList.map((item) => {
          const { currency, ...otherProperties } = item;
          return {
            ...otherProperties,
            currency: getCurrencyValue(currency) // [CHANGE CURRENCY TO LIST] currency.value
          }
        }),
      })
    } catch (error) {
      // TODO
      // openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
      >
      {({values, errors, touched, isSubmitting, setFieldValue, handleChange}) => (
        <Form id={FormNames.PRICING_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5">Pricing</SoftTypography>
            <FieldArray
              name={formFields.pricingList.name}
              error={errors.pricingList}
              render={arrayHelpers => (
                <SoftBox mt={3}>
                  {values.pricingList?.map((pricingListElem, index) => (
                    <Grid container spacing={3} key={index}>
                      <Grid item xs={12} sm={5}>
                        <FormField
                          type={formFields.pricingList.props.description.type} 
                          label={formFields.pricingList.props.description.label} 
                          name={`pricingList[${index}].description`}
                          placeholder={formFields.pricingList.props.description.placeholder} />
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <FormField 
                          type={formFields.pricingList.props.price.type} 
                          label={formFields.pricingList.props.price.label} 
                          name={`pricingList[${index}].price`}
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={ { min: 0 } }
                          onChange={(event) => {
                            handleChange(event);
                            setFieldValue(`pricingList[${index}].discountedPrice`, calculateDiscountedPrice(event.target.value, values.pricingList[index].discount));
                          }} 
                          />
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        {/* <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {formFields.pricingList.props.currency.label}
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          name={`pricingList[${index}].currency`}
                          defaultValue={(initialValues.pricingList && initialValues.pricingList[index]) ? initialValues.pricingList[index]?.currency : formFields.pricingList.props.currency.default}
                          options={formFields.pricingList.props.currency.options}
                          onChange={(event) => {
                            setFieldValue(`pricingList[${index}].currency`, event);
                          }}
                          disabled
                        /> */}
                       {/* [CHANGE CURRENCY TO LIST] remove below FormField and uncomment previous */}
                        <FormField
                          type="text"
                          label={formFields.pricingList.props.currency.label}
                          name={`pricingList[${index}].currency`}
                          value={values.pricingList[index].currency?.toUpperCase()}
                          error={errors.country && touched.country}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <FormField  
                          type={formFields.pricingList.props.discount.type} 
                          label={formFields.pricingList.props.discount.label} 
                          name={`pricingList[${index}].discount`}
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={ { min: 0 } } 
                          onChange={(event) => {
                            handleChange(event);
                            setFieldValue(`pricingList[${index}].discountedPrice`, calculateDiscountedPrice(values.pricingList[index].price, event.target.value));
                          }} 
                          />
                      </Grid>
                      <Grid item xs={12} sm={1.5}>
                        <FormField 
                          type={formFields.pricingList.props.discountedPrice.type} 
                          label={formFields.pricingList.props.discountedPrice.label} 
                          name={`pricingList[${index}].discountedPrice`}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Action
                          </SoftTypography>
                        </SoftBox>
                        <Tooltip title="Remove pricing list element" placement="bottom" arrow>
                          <SoftBox>
                            <IconButton variant="gradient" color="error" size="medium" 
                              disabled={values.pricingList.length <= 1}
                              onClick={() => {
                                if(values.pricingList.length > 1) {
                                  arrayHelpers.remove(index)
                                }
                              }}>
                                <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                            </IconButton>
                          </SoftBox>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                  <SoftBox  mt={1} width="100%" display="flex" justifyContent="end">
                    <Tooltip title="Add pricing list element" placement="bottom" arrow>
                      <SoftButton color="secondary" size="small" 
                        onClick={() => arrayHelpers.push({ 
                          description: "",
                          price: 0,
                          currency: formFields.pricingList.props.currency.default,
                          discount: 0,
                          discountedPrice: 0
                          })}
                      >
                        + add
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                )}
                />
              <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting}/>
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default Pricing;
