/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Helpers
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreateGetRequest } from "helpers/back-request/helpers";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";

function BusinessGalleryImages({galleryImagesIds}) {
  const [galleryImages, setGalleryImages] = useState([]);
  const [currentImage, setCurrentImage] = useState();
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);

  const getBusinessItemDetails = async () => {
    try {
      if (galleryImagesIds.length > 0) {
        for (let i = 0; i < galleryImagesIds.length; i++) {
          const galleryImageId = galleryImagesIds[i];
          if (galleryImageId == null) {
            continue
          }
          const galleryImagesResponse = await CreateGetRequest(BackRequests.GET_MEDIA + galleryImageId, {responseType: 'blob'});
          galleryImages.push(URL.createObjectURL(galleryImagesResponse.data))
          setGalleryImages(galleryImages)
        }
        setCurrentImage(galleryImages[0])
      }
    } catch (error) {
      /*console.log("error")
      console.log(error)
      // TODO: Sta na stranici prikazati ako se ne ucitaju podaci
      openErrorSB(setErrorMessage(error), true);*/
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await getBusinessItemDetails();
    }
    loadData();
  }, []);

  // [{ src: image1 }, { src: image2 }, { src: image3 }, { src: image4 }, { src: image5 }]
  return (
    <SoftBox>
      <ImgsViewer
        imgs={galleryImages.map((imageUrl) => {return {src: imageUrl}})}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />
      <SoftBox
        component="img"
        src={currentImage} 
        alt="Sport Image"
        shadow="lg"
        borderRadius="lg"
        maxWidth="100%"
        onClick={openImgsViewer}
      />
      <SoftBox mt={2} pt={1}>
        <Grid container spacing={3}>
          {galleryImages?.map((imageUrl, index) => (
            <Grid item xs={2} key={"galleryImage-"+index}>
              <SoftBox
                component="img"
                id={0}
                src={imageUrl}
                alt="small image 1"
                borderRadius="lg"
                shadow="md"
                maxWidth="100%"
                sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
                onClick={handleSetCurrentImage}
              />
            </Grid>
          ))}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default BusinessGalleryImages;
