const BusinessItemTypes = {
    EVENT: "event",
    PERSONAL_COACH: "personal-coach",
    SPORT_FACILITY: "sport-facility",
    GROUP_ACTIVITY: "group-activity",
}

const BusinessItemStatus = {
    PENDING_APPROVAL: "pending-approval",
    ACTIVE: "active",
    REJECTED: "rejected",
    DELETED: "deleted",
}

const BusinessItemOrderOptions = {
    SCORE_HIGH_TO_LOW : "score-desc",
    SCORE_LOW_TO_HIGH : "score-asc",
}

function getDisplayBusinessItemType(type, t) {
    switch (type) {
        case BusinessItemTypes.EVENT:
            return t('businessItem.type.event')
        case BusinessItemTypes.PERSONAL_COACH:
            return t('businessItem.type.personalCoach')
        case BusinessItemTypes.SPORT_FACILITY:
            return t('businessItem.type.sportFacility')
        case BusinessItemTypes.GROUP_ACTIVITY:
            return t('businessItem.type.groupActivity')
    }
}

function getDisplayBusinessItemStatus(status, t) {
    switch (status) {
        case BusinessItemStatus.PENDING_APPROVAL:
            return t('businessItem.status.pendingApproval')
        case BusinessItemStatus.ACTIVE:
            return t('businessItem.status.active')
        case BusinessItemStatus.REJECTED:
            return t('businessItem.status.rejected')
        case BusinessItemStatus.DELETED:
            return t('businessItem.status.deleted')
    }
}

export {
    BusinessItemTypes,
    BusinessItemStatus,
    BusinessItemOrderOptions,
    getDisplayBusinessItemType,
    getDisplayBusinessItemStatus
};