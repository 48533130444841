/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";

function DeleteBusiness({ businessName, businessId }) {
  const { t, i18n } = useTranslation();

  const showAlert = (title, question, confirmButtonText, cancelButtonText, successTitle, successMsg, errorTitle, errorMsg) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-error",
        cancelButton: "button button-secondary",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `<h5>${title}</h5>`,
        text: question,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: true,
      })
      .then(async (result) => {

        if (result.value) {
          try {

            await CreatePostRequest(BackRequests.DELETE_BUSINESS_ITEM,
              {
                businessItemId: businessId,
                userId: JSON.parse(localStorage.getItem("userId"))
              });
            newSwal.fire(successTitle, successMsg, "success");
          } catch (error) {
            console.log("ERRRRROROOR")
            console.log(error)
            newSwal.fire(errorTitle, errorMsg, "error");
          }
        }
      });
  };

  const onDeleteClick = async () => {
    showAlert(
      t('businessItem.deleteConfirmDialog.title'),
      t('businessItem.deleteConfirmDialog.question') + businessName + "?",
      t('businessItem.deleteConfirmDialog.delete'),
      t('businessItem.deleteConfirmDialog.cancel'),
      t('businessItem.deleteConfirmDialog.successTitle'),
      t('businessItem.deleteConfirmDialog.successMsg') + businessName,
      t('businessItem.deleteConfirmDialog.errorTitle'),
      t('businessItem.deleteConfirmDialog.errorMsg') + businessName,
    );
  }


  return (
    <Card id="delete-account">
      <SoftBox p={3} pb={0} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">
            {t('businessItem.settings.deleteSection.title')}
          </SoftTypography>
        </SoftBox>

      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >

        <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
          <SoftTypography variant="body2" color="text" fontWeight="regular">
            {t('businessItem.settings.deleteSection.text')}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton variant="gradient" color="error" sx={{ height: "100%" }} onClick={onDeleteClick}>
              {t('businessItem.settings.deleteSection.button')}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default DeleteBusiness;
