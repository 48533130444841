import checkout from "layouts/authentication/sign-in/basic/schemas/form";
const {
    formField: {
        email,
        password,
    },
} = checkout;

const initialValues = {
    [email.name]: "",
    [password.name]: "",
};

export default initialValues;
