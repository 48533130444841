/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import StarIcon from '@mui/icons-material/Star';
import PlaceIcon from '@mui/icons-material/Place';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { useTranslation } from "react-i18next";
import RoutesNames from "helpers/enums/routes-map.enum";
import { getDisplayBusinessItemType } from "helpers/enums/business-item.enum";
import { Divider, Tooltip } from "@mui/material";

import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SoftButton from "components/SoftButton";
import SoftBadgeDot from "components/SoftBadgeDot";

function BusinessInfoPreviewInCard({ title, type, addresses, sports, rating, ratingCount, maxDiscount, eventId, showDivider }) {
    const { t, i18n } = useTranslation();

    return (
        <SoftBox height="100%" display="flex" flexDirection="column">
            <SoftBox flex="1" my={2} pl={1.5}>
                <SoftBox lineHeight={0} textAlign="left" marginBottom={!showDivider ? "16px" : 0}>
                    <SoftTypography variant="h6" fontWeight="bold"
                        component={Link}
                        to={RoutesNames.EVENT} state={{ id: eventId }}>
                        {title}
                    </SoftTypography>
                    {type ? (
                        <SoftBox mt={0.3}>
                            <SoftTypography
                                variant="caption"
                                fontWeight="regular"
                                color="text"
                                textTransform="capitalize"
                            >
                                {getDisplayBusinessItemType(type, t)}
                            </SoftTypography>
                        </SoftBox>
                    ) : null}
                </SoftBox>
                {showDivider && 
                    <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
                }
                <SoftBox display="flex" alignItems="center">
                    <StarIcon color="secondary" fontSize="small" />
                    <SoftTypography variant="body2" textTransform="capitalize" ml={0.5}>
                        {rating > 0 ? Number(rating) + "/5 (" + ratingCount + (ratingCount == 1 ? " review)" : " reviews)") : "(0 reviews)"}
                    </SoftTypography>
                </SoftBox>

                {addresses && addresses.length > 0 && (
                    <SoftBox display="flex" alignItems="center">
                        <PlaceIcon color="secondary" fontSize="small" />
                        <SoftTypography variant="body2" textTransform="capitalize" ml={0.5}>
                            {addresses[0].city}
                        </SoftTypography>
                        {
                            addresses.length > 1 && (
                                <Tooltip title={(
                                    <SoftBox>
                                        <SoftTypography color="secondary" variant="caption" fontWeight="bold">All locations:</SoftTypography>
                                        <SoftBox>
                                            {addresses.map((address, index) => {
                                                return (
                                                    <SoftBadgeDot key={"address-" + index} color="light" badgeContent={address.city} font={{ color: "light" }} py={0.3} />
                                                )
                                            })}
                                        </SoftBox>
                                    </SoftBox>
                                )} describeChild arrow={false} placement="bottom-start" PopperProps={{
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, -15],
                                            },
                                        },
                                    ],
                                }}
                                    enterTouchDelay={0}
                                >
                                    <SoftTypography variant="body2" ml={0.5}>
                                        and others
                                    </SoftTypography>
                                </Tooltip>
                            )
                        }
                    </SoftBox>
                )}

                <SoftBox display="flex" alignItems="center">
                    <SoftTypography variant="body2" mr={1}>{t('cardLabels.discount')} </SoftTypography>
                    <SoftTypography variant="body2" color="secondary" fontWeight="bold">{maxDiscount}%</SoftTypography>
                </SoftBox>

                {
                    sports && sports.length > 0 && (
                        <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="body2" mr={0.5}>
                                {t('cardLabels.sport')}
                            </SoftTypography>
                            {sports.slice(0, 3).map((paragraph, i) =>
                                <SoftBox key={"sport-" + i + "-" + i} display="flex" mr={0.5}>{
                                    (() => {
                                        if (paragraph == "Tennis") {
                                            return (
                                                <Tooltip title="Tennis" placement="bottom" arrow>
                                                    <SportsTennisIcon color="secondary" fontSize="small" />
                                                </Tooltip>
                                            )
                                        } else if (paragraph == "Boxing") {
                                            return (
                                                <Tooltip title="Boxing" placement="bottom" arrow>
                                                    <SportsMmaIcon color="secondary" fontSize="small" />
                                                </Tooltip>
                                            )
                                        } else if (paragraph == "Basketball") {
                                            return (
                                                <Tooltip title="Basketball" placement="bottom" arrow>
                                                    <SportsBasketballIcon color="secondary" fontSize="small" />
                                                </Tooltip>
                                            )
                                        } else if (paragraph == "Football") {
                                            return (
                                                <Tooltip title="Football" placement="bottom" arrow>
                                                    <SportsSoccerIcon color="secondary" fontSize="small" />
                                                </Tooltip>
                                            )
                                        } else if (paragraph == "Volleyball") {
                                            return (
                                                <Tooltip title="Volleyball" placement="bottom" arrow>
                                                    <SportsVolleyballIcon color="secondary" fontSize="small" />
                                                </Tooltip>
                                            )
                                        }
                                    })()} </SoftBox>)}

                            {
                                sports.length > 3 && (
                                    <Tooltip title={(
                                        <SoftBox>
                                            <SoftTypography color="secondary" variant="caption" fontWeight="bold">All sports:</SoftTypography>
                                            <SoftBox>
                                                {sports.map((sport, index) => {
                                                    return (
                                                        <SoftBadgeDot key={"sport-" + index} color="light" badgeContent={sport} font={{ color: "light" }} py={0.3} />
                                                    )
                                                })}
                                            </SoftBox>
                                        </SoftBox>
                                    )} describeChild arrow={false} placement="bottom-start" PopperProps={{
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, -15],
                                                },
                                            },
                                        ],
                                    }}
                                        enterTouchDelay={0}
                                    >
                                        <SoftTypography variant="body2" ml={0.5}>
                                            and others
                                        </SoftTypography>
                                    </Tooltip>
                                )
                            }
                        </SoftBox>
                    )
                }
            </SoftBox>
            <SoftBox mt="auto" display="flex" justifyContent="end">
                <SoftButton color="primary" variant="text" size="small" component={Link} to={RoutesNames.EVENT} state={{ id: eventId }}>
                    See details
                </SoftButton>
            </SoftBox>
        </SoftBox>

    );
}

BusinessInfoPreviewInCard.propTypes = {
    title: PropTypes.string.isRequired,
    addresses: PropTypes.array,
    sports: PropTypes.array,
    rating: PropTypes.string.isRequired,
    maxDiscount: PropTypes.number.isRequired,
    isSponsored: PropTypes.bool,
};

export default BusinessInfoPreviewInCard;
