/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Moment from 'moment';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getDisplayUserStatus } from "helpers/enums/user-status.enum";
import { useTranslation } from "react-i18next";

function AccountInfo({ id, status, dateOfRegistration, dateOfLastAction }) {
    const { t, i18n } = useTranslation();

    return (
        <Card id="profile" sx={{width:"100%"}}>
             <SoftBox p={3} pb={0} lineHeight={1}>
                <SoftBox mb={1}>
                <SoftTypography variant="h5">
                    {t('user.settings.accountSection.title')}
                </SoftTypography>
                </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
                <Grid container spacing={1} alignItems="left" justifyContent="left" direction="column">
                <Grid item>
                        <SoftBox display="flex" alignItems="center">
                            <SoftTypography component="label"
                                variant="body2"
                                fontWeight="bold"
                                textTransform="capitalize">{t('user.settings.accountSection.status')}:</SoftTypography>
                            <SoftTypography variant="body2" textTransform="capitalize" ml={1}>
                                {getDisplayUserStatus(status, t) ?? "/"}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item>
                        <SoftBox display="flex" alignItems="center">
                            <SoftTypography component="label"
                                variant="body2"
                                fontWeight="bold">{t('user.settings.accountSection.dateOfRegistration')}:</SoftTypography>
                            <SoftTypography variant="body2" textTransform="capitalize" ml={1}>
                                {dateOfRegistration ? Moment(dateOfRegistration).format("DD/MM/YYYY, HH:mm:ss") : "/"}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item>
                        <SoftBox display="flex" alignItems="center">
                            <SoftTypography component="label"
                                variant="body2"
                                fontWeight="bold">{t('user.settings.accountSection.dateOfLastAction')}:</SoftTypography>
                            <SoftTypography variant="body2" textTransform="capitalize" ml={1}>
                                {dateOfLastAction ? Moment(dateOfLastAction).format("DD/MM/YYYY, HH:mm:ss") : "/"}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
        </Card>
    );
}

export default AccountInfo;
