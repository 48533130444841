/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {Form, Formik} from "formik";
import {useState} from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import FormField from "components/common/FormField";

// Form 
import {formFields} from "layouts/pages/client/profile/schemas/form";
import {validations} from "layouts/pages/client/profile/schemas/validations";
import {initialValues} from "layouts/pages/client/profile/schemas/initialValues";

// Helpers
import {CreatePostRequest, GetErrorMessage} from "helpers/back-request/helpers"
import BackRequests from "helpers/back-request/back-requests.enum";
import MessagesTexts from "helpers/messages/messages-texts";

function ChangePassword({setResponseMessage}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const formId = "change-password-form";
  const formValidation = validations[formId]

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SoftBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SoftTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </SoftTypography>
      </SoftBox>
    );
  });

  const changePasswordValues = async (values) => {
    try {
      await CreatePostRequest(BackRequests.EDIT_PASSWORD,
        {
          userId: JSON.parse(localStorage.getItem("userId")),
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        });
      setResponseMessage(MessagesTexts.SUCCESSFULLY_UPDATED_PASSWORD, false);
    } catch (error) {
      console.log(error)
      setResponseMessage(GetErrorMessage(error), true);
    }
  }

  const handleSubmitChangePassword = async (values, actions) => {
    if (values.newPassword !== values.repeatPassword) {
      setResponseMessage(MessagesTexts.PASSWORDS_DO_NOT_MATCH, true);
    }

    if (isEditMode) {
      await changePasswordValues(values)
      actions.setSubmitting(false);
      setIsEditMode(false);
      actions.resetForm();
    }
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={formValidation}
      initialValues={initialValues}
      onSubmit={handleSubmitChangePassword}
      onReset={handleCancel}
    >
      {({values, errors, touched, isSubmitting, resetForm}) => (
        <Form id={formId} autoComplete="off">
          <Card id={formId}>
            <SoftBox p={3}>
              <SoftTypography variant="h5">Change Password</SoftTypography>
            </SoftBox>
            <SoftBox pb={3} px={3}>
              <SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      type={formFields.currentPassword.type}
                      label={formFields.currentPassword.label}
                      name={formFields.currentPassword.name}
                      value={values.currentPassword}
                      placeholder={formFields.currentPassword.placeholder}
                      error={errors.currentPassword && touched.currentPassword && isEditMode}
                      success={values.currentPassword.length > 0 && !errors.currentPassword && isEditMode}
                      inputProps={{type: "password", autoComplete: ""}}
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type={formFields.newPassword.type}
                      label={formFields.newPassword.label}
                      name={formFields.newPassword.name}
                      value={values.newPassword}
                      placeholder={formFields.newPassword.placeholder}
                      error={errors.newPassword && touched.newPassword && isEditMode}
                      success={values.newPassword.length > 0 && !errors.newPassword && isEditMode}
                      inputProps={{type: "password", autoComplete: ""}}
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type={formFields.repeatPassword.type}
                      label={formFields.repeatPassword.label}
                      name={formFields.repeatPassword.name}
                      value={values.repeatPassword}
                      placeholder={formFields.repeatPassword.placeholder}
                      error={errors.repeatPassword && touched.repeatPassword && isEditMode}
                      success={values.repeatPassword.length > 0 && !errors.repeatPassword && isEditMode}
                      inputProps={{type: "password", autoComplete: ""}}
                      disabled={!isEditMode}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mt={6} mb={1}>
                <SoftTypography variant="h5">Password requirements</SoftTypography>
              </SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="text">
                  Please follow this guide for a strong password
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <SoftBox component="ul" m={0} pl={3.25} mb={{xs: 8, sm: 0}}>
                  {renderPasswordRequirements}
                </SoftBox>
                <SoftBox ml="auto">
                  {isEditMode ? (
                    <SoftBox display="flex" flexDirection={{xs: "column", sm: "row"}}>
                      <SoftButton key="notsubmit" variant="gradient" color="dark" type="button" onClick={resetForm}
                                  mr={2}> cancel </SoftButton>
                      <SoftBox ml={{xs: 0, sm: 1}} mt={{xs: 1, sm: 0}}>
                        <SoftButton key="submit" variant="gradient" color="success" type="submit"
                                    disabled={isSubmitting}> update password </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  ) : (
                    <SoftButton key="notsubmit" variant="gradient" color="dark" type="button"
                                onClick={handleEdit}> edit </SoftButton>
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default ChangePassword;
