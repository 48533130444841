/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";
import MediaQuery from 'react-responsive'

// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

// Pricing page components
import FaqCollapse from "../FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <SoftBox mt={16}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <MediaQuery maxWidth={430}>
            <SoftTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
              {t('faq.header')}
            </SoftTypography>
          </MediaQuery>
          <MediaQuery minWidth={1430}>
            <SoftTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              {t('faq.header')}
            </SoftTypography>
          </MediaQuery>
        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title={t('faq.subHeader1')}
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            {t('faq.description1')}
          </FaqCollapse>
          <FaqCollapse
            title={t('faq.subHeader2')}
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            {t('faq.description2')}
          </FaqCollapse>
          <FaqCollapse
            title={t('faq.subHeader3')}
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            {t('faq.description3')}
          </FaqCollapse>
          <FaqCollapse
            title={t('faq.subHeader4')}
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            {t('faq.description4')}
          </FaqCollapse>
          <FaqCollapse
            title={t('faq.subHeader5')}
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            {t('faq.description5')}
          </FaqCollapse>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Faq;
