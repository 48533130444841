const form = {
    formId: "sign-up-form",
    formField: {
        firstName: {
            name: "firstName",
            label: "first name",
            type: "text",
            placeholder: "First name",
            errorMsg: "First name is required.",
        },
        lastName: {
            name: "lastName",
            label: "last name",
            type: "text",
            placeholder: "Last name",
            errorMsg: "Last name is required.",
        },
        email: {
            name: "email",
            label: "email address",
            type: "email",
            placeholder: "Email",
            errorMsg: "Email address is required.",
            invalidMsg: "Your email address is invalid",
        },
        password: {
            name: "password",
            label: "password",
            type: "password",
            placeholder: "Password",
            errorMsg: "Password is required.",
            invalidMsg: "Your password should be more than 6 characters.",
        },
        repeatPassword: {
            name: "repeatPassword",
            label: "confirm password",
            type: "password",
            placeholder: "Confirm Password",
            errorMsg: "Confirm Password is required.",
            invalidMsg: "Your password doesn't match.",
        },
    },
};

export default form;
