import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'translate/languages';
import SoftBox from "components/SoftBox";
import { useState } from 'react';

import style from './style.css';


function TranslateSelect() {
    const { t, i18n } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectOption = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
        const lang_code = option.code;
        i18n.changeLanguage(lang_code);
    };

    return (
        <SoftBox style={style}>
            <div className='container' >
                <div className="dropdown">
                    <div className="dropdown-toggle" onClick={toggleDropdown}>
                        {selectedOption && (
                            <img src={selectedOption.image} alt={selectedOption.label} className="dropdown-option-image" />
                        )}
                        {!selectedOption && (
                            <img src={LANGUAGES[0].image} alt={LANGUAGES[0].label} className="dropdown-option-image" />
                        )}
                    </div>
                    <ul className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
                        {LANGUAGES.map((option, index) => (
                            <li key={index} onClick={() => selectOption(option)}>
                                <img src={option.image} alt={option.label} className="dropdown-option-image" />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </SoftBox>
    )
}

export default TranslateSelect;
