/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

function ProductImages({images}) {
  const [galleryImages, setGalleryImages] = useState([]);

  const [currentImage, setCurrentImage] = useState();
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  useEffect(() => {
    setGalleryImages(images)
    setCurrentImage(images[0])
  }, []);

  // [{ src: image1 }, { src: image2 }, { src: image3 }, { src: image4 }, { src: image5 }]
  return (
    <SoftBox>
      <ImgsViewer
        imgs={galleryImages.map((imageUrl) => {return {src: imageUrl}})}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />
      <SoftBox
        component="img"
        src={currentImage} 
        alt="Sport Image"
        shadow="md"
        borderRadius="lg"
        width="100%"
        onClick={openImgsViewer}
        style={{objectFit: "cover"}}
        maxHeight="80vh"
      />
      <SoftBox mt={2} pt={1}>
        <Grid container spacing={3}>
          {galleryImages?.map((imageUrl, index) => (
            <Grid item xs={2}>
              <SoftBox
                component="img"
                id={0}
                src={imageUrl}
                alt="small image 1"
                borderRadius="lg"
                shadow="md"
                width="100%"
                sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
                onClick={handleSetCurrentImage}
              />
            </Grid>
          ))}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductImages;
