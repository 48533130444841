const UserStatus = {
    PENDING: "pending",
    ACTIVE: "active",
    DEACTIVATED: "deactivated",
    BLOCKED: "blocked",
    DELETED: "deleted",
}

function getDisplayUserStatus(status, t) {
    switch (status) {
        case UserStatus.PENDING:
            return t('user.status.pending')
        case UserStatus.ACTIVE:
            return t('user.status.active')
        case UserStatus.DEACTIVATED:
            return t('user.status.deactivated')
        case UserStatus.BLOCKED:
            return t('user.status.blocked')
        case UserStatus.DELETED:
            return t('user.status.deleted')
    }
}


export default UserStatus;

export {
    UserStatus,
    getDisplayUserStatus
};
