/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import FormField from "components/common/FormField";

// Form
import { formFields } from "layouts/authentication/reset-password-confirm/schemas/form";
import { validations } from "layouts/authentication/reset-password-confirm/schemas/validations";
import { initialValues } from "layouts/authentication/reset-password-confirm/schemas/initialValues";
import { GetErrorMessage } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import RoutesNames from "helpers/enums/routes-map.enum";


function ResetPasswordConfirm() {
  let params = useParams();
  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(false);

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  const handleSubmit = async (values, actions) => {
    try {
      setIsSubmited(true);
      await CreatePostRequest(BackRequests.PASSWORD_RESET_CONFIRM + params.userId + "/" + params.token, {
        newPassword: values.newPassword,
      });
      openSuccessSB("Password reset finished successfully");
    } catch (error) {
      console.log("usao u error")
      openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <PageLayout background="light">
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ minHeight: "75vh" }}
      >
        <Grid item xs={10} md={6} lg={4}>
          <SoftBox mt={32} mb={3} px={{ xs: 0, lg: 2 }}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id="reset-password-form" autoComplete="off">
                  <Card>
                    <SoftBox pt={3} px={3} pb={1} textAlign="center">
                      <SoftTypography
                        variant="h4"
                        fontWeight="bold"
                        textGradient
                        mb={2}
                      >
                        Reset password
                      </SoftTypography>
                      <SoftTypography variant="body2" color="text">
                        Please insert Your new password
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox p={3}>
                      <SoftBox>
                        <SoftBox>
                          <Grid container>
                            <Grid item xs={12}>
                              <FormField
                                type={formFields.newPassword.type}
                                label={formFields.newPassword.label}
                                name={formFields.newPassword.name}
                                value={values.newPassword}
                                placeholder={formFields.newPassword.placeholder}
                                error={
                                  errors.newPassword &&
                                  touched.newPassword
                                }
                                success={
                                  values.newPassword.length > 0 &&
                                  !errors.newPassword
                                }
                                inputProps={{
                                  type: "password",
                                  autoComplete: "",
                                }}
                                disabled={isSubmited}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormField
                                type={formFields.repeatPassword.type}
                                label={formFields.repeatPassword.label}
                                name={formFields.repeatPassword.name}
                                value={values.repeatPassword}
                                placeholder={
                                  formFields.repeatPassword.placeholder
                                }
                                error={
                                  errors.repeatPassword &&
                                  touched.repeatPassword
                                }
                                success={
                                  values.repeatPassword.length > 0 &&
                                  !errors.repeatPassword
                                }
                                inputProps={{
                                  type: "password",
                                  autoComplete: "",
                                }}
                                disabled={isSubmited}
                              />
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mt={5} mb={1}>
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            size="large"
                            fullWidth
                            disabled={isSubmited}
                          >
                            reset password
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </SoftBox>
        </Grid>
      </Grid>
      { ErrorMessageSnackBar({title: "Reset password error", errorMessage, errorSB, closeErrorSB}) }
      { SuccessMessageSnackBar({title: "Success", successMessage, successSB, closeSuccessSB}) }
    </PageLayout>
  );
}

export default ResetPasswordConfirm;
