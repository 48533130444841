/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { useTranslation } from "react-i18next";

// Images
import { getDisplayBusinessItemType } from "helpers/enums/business-item.enum";
import RoutesNames from "helpers/enums/routes-map.enum";
import { Link, useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";

function Header({ id, name, image, type }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onEditBusiness = async () => {
    navigate(RoutesNames.EDIT_BUSINESS, { state: { businessItemId:id, isAdmin: true } });
  }

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={image}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
              component={Link} to={RoutesNames.EVENT} state={{ id, isAdmin: true }}
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1} component={Link} to={RoutesNames.EVENT} state={{ id, isAdmin: true }}>
              <SoftTypography variant="h5" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {getDisplayBusinessItemType(type, t)}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftButton color="secondary" size="small" onClick={onEditBusiness}>
                {t('businessItem.settings.button.edit')}
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Header;
