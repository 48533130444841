/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {transparent} = colors;

const stepper = {
  styleOverrides: {
    root: {
      margin: `${pxToRem(48)} 0`,
      padding: `0 ${pxToRem(12)}`,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main,
      },
    },
  },
};

export default stepper;
