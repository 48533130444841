/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import RoutesNames from "helpers/enums/routes-map.enum";
import colors from "assets/theme/base/colors";
import BusinessInfoPreviewInCard from "../BusinessInfoPreviewInCard";

function SearchResultCard({ image, title, type, addresses, sports, rating, ratingCount, maxDiscount, eventId, isSponsored }) {
  const { t, i18n } = useTranslation();

  return (
    <Card sx={{
      height: "100%",
      ...(isSponsored === true && {
        boxShadow: "0 0 8px 1px",
        color: colors.secondary.main
      }),
    }} >
      <SoftBox p={1} display="flex" sx={{ height: "100%" }}>
        <Grid container spacing={1} >
          <Grid item xs={12} md={6} lg={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftBox component={Link} to={RoutesNames.EVENT} state={{ id: eventId }} display="flex" width="100%">
              <SoftBox
                component="img"
                src={image}
                alt="message-image"
                borderRadius="lg"
                shadow="md"
                width="100%"
                // height="25vh"
                style={{ objectFit: "cover" }}
              //display="inherit"
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6} display="flex" flexDirection="column">
            <BusinessInfoPreviewInCard
              title={title} 
              type={type} 
              addresses={addresses}
              sports={sports} 
              rating={rating} 
              ratingCount={ratingCount} 
              maxDiscount={maxDiscount} 
              eventId={eventId} 
              showDivider
            />
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

SearchResultCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addresses: PropTypes.array,
  sports: PropTypes.array,
  rating: PropTypes.string.isRequired,
  maxDiscount: PropTypes.number.isRequired,
  isSponsored: PropTypes.bool,
};

export default SearchResultCard;
