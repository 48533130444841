import SoftSnackbar from "components/SoftSnackbar";

function ErrorMessageSnackBar({ title, errorMessage, errorSB, closeErrorSB }) {
  return (
    <SoftSnackbar
      color="error"
      icon="warning"
      title={title}
      content={errorMessage}
      dateTime="0 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
}

function SuccessMessageSnackBar({
  title,
  successMessage,
  successSB,
  closeSuccessSB,
}) {
  return (
    <SoftSnackbar
      color="success"
      icon="info"
      title={title}
      content={successMessage}
      dateTime="0 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
}

export { ErrorMessageSnackBar, SuccessMessageSnackBar };
