/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Moment from 'moment';
import UserRoles from "helpers/enums/user-role.enum";

// Material
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';

// Components
import SoftBadge from 'components/SoftBadge';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { IconButton } from '@mui/material';

const dataTableData = {
  columns: [
    {Header: "name", accessor: "name", width: "20%"},
    {Header: "email", accessor: "email", width: "20%"},
    {Header: "role", accessor: "role", width: "20%"},
    {Header: "registration date", accessor: "registrationDate", width: "20%"},
    {Header: "actions", accessor: "actions", width: "20%"},
  ],
  rows: []
};

const populateTableRows = (data, openUserSettings, t) => {
  const rowsData = data.map((user) => {
    return {
      id: user.id,
      name: user.firstName + " " + user.lastName,
      email: user.email,
      role: getRoleBadge(user.accountData.role, t),
      registrationDate: Moment(user.accountData.dateOfRegistration).format("DD/MM/YYYY, HH:mm:ss"),
      actions: ActionCell(openUserSettings, user.id) 
    }
  });
  const resp = {
    columns: dataTableData.columns,
    rows: rowsData,
  }
  return resp;
};

const getRoleBadge = (role, t) => {
  switch (role) {
    case UserRoles.SYSTEM_ADMIN:
      return (
        <SoftBadge variant="contained" color="dark" size="xs" badgeContent={t('user.role.systemAdmin')} container />
      )
    case UserRoles.BUSINESS_ADMIN:
      return (
        <SoftBadge variant="contained" color="info" size="xs" badgeContent={t('user.role.businessAdmin')} container />
      )
    case UserRoles.END_CLIENT:
      return (
        <SoftBadge variant="contained" color="success" size="xs" badgeContent={t('user.role.endClient')} container />
      )
  }
};

function ActionCell(openUserSettings, userId) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox ml={1}>
            <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Settings" placement="left">
                <IconButton color="secondary" size="small"
                  onClick={() => {
                    openUserSettings(userId)
                  }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export { 
  dataTableData,
  populateTableRows,
  getRoleBadge
};

