/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { FieldArray } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";

// Form schemas
import { preventMinus, preventPasteNegative } from "layouts/pages/business/edit-business/schemas/validations";
import { formFields } from "layouts/pages/business/edit-business/schemas/form";

// Helpers
import { BusinessItemTypes } from "helpers/enums/business-item.enum";

import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { useTranslation } from "react-i18next";

function ProgramDetailsEdit({ values, errors, touched, type, setFieldValue }) {
  const { t, i18n } = useTranslation();
  const [ageCategoriesOptions, setAgeCategoriesOptions] = useState([]);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [skillLevelsOptions, setSkillLevelsOptions] = useState([]);
  const [selectedTypeValue, setSelectedTypeValue] = useState(BusinessItemTypes.PERSONAL_COACH);

  const renderAdditionalData = (values, errors, touched, setFieldValue) => {
    switch (selectedTypeValue) {
      case BusinessItemTypes.SPORT_FACILITY:
        return (
          <SoftBox mb={1} mt={-1} isplay="inline-block">
            <SoftBox>
              <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('businessItem.editPage.workingHours')} *
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" mb={2}>
                <FieldArray
                  name={formFields.workingHours.name}
                  error={errors.workingHours}
                  render={arrayHelpers => (
                    <SoftBox>
                      {values.workingHours?.map((workingHoursElem, index) => (
                        <Grid container spacing={3} key={index}>
                          <Grid item xs={12} sm={5}>
                            <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {formFields.workingHours.props.day.label}
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              name={`workingHours[${index}].day`}
                              defaultValue={initialValues.workingHours[index] ? initialValues.workingHours[index]?.day : formFields.workingHours.props.day.default}
                              options={formFields.workingHours.props.day.options}
                              onChange={(event) => {
                                setFieldValue(`workingHours[${index}].day`, event);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              type={formFields.workingHours.props.startTime.type}
                              label={formFields.workingHours.props.startTime.label}
                              name={`workingHours[${index}].startTime`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              type={formFields.workingHours.props.endTime.type}
                              label={formFields.workingHours.props.endTime.label}
                              name={`workingHours[${index}].endTime`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t('businessItem.editPage.action')}
                              </SoftTypography>
                            </SoftBox>
                            <Tooltip title="Remove" placement="bottom" arrow>
                              <SoftBox>
                                <IconButton variant="gradient" color="error" size="medium"
                                  disabled={values.workingHours.length <= 1}
                                  onClick={() => {
                                    if (values.workingHours.length > 1) {
                                      arrayHelpers.remove(index)
                                    }
                                  }}>
                                  <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                                </IconButton>
                              </SoftBox>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                        <Tooltip title="Add" placement="bottom" arrow>
                          <SoftButton variant="gradient" color="info" size="small"
                            onClick={() => arrayHelpers.push({
                              day: formFields.workingHours.props.day.default,
                              startTime: "",
                              endTime: ""
                            })}
                          >
                            + {t('businessItem.editPage.addButton')}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  )}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>)
      case BusinessItemTypes.GROUP_ACTIVITY:
        return (
          <SoftBox mb={1} mt={-1} isplay="inline-block">
            <SoftBox>
              <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('businessItem.editPage.schedule')} *
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" mb={2}>
                <FieldArray
                  name={formFields.schedule.name}
                  error={errors.schedule}
                  render={arrayHelpers => (
                    <SoftBox>
                      {values.schedule?.map((scheduleElem, index) => (
                        <Grid container spacing={3} key={index}>
                          <Grid item xs={12} sm={5}>
                            <FormField
                              type={formFields.schedule.props.description.type}
                              label={formFields.schedule.props.description.label}
                              name={`schedule[${index}].description`}
                              placeholder={formFields.schedule.props.description.placeholder}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              type={formFields.schedule.props.startTime.type}
                              label={formFields.schedule.props.startTime.label}
                              name={`schedule[${index}].startTime`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              type={formFields.schedule.props.endTime.type}
                              label={formFields.schedule.props.endTime.label}
                              name={`schedule[${index}].endTime`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t('businessItem.editPage.action')}
                              </SoftTypography>
                            </SoftBox>
                            <Tooltip title="Remove" placement="bottom" arrow>
                              <SoftBox>
                                <IconButton variant="gradient" color="error" size="medium"
                                  disabled={values.schedule.length <= 1}
                                  onClick={() => {
                                    if (values.schedule.length > 1) {
                                      arrayHelpers.remove(index)
                                    }
                                  }}>
                                  <DeleteIcon sx={{ fontWeight: "bold" }}>{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>
                                </IconButton>
                              </SoftBox>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                        <Tooltip title="Add" placement="bottom" arrow>
                          <SoftButton variant="gradient" color="info" size="small"
                            onClick={() => arrayHelpers.push({
                              description: "",
                              startTime: "",
                              endTime: ""
                            })}
                          >
                            + {t('businessItem.editPage.addButton')}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  )}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>)
      default:
        break;
    }
  }


  const getAgeCategoriesOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_AGE_CATEGORIES);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getSportsOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_SPORTS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getSkillLevelsOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_SKILL_LEVELS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (type) {
        setSelectedTypeValue(type);
      }
      setAgeCategoriesOptions(await getAgeCategoriesOptions());
      setSportsOptions(await getSportsOptions());
      setSkillLevelsOptions(await getSkillLevelsOptions());
    }
    loadData();

  }, []);

  return (
    <SoftBox p={3} display="grid">
      <SoftTypography variant="h5">{t('businessItem.editPage.programDetails')}</SoftTypography>
      <SoftBox mt={1.625}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                {formFields.ageCategory.label}
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              name={formFields.ageCategory.name}
              options={ageCategoriesOptions}
              isMulti
              value={values.ageCategory}
              onChange={(event) => {
                setFieldValue(formFields.ageCategory.name, event);
              }}
              error={(errors.ageCategory && touched.ageCategory) ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                {formFields.gender.label}
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              name={formFields.gender.name}
              options={formFields.gender.options}
              value={values.gender}
              onChange={(event) => {
                console.log(event)
                setFieldValue(formFields.gender.name, event);
              }}
              error={(errors.gender && touched.gender) ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                {formFields.skillLevel.label}
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              name={formFields.skillLevel.name}
              options={skillLevelsOptions}
              isMulti
              value={values.skillLevel}
              onChange={(event) => {
                setFieldValue(formFields.skillLevel.name, event);
              }}
              error={(errors.skillLevel && touched.skillLevel) ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                {formFields.sport.label}
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              name={formFields.sport.name}
              options={sportsOptions}
              isMulti
              value={values.sport}
              onChange={(event) => {
                setFieldValue(formFields.sport.name, event);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={formFields.numberOfFreeTrainings.type}
              label={formFields.numberOfFreeTrainings.label}
              name={formFields.numberOfFreeTrainings.name}
              value={values.numberOfFreeTrainings}
              placeholder={formFields.numberOfFreeTrainings.placeholder}
              onKeyPress={preventMinus}
              onPaste={preventPasteNegative}
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
      </SoftBox>
      {/* <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderAdditionalData(values, errors, touched, setFieldValue)}
          </Grid>
        </Grid>
      </SoftBox> */}
    </SoftBox>
  );
}

export default ProgramDetailsEdit;
