/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

 import { useEffect, useState } from "react";

 import Checkbox from "@mui/material/Checkbox";
 import {Form, Formik} from "formik";
 
 // Soft UI Dashboard PRO React components
 import SoftBox from "components/SoftBox";
 import SoftTypography from "components/SoftTypography";

 // Form schemas
import {validations} from "layouts/pages/business/new-business/schemas/validations";
import {fields} from "layouts/pages/business/new-business/schemas/form";
import {initial} from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
 

function SportFacilityAdditionals({activeStep, isLastStep, handleNext, handleBack, type}) {
  const [additionalsOptions, setAdditionalsOptions] = useState([]);

  // Form data
  const formValidation = validations[FormNames.SPORT_FACILITY_ADDITIONALS_FORM];
  const formFields = fields[FormNames.SPORT_FACILITY_ADDITIONALS_FORM];
  const initialValues = initial[FormNames.SPORT_FACILITY_ADDITIONALS_FORM];

  const handleSubmit = async (values, actions) => {
    try {
      console.log("SPORT FACILITY ADDITIONALS HANDLE SUBMIT")
      console.log(values)
     
      handleNext({
        additionals: values.additionals,
      })
    } catch (error) {
      // TODO
      // openErrorSB(GetErrorMessage(error));
    }
  };


  const getAdditionalsOptions = async () => {
    try {
      // Get client info from database
      const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_ADDITIONAL + type);
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setAdditionalsOptions(await getAdditionalsOptions());
    }
    loadData();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({values, errors, touched, isSubmitting, setFieldValue}) => (
        <Form id={FormNames.SPORT_FACILITY_ADDITIONALS_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5">Additionals</SoftTypography>
            <SoftBox mt={3}>
              {additionalsOptions.map((option) => (
                <SoftBox key={option.value + "-soft-box"}>
                  <Checkbox 
                    name={formFields.additionals.name}
                    checked={values.additionals.findIndex((value) => value == option.value) > -1}
                    onChange={(event) => {
                      if (event.target.checked) {
                        values.additionals.push(option.value)
                      } else {
                        const index = values.additionals.findIndex((value) => value == option.value);
                        if (index > -1) { 
                          values.additionals.splice(index, 1);
                        }
                      }
                      setFieldValue(formFields.additionals.name, values.additionals)
                    }}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    {option.label}
                  </SoftTypography>
                </SoftBox>
              ))}
            </SoftBox>
            <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting}/>
          </SoftBox>
        </Form>
      )}
    </Formik>
   );
 }
 
 export default SportFacilityAdditionals;
 