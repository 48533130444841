/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState} from "react";
import {Form, Formik} from "formik";
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import FormField from "components/common/FormField";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Separator from "layouts/authentication/components/Separator";

// Sign-in schemas for form and form fields
import validations from "layouts/authentication/sign-in/basic/schemas/validation";
import form from "layouts/authentication/sign-in/basic/schemas/form";
import initialValues from "layouts/authentication/sign-in/basic/schemas/initialValues";


// Images
import runnerImg from "assets/images/auth-images/runner.jpg";
import RoutesNames from "helpers/enums/routes-map.enum";
import UserRoles from "helpers/enums/user-role.enum";

// Helpers
import {ErrorMessageSnackBar} from "helpers/messages/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import {CreatePostRequest, GetErrorMessage} from "helpers/back-request/helpers"

function Basic() {
  const { formId, formField } = form;
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);

  const [rememberMe, setRememberMe] = useState(false);

  // TODO
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (values, actions) => {
    try {
      const response = await CreatePostRequest(BackRequests.SIGN_IN,
        {email: values.email, password: values.password});

      localStorage.setItem('user', JSON.stringify(response.data.data));
      localStorage.setItem('userId',response.data.data.userId);
      redirectAfterSuccessLogin(response.data.data.role);
    } catch (error) {
      openErrorSB(GetErrorMessage(error));
    }
  };

  function redirectAfterSuccessLogin(role) {
    switch (role) {
      case UserRoles.SYSTEM_ADMIN:
        navigate(RoutesNames.ADMIN_CRM);
        return;
      case UserRoles.BUSINESS_ADMIN:
        navigate(RoutesNames.BUSINESS_DASHBOARD);
        return;
      case UserRoles.END_CLIENT:
        // navigate(RoutesNames.CLIENT_DASHBOARD);
        // TODO
        navigate(-1);
        return;
    }
  }

  return (
    <BasicLayout
      title="Welcome!"
      description="Use this form to login to your MoveOn account."
      image={runnerImg}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Sign in
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox mb={2}>
          <Socials/>
        </SoftBox> */}
        <SoftBox p={3}>
          <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id={formId} autoComplete="off">
                    <SoftBox>
                      <SoftBox mb={2}>
                        <FormField
                          type={formField.email.type}
                          label={formField.email.label}
                          name={formField.email.name}
                          value={values.email}
                          placeholder={formField.email.placeholder}
                          error={errors.email && touched.email}
                        />
                      </SoftBox>
                      <SoftBox mb={2}>
                        <FormField
                          type={formField.password.type}
                          label={formField.password.label}
                          name={formField.password.name}
                          value={values.password}
                          placeholder={formField.password.placeholder}
                          error={errors.password && touched.password}
                        />
                      </SoftBox>
                      {/* <SoftBox display="flex" alignItems="center">
                        <Switch checked={rememberMe} onChange={handleSetRememberMe}/>
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          onClick={handleSetRememberMe}
                          sx={{cursor: "pointer", userSelect: "none"}}
                        >
                          &nbsp;&nbsp;Remember me
                        </SoftTypography>
                      </SoftBox> */}
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          type="submit"
                        >
                          sign in
                        </SoftButton>
                      </SoftBox>
                      <Separator/>
                      <SoftBox mt={1} mb={3}>
                        <SoftButton
                          component={Link}
                          to={RoutesNames.SIGNUP}
                          variant="gradient"
                          color="dark"
                          fullWidth
                        >
                          sign up
                        </SoftButton>
                      </SoftBox>
                      <SoftBox mt={3} textAlign="center">
                        <SoftTypography
                          variant="button"
                          color="text"
                          fontWeight="regular"
                        >
                          Forgot your password?&nbsp;
                          <SoftTypography
                            component={Link}
                            to={RoutesNames.PASSWORD_RESET_INIT}
                            variant="button"
                            color="dark"
                            fontWeight="bold"
                            textGradient
                          >
                            Reset password
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Form>
                )}
            </Formik>
          </SoftBox>
      </Card>
      { ErrorMessageSnackBar({title: "Sign in error", errorMessage, errorSB, closeErrorSB}) }
    </BasicLayout>
  );
}

export default Basic;