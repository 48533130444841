const form = {
    formId: "sign-in-form",
    formField: {
        email: {
            name: "email",
            label: "email address",
            type: "email",
            placeholder: "Email",
            errorMsg: "Email address is required.",
            invalidMsg: "Your email address is invalid",
        },
        password: {
            name: "password",
            label: "password",
            type: "password",
            placeholder: "Password",
            errorMsg: "Password is required.",
            invalidMsg: "Your password should be more than 6 characters.",
        },
    },
};

export default form;
