/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import UserRoles from "helpers/enums/user-role.enum";

const userRolesDoughnutChartData = {
  labels: [UserRoles.END_CLIENT, UserRoles.BUSINESS_ADMIN],
  datasets: {
    label: "Projects",
    backgroundColors: ["dark", "secondary"],
    data: [60,12],
  },
};

const populateUsersDoughnutChartData = (statisticsData) => {
  const doughnutChartData = userRolesDoughnutChartData;
  doughnutChartData.datasets.data = [statisticsData.numOfEndClients, statisticsData.numOfBusinessAdmins]
  return doughnutChartData;
};

export { 
  userRolesDoughnutChartData,
  populateUsersDoughnutChartData
};
