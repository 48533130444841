
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SoftButton from 'components/SoftButton';
import IconButton from "@mui/material/IconButton";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { CreatePostRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { useTranslation } from "react-i18next";

const ApproveBusinessConfirmDialog = ({ businessId, businessName, handleClose, openSuccessSB, openErrorSB, getBusinessesData }) => {
    const { t, i18n } = useTranslation();

    const onApproveBusiness = async () => {
        try {
            await CreatePostRequest(BackRequests.APPROVE_BUSINESS_REQUEST, { businessItemId: businessId });
            getBusinessesData();
            handleClose();
            openSuccessSB(t('approveConfirmDialog.successMessage'));
        } catch (error) {
            console.log(error)
            handleClose()
            openErrorSB(GetErrorMessage(error));
        }
    }

    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <DialogTitle> {t('approveConfirmDialog.title')}</DialogTitle>
            <SoftBox position="absolute" top={0} right={0}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </SoftBox>
            <DialogContent>
                <SoftTypography variant="body2"> {t('approveConfirmDialog.question')} {businessName}?</SoftTypography>
            </DialogContent>
            <DialogActions>
                <SoftButton color="primary" variant="contained" onClick={handleClose}>
                    {t('approveConfirmDialog.cancel')}
                </SoftButton>
                <SoftButton color="secondary" variant="contained"
                    onClick={onApproveBusiness}>
                    {t('approveConfirmDialog.approve')}
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
};

export default ApproveBusinessConfirmDialog;