// Images
import eng_flag from "assets/images/moveon/eng.jpg";
import srb_flag from "assets/images/moveon/srb.png";

const LANGUAGES = [
    { label: "English", code: "en" , image: eng_flag},
    { label: "Serbian", code: "sr", image: srb_flag },
];

export {
    LANGUAGES
}