/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DeleteBusiness from "./components/DeleteBusiness";
import Header from "./components/Header";
import PromoteBusiness from "./components/PromoteBusiness";
import BusinessOwner from "./components/BusinessOwner";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function BusinessSettings() {
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [businessConfigData, setBusinessConfigData] = useState();

  const getBusinessSettingsData = async () => {
    try {
      console.log("getBusinessSettingsData")
      // location.state.businessItemId
      // Get business data from database
      const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_BY_ID + location.state.businessItemId);
      const businessData = response.data.data;

      let mainImage = null;
      if (businessData.mediaPayload?.mainImage) {
        const mainImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessData.mediaPayload?.mainImage, { responseType: 'blob' });
        businessData.mediaPayload.mainImageUrl = URL.createObjectURL(mainImageResponse.data);
      }

      return businessData;
    } catch (error) {
      console.log("error")
      console.log(error)
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setShowLoader(true);
      const businessSettingsDataResp = await getBusinessSettingsData();
      console.log(businessSettingsDataResp)

      setBusinessConfigData(businessSettingsDataResp);
      setShowLoader(false);
    }
    loadData();
  }, []);

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Tabovi: Logovi, uplate
  // Sekcija: kada je registrovan, kada je poslednja izmena
  //loader

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid> */}
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header
                    id={businessConfigData?.id}
                    name={businessConfigData?.name}
                    type={businessConfigData?.type}
                    image={businessConfigData?.mediaPayload?.mainImageUrl} />
                </Grid>
                {businessConfigData &&
                  <Grid item xs={12}>
                    <BusinessOwner
                      businessItemId={businessConfigData?.id}
                    />
                  </Grid>
                }
                {businessConfigData &&
                  <Grid item xs={12}>
                    <PromoteBusiness businessName={businessConfigData?.name} businessId={businessConfigData?.id} initPromotionStatus={businessConfigData?.isSponsored} />
                  </Grid>}
                <Grid item xs={12}>
                  <DeleteBusiness businessName={businessConfigData?.name} businessId={businessConfigData?.id} />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default BusinessSettings;
