/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import moveon_logo_text from "assets/images/moveon/moveon_tennisball_orange.png";
import TranslateSelect from "../TranslateSelect";
import PublicNavbar from "examples/Navbars/PublicNavbar";
import routes from "routes";
import { useTranslation } from "react-i18next";
import RoutesNames from "helpers/enums/routes-map.enum";
import SoftAvatar from "components/SoftAvatar";
import clientAvatar from "assets/images/client-avatar.png";
import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { Grid, MenuItem } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { clearUserDataFromLocalStorage } from "helpers/general/helpers";
import { useEffect, useState } from "react";
import SoftTypography from "components/SoftTypography";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import UserRoles from "helpers/enums/user-role.enum";
import MediaQuery from "react-responsive";

function HeaderMenu() {
    const { t, i18n } = useTranslation();
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userProfileImage, setUserProfileImage] = useState(clientAvatar);
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState(UserRoles.END_CLIENT)

    useEffect(() => {
        (async () => {
            const userData = JSON.parse(localStorage.getItem("user"));
            if (userData) {
                setUserLoggedIn(true);
                // if (userData.profileImage) {
                //     const profileImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.profileImage, { responseType: 'blob' });
                //     setUserProfileImage(URL.createObjectURL(profileImageResponse.data));
                // }
                setUserRole(userData.role);
            } else {
                setUserLoggedIn(false);
            }
        })();
    }, []);

    // Menu
    const [openMenu, setOpenMenu] = useState(false);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const handleSignout = () => {
        //console.log("DADADAAAAA")
        clearUserDataFromLocalStorage();
        navigate(RoutesNames.HOME);
        setUserLoggedIn(false);
    };

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 1 }}
        >
            <MenuItem
                key="settingsMenuItem"
                component={Link}
                to={RoutesNames.CLIENT_PROFILE}
            >
                <SoftBox display="flex" alignItems="center">
                    <SettingsIcon color="primary" size="large" />
                    <SoftTypography variant="body2" ml={1}>{t('clientNavbar.settings')}</SoftTypography>
                </SoftBox>
            </MenuItem>
            <MenuItem
                key="myReservationsMenuItem"
                component={Link}
                to={RoutesNames.CLIENT_RESERVATIONS}
            >
                <SoftBox display="flex" alignItems="center">
                    <LocalActivityIcon color="primary" size="large" />
                    <SoftTypography variant="body2" ml={1}>{t('clientNavbar.reservations')}</SoftTypography>
                </SoftBox>
            </MenuItem>
            {userRole && userRole == UserRoles.END_CLIENT && (
                <MenuItem
                    key="becomeBusinessMenuItem"
                    component={Link}
                    to={RoutesNames.NEW_BUSINESS}
                >
                    <SoftBox display="flex" alignItems="center">
                        <RocketLaunchIcon color="primary" size="large" />
                        <SoftTypography variant="body2" ml={1}>{t('clientNavbar.becomeBusiness')}</SoftTypography>
                    </SoftBox>
                </MenuItem>
            )}
            {userRole && userRole == UserRoles.BUSINESS_ADMIN && (
                <MenuItem
                    key="businessProfile"
                    component={Link}
                    to={RoutesNames.BUSINESS_DASHBOARD}
                >
                    <SoftBox display="flex" alignItems="center">
                        <BusinessCenterIcon color="primary" size="large" />
                        <SoftTypography variant="body2" ml={1}>{t('clientNavbar.businessProfile')}</SoftTypography>
                    </SoftBox>
                </MenuItem>
            )}
            <MenuItem
                key="signoutMenuItem"
                onClick={handleSignout}
            >
                <SoftBox display="flex" alignItems="center">
                    <LogoutIcon color="primary" size="large" />
                    <SoftTypography variant="body2" ml={1}>{t('clientNavbar.signout')}</SoftTypography>
                </SoftBox>
            </MenuItem>
        </Menu>
    );


    return (
        <SoftBox
            bgColor="primary"
            display="flex"
            alignItems="center"
            position="relative"
            marginBottom={0}
            style={{ margin: "-1px" }}
        >
            <MediaQuery minWidth={431}>
                <Grid container spacing={3} alignItems="center" padding="10px 0 0 0" >
                    <Grid item xs={2} md={2} lg={2}>
                        <a href="/home"><img src={moveon_logo_text}
                            alt="Move ON"
                            width={70}
                            style={{ margin: "5px 0", }}
                        /></a>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} >
                        {!userLoggedIn && <PublicNavbar
                            routes={routes}
                            action={{
                                type: "internal",
                                route: "/authentication/sign-in",
                                label: t('auth.login'),
                            }}
                            transparent
                            light
                        />}
                        {userLoggedIn && <PublicNavbar
                            routes={routes}
                            transparent
                            light
                        />}

                    </Grid>
                    <Grid item xs={2} md={2} lg={2} mb="6px" display="flex" alignItems="center" >
                        <TranslateSelect />
                        {userLoggedIn && <SoftBox ml={2}>
                            <SoftAvatar
                                src={userProfileImage}
                                alt="profile-image"
                                variant="rounded"
                                size="sm"
                                shadow="sm"
                                onClick={handleOpenMenu}
                            />
                            {renderMenu()}
                        </SoftBox>
                        }
                    </Grid>
                </Grid>
            </MediaQuery>
            <MediaQuery maxWidth={431}>
                <Grid container alignItems="center" padding="10px 0 0 0" >
                    <Grid item xs={4} md={4} lg={4} justifyContent="start" pl={1}>
                        {!userLoggedIn && <PublicNavbar
                            routes={routes}
                            action={{
                                type: "internal",
                                route: "/authentication/sign-in",
                                label: t('auth.login'),
                            }}
                            transparent
                            light
                        />}
                        {userLoggedIn && <PublicNavbar
                            routes={routes}
                            transparent
                            light
                        />}

                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <a href="/home"><img src={moveon_logo_text}
                            alt="Move ON"
                            width={70}
                            style={{ margin: "5px 0", }}
                        /></a>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} mb="6px" pr={3} display="flex" alignItems="center" justifyContent="end">
                        <TranslateSelect />
                        {userLoggedIn && <SoftBox ml={2}>
                            <SoftAvatar
                                src={userProfileImage}
                                alt="profile-image"
                                variant="rounded"
                                size="sm"
                                shadow="sm"
                                onClick={handleOpenMenu}
                            />
                            {renderMenu()}
                        </SoftBox>
                        }
                    </Grid>
                </Grid>
            </MediaQuery>
        </SoftBox>
    )
}

export default HeaderMenu;

