/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import FormField from "components/common/FormField";


// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import runnerImg from "assets/images/auth-images/runner.jpg";
import RoutesNames from "helpers/enums/routes-map.enum";

// Signup schemas for form and form feilds
import validations from "layouts/authentication/sign-up/basic/schemas/validation";
import form from "layouts/authentication/sign-up/basic/schemas/form";
import initialValues from "layouts/authentication/sign-up/basic/schemas/initialValues";

// Helpers
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest, GetErrorMessage } from "helpers/back-request/helpers"
import SoftAlert from "components/SoftAlert";


function Basic() {
  const { formId, formField } = form;

  const successMessageRef = useRef();
  const [isFinished, setIsFinished] = useState(false);
  const [agreement, setAgreement] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);


  const handleSetAgreement = () => setAgreement(!agreement);

  const handleSubmit = async (values, actions) => {
    try {
      await CreatePostRequest(BackRequests.SIGN_UP, 
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
        });
        setIsFinished(true)
    } catch (error) {
      openErrorSB(GetErrorMessage(error));
    }
  };

  useEffect(() => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'end'
        })
    }
  },
  [isFinished])

  return (
    <BasicLayout
      title="Welcome!"
      description="Use this form to create new MoveOn account for free."
      image={runnerImg}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Register
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox mb={2}>
          <Socials/>
        </SoftBox> */}
        {/* <Separator /> */}
        <SoftBox pt={2} pb={3} px={3}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox>
                  <SoftBox mb={2}>
                    <FormField
                      type={formField.firstName.type}
                      label={formField.firstName.label}
                      name={formField.firstName.name}
                      value={values.firstName}
                      placeholder={formField.firstName.placeholder}
                      error={errors.firstName && touched.firstName}
                      disabled={isFinished}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <FormField
                      type={formField.lastName.type}
                      label={formField.lastName.label}
                      name={formField.lastName.name}
                      value={values.lastName}
                      placeholder={formField.lastName.placeholder}
                      error={errors.lastName && touched.lastName}
                      disabled={isFinished}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <FormField
                      type={formField.email.type}
                      label={formField.email.label}
                      name={formField.email.name}
                      value={values.email}
                      placeholder={formField.email.placeholder}
                      error={errors.email && touched.email}
                      disabled={isFinished}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <FormField
                      type={formField.password.type}
                      label={formField.password.label}
                      name={formField.password.name}
                      value={values.password}
                      placeholder={formField.password.placeholder}
                      error={errors.password && touched.password}
                      disabled={isFinished}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <FormField
                      type={formField.repeatPassword.type}
                      label={formField.repeatPassword.label}
                      name={formField.repeatPassword.name}
                      value={values.repeatPassword}
                      placeholder={formField.repeatPassword.placeholder}
                      error={errors.repeatPassword && touched.repeatPassword}
                      disabled={isFinished}
                    />
                  </SoftBox>
                  <SoftBox display="flex" alignItems="center">
                    <Checkbox
                      checked={agreement}
                      onChange={handleSetAgreement}
                      disabled={isFinished}
                    />
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={handleSetAgreement}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                      &nbsp;&nbsp;I agree the&nbsp;
                    </SoftTypography>
                    <SoftTypography
                      component="a"
                      href="#"
                      variant="button"
                      fontWeight="bold"
                      textGradient
                    >
                      Terms and Conditions
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={4} mb={1}>
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      type="submit"
                      disabled={isFinished}
                    >
                      sign up
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mt={3} textAlign="center">
                    <SoftTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      Already have an account?&nbsp;
                      <SoftTypography
                        component={Link}
                        to={RoutesNames.SIGN_IN}
                        variant="button"
                        color="dark"
                        fontWeight="bold"
                        textGradient
                      >
                        Sign in
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Form>
            )}
          </Formik>
          {isFinished && <SoftBox>
            <SoftAlert color="success">
              <SoftTypography variant="body2" color="white">
                You have successfully created an account. Please activate your profile by clicking the link in the email
                we sent. This will confirm your email and allow you to access MoveOn platform.
              </SoftTypography>
            </SoftAlert>
            <SoftBox ref={successMessageRef}></SoftBox>
          </SoftBox>
         }
        </SoftBox>
      </Card>
      { ErrorMessageSnackBar({title: "Sign up error", errorMessage, errorSB, closeErrorSB}) }
    </BasicLayout>
  );
}

export default Basic;
