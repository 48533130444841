/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { dataTableData, populateTableRows } from "layouts/pages/admin/users/list-users/data/dataTableData";

// Helpers
import BackRequests from "helpers/back-request/back-requests.enum";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { CreateGetRequest } from "helpers/back-request/helpers";
import UserStatus from "helpers/enums/user-status.enum";
import RoutesNames from "helpers/enums/routes-map.enum";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDisplayUserStatus } from "helpers/enums/user-status.enum";

function UserDataTable({status}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState(dataTableData);

  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);

  const getUsersData = async () => {
    try {
      // Get users data from database
      const response = await CreateGetRequest(BackRequests.GET_USERS_BY_STATUS + status);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      openErrorSB(GetErrorMessage(error), true);
    }
  };

  const openUserSettings = async (userId) => {
    navigate(RoutesNames.USER_SETTINGS, { state: { userId } });
  }

  useEffect(() => {
    (async () => {
      const usersData = await getUsersData();
      if (usersData) {
        setUsers(populateTableRows(usersData.users, openUserSettings, t))
      }
    })();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <SoftBox pt={6} pb={3}>
        <Card>
          <SoftBox p={3} lineHeight={1}>
            <SoftTypography variant="h5" fontWeight="medium">
              {t('user.list.title') + " " + getDisplayUserStatus(status, t)}
            </SoftTypography>
          </SoftBox>
          <DataTable table={users} 
           entriesPerPage={{
            defaultValue: 5,
            entries: [5, 7, 10, 15, 20, 25],
          }}
          pagination={{
            variant: "contained",
            color: "primary"
          }}
          canSearch />
        </Card>
        { ErrorMessageSnackBar({title: "Error", errorMessage, errorSB, closeErrorSB}) }
      </SoftBox>
      <Footer/>
    </DashboardLayout>
  );
}

export default UserDataTable;
