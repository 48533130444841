/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import "react-multi-carousel/lib/styles.css";

import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DiscountIcon from '@mui/icons-material/Discount';
import ExploreIcon from '@mui/icons-material/Explore';
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import MediaQuery from "react-responsive";


function InfoContent() {
    const { t, i18n } = useTranslation();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 2048 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 2048, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <SoftBox>
            <MediaQuery minWidth={431}>
                <SoftBox position="relative"
                    zIndex={0} mt={9}
                    px={{ xs: 1, sm: 0 }}
                    width="100%"
                    align="justify">
                    <SoftBox mt={2} px={15}>
                        <SoftBox mb={3}>
                            <Grid container spacing={10} >
                                <Grid item xs={12} sm={6} lg={3} align="center">
                                    <SportsBasketballIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5" mb={1}>{t('home-info.one_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.one_content')}</SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3} align="center">
                                    <ExploreIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5" mb={1}>{t('home-info.two_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.two_content')}</SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3} align="center">
                                    <DiscountIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5" mb={1}>{t('home-info.three_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.three_content')}</SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3} align="center">
                                    <CalendarMonthIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5" mb={1}>{t('home-info.four_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.four_content')}</SoftTypography>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </MediaQuery>
            <MediaQuery maxWidth={430}>
                <SoftBox position="relative"
                    zIndex={0} mt={4}
                    px={{ xs: 1, sm: 0 }}
                    width="100%"
                    align="justify">
                    <SoftBox px={1.5}>
                        <Carousel responsive={responsive} infinite={true} autoPlay={false} autoPlaySpeed={3000}>
                            <SoftBox align="center">
                                <SoftBox width="75%">
                                    <SportsBasketballIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5">{t('home-info.one_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.one_content')}</SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox align="center">
                                <SoftBox width="75%">
                                    <ExploreIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5">{t('home-info.two_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.two_content')}</SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox align="center">
                                <SoftBox width="75%">
                                    <DiscountIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5">{t('home-info.three_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.three_content')}</SoftTypography>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox align="center">
                                <SoftBox width="75%">
                                    <CalendarMonthIcon fontSize="large" color="secondary" />
                                    <SoftTypography variant="h5">{t('home-info.four_header')}</SoftTypography>
                                    <SoftTypography variant="body2">{t('home-info.four_content')}</SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Carousel>
                    </SoftBox>
                </SoftBox>
            </MediaQuery>
        </SoftBox>
    );
}

// Typechecking props for the PricingCards

export default InfoContent;
