/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// react-router components
import {Link, useLocation, useNavigate} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarMobileMenu,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useSoftUIController,} from "context";

// Images
import RoutesNames from "helpers/enums/routes-map.enum";
import { clearUserDataFromLocalStorage } from "helpers/general/helpers";
import SoftButton from "components/SoftButton";
import TranslateSelect from "components/common/TranslateSelect";

function DashboardNavbar({absolute, light, isMini}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleSignout = () => {
    clearUserDataFromLocalStorage();
    navigate(RoutesNames.HOME);
    setUserLoggedIn(false);
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, {transparentNavbar, absolute, light})}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/> */}
          {miniSidenav ? (
            <MenuOpenIcon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}></MenuOpenIcon>
          ) : (
            <MenuIcon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}></MenuIcon>
          )}
          {/* <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon> */}
        </SoftBox>
        <SoftBox display="flex" alignItems="end">
          <SoftBox mr={2}>
            <TranslateSelect />
          </SoftBox>
          {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, {isMini})}>
            <SoftBox color={light ? "white" : "inherit"}>
             <SoftButton
                component={Link}
                to={RoutesNames.SIGN_IN}
                color="primary"
                size="small"
                onClick={handleSignout}
              >
                Sign out
              </SoftButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </SoftBox>
          </SoftBox>
        )}
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
