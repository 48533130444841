/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "components/common/FormField";
import { useTranslation } from "react-i18next";

// Form schemas
import { formFields } from "layouts/pages/business/edit-business/schemas/form";

function ContactDataEdit({ values, errors, touched }) {
    const { t, i18n } = useTranslation();

    return (
        <SoftBox p={3} display="grid">
            <SoftTypography variant="h5">
            {t('businessItem.editPage.contactData')}
            </SoftTypography>
            <SoftBox mt={1.625}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.website.type}
                            label={formFields.website.label}
                            name={formFields.website.name}
                            value={values.website}
                            placeholder={formFields.website.placeholder}
                            error={errors.website && touched.website}
                            success={values.website.length > 0 && !errors.website}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.email.type}
                            label={formFields.email.label}
                            name={formFields.email.name}
                            value={values.email}
                            placeholder={formFields.email.placeholder}
                            error={errors.email && touched.email}
                            success={values.email.length > 0 && !errors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.contactPhone.type}
                            label={formFields.contactPhone.label}
                            name={formFields.contactPhone.name}
                            value={values.contactPhone}
                            placeholder={formFields.contactPhone.placeholder}
                            error={errors.contactPhone && touched.contactPhone}
                            success={values.contactPhone.length > 0 && !errors.contactPhone}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.instagramAccount.type}
                            label={formFields.instagramAccount.label}
                            name={formFields.instagramAccount.name}
                            value={values.instagramAccount}
                            placeholder={formFields.instagramAccount.placeholder}
                            error={errors.instagramAccount && touched.instagramAccount}
                            success={values.instagramAccount.length > 0 && !errors.instagramAccount}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.facebookAccount.type}
                            label={formFields.facebookAccount.label}
                            name={formFields.facebookAccount.name}
                            value={values.facebookAccount}
                            placeholder={formFields.facebookAccount.placeholder}
                            error={errors.facebookAccount && touched.facebookAccount}
                            success={values.facebookAccount.length > 0 && !errors.facebookAccount}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.twitterAccount.type}
                            label={formFields.twitterAccount.label}
                            name={formFields.twitterAccount.name}
                            value={values.twitterAccount}
                            placeholder={formFields.twitterAccount.placeholder}
                            error={errors.twitterAccount && touched.twitterAccount}
                            success={values.twitterAccount.length > 0 && !errors.twitterAccount}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={formFields.tiktokAccount.type}
                            label={formFields.tiktokAccount.label}
                            name={formFields.tiktokAccount.name}
                            value={values.tiktokAccount}
                            placeholder={formFields.tiktokAccount.placeholder}
                            error={errors.tiktokAccount && touched.tiktokAccount}
                            success={values.tiktokAccount.length > 0 && !errors.tiktokAccount}
                        />
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

export default ContactDataEdit;
