/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Images
import RoutesNames from "helpers/enums/routes-map.enum";
import { Link } from "react-router-dom";
import { getRoleBadge } from "../../../list-users/data/dataTableData";
import { useTranslation } from "react-i18next";

function Header({ id, firstName, lastName, image, role }) {
  const { t, i18n } = useTranslation();

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={1} alignItems="center" justifyContent="center" direction="column">
          <Grid item>
            <SoftAvatar
              src={image}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {firstName} {lastName}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="body2" textTransform="capitalize" ml={1}>
                  {getRoleBadge(role, t) ?? "/"}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Header;
