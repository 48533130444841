/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {formFields, getGenderOption} from "layouts/pages/client/profile/schemas/form";

const {
  firstName,
  lastName,
  email,
  age,
  gender,
  phoneNumber,
  currentPassword,
  newPassword,
  repeatPassword,
  profileImage
} = formFields;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [age.name]: 20,
  [gender.name]: "",
  [phoneNumber.name]: "",
  [currentPassword.name]: "",
  [newPassword.name]: "",
  [repeatPassword.name]: "",
  [profileImage.name]: null,
  status: "/",
  dateOfRegistration: "/",
  dateOfLastAction: "/",

};

const populateInitialValues = async (initData, profileImageFile) => {
  return {
    [firstName.name]: initData.firstName ?? "",
    [lastName.name]: initData.lastName ?? "",
    [email.name]: initData.email ?? "",
    [age.name]: initData.age ?? "",
    [gender.name]: getGenderOption(initData.gender) ?? "",
    [phoneNumber.name]: initData.phoneNumber ?? "",
    [profileImage.name]: profileImageFile ?? null,
    status: initData.accountData?.status ?? "/",
    dateOfRegistration: initData.accountData?.dateOfRegistration ?? "/",
    dateOfLastAction: initData.accountData?.dateOfLastAction ?? "/",
  }
};

export {
  initialValues,
  populateInitialValues
};
