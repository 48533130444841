/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { formFields } from "./form";


const {
  goodMessage,
  badMessage,
  rating,
} = formFields;

const initialValues = {
  [goodMessage.name]: "",
  [badMessage.name]: "",
  [rating.name]: 0,
};

export {
  initialValues,
};
