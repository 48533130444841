/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

 // Soft UI Dashboard PRO React components
 import SoftBox from "components/SoftBox";
 
 // Soft UI Dashboard PRO React example components
 import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
 import DashboardNavbar from "examples/Navbars/DashboardNavbar";
 import Footer from "examples/Footer";
 
 // Helpers
 import BackRequests from "helpers/back-request/back-requests.enum";
import BusinessDataTable from "../business-table";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
 function ActiveBusinessDataTable() {
  const params = useParams()
  const [requestName, setRequestName] = useState();


  useEffect(() => {
      console.log(params)
    if (params?.type) {
      setRequestName(BackRequests.GET_ACTIVE_BUSINESSES_BY_TYPE + params.type)
    } else {
      setRequestName(BackRequests.GET_ACTIVE_BUSINESSES)
    }  
  }, []);
  
   return (
     <DashboardLayout>
       <DashboardNavbar/>
       <SoftBox pt={6} pb={3}>
         {requestName && (
           <BusinessDataTable title="Active Businesses" request={requestName}/>
          )}
       </SoftBox>
       <Footer/>
     </DashboardLayout>
   );
 }
 
 export default ActiveBusinessDataTable;
 