/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable";
import loader from "assets/images/moveon/loader-simple.svg";

// Data
import { dataTableData, populateTableRows } from "layouts/pages/admin/businesses/business-table/dataTableData";

// Helpers
import { GetErrorMessage } from "helpers/back-request/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { CreateGetRequest } from "helpers/back-request/helpers";
import { capitalizeFirstLetter } from "helpers/general/helpers";
import { useNavigate } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import ApproveBusinessConfirmDialog from "components/ConfirmDialog/approve-business-confirm-dialog";
import RejectBusinessConfirmDialog from "components/ConfirmDialog/reject-business-confirm-dialog";

function BusinessDataTable({ title, request }) {
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState(dataTableData);
  const [confirmApproveDialogOpened, setConfirmApproveDialogOpened] = useState(false);
  const [confirmRejectionDialogOpened, setConfirmRejectionDialogOpened] = useState(false);
  const [businessId, setBusinessId] = useState();
  const [businessName, setBusinessName] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);

  const seeBusinessProfile = async (businessItemId) => {
    navigate(RoutesNames.EVENT, { state: { id: businessItemId, isAdmin: true } });
  }
  const onEditBusiness = async (businessItemId) => {
    navigate(RoutesNames.EDIT_BUSINESS, { state: { businessItemId } });
  }
  const openBusinessSettings = async (businessItemId) => {
    navigate(RoutesNames.BUSINESS_SETTINGS, { state: { businessItemId } });
  }

  const getBusinessesData = async () => {
    try {
      // Get businesses data from database
      const response = await CreateGetRequest(request);
      const businessesData = response.data.data;
      if (businessesData) {
        setBusinesses(populateTableRows(businessesData, onEditBusiness, seeBusinessProfile, openBusinessSettings,
          setConfirmApproveDialogOpened, setConfirmRejectionDialogOpened, setBusinessName, setBusinessId))
      }
    } catch (error) {
      console.log("error")
      console.log(error)
      console.log(GetErrorMessage(error))
      openErrorSB(GetErrorMessage(error), true);
    }
  };

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      await getBusinessesData();
      setShowLoader(false);
    })();
  }, []);

  return (
    <SoftBox>
      <Card>
        {!showLoader && (
          <SoftBox>
            <SoftBox p={3} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {capitalizeFirstLetter(title)}
              </SoftTypography>
            </SoftBox>
            <DataTable table={businesses}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              pagination={{
                variant: "contained",
                color: "primary"
              }}
              canSearch />
            {confirmApproveDialogOpened &&
              (<ApproveBusinessConfirmDialog
                businessId={businessId} businessName={businessName}
                handleClose={() => setConfirmApproveDialogOpened(false)}
                openSuccessSB={openSuccessSB}
                openErrorSB={openErrorSB}
                getBusinessesData={getBusinessesData}
              />)
            }
            {confirmRejectionDialogOpened &&
              (<RejectBusinessConfirmDialog
                businessId={businessId} businessName={businessName}
                handleClose={() => setConfirmRejectionDialogOpened(false)}
                openSuccessSB={openSuccessSB}
                openErrorSB={openErrorSB}
                getBusinessesData={getBusinessesData}
              />)
            }
          </SoftBox>
        )}
        {showLoader &&
          <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
            <img src={loader} alt="Loading..." className="loader" />
          </SoftBox>}
      </Card>

      {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
      {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
    </SoftBox>
  );
}

export default BusinessDataTable;
