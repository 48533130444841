/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// RTL plugins
// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import {setMiniSidenav, setOpenConfigurator, useSoftUIController} from "context";
import SettingsIcon from '@mui/icons-material/Settings';

// Images
import brand from "assets/images/moveon/move_on_logo_orange_with_text.png";
import RoutesNames from "helpers/enums/routes-map.enum";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav, direction, layout, openConfigurator, sidenavColor} = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const {pathname} = useLocation();
  const [user, setUser] = useState(null);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // TODO
  // Check is there logged in user
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      // TODO: Check this setUser
      setUser(foundUser);
    }
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key}/>;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{cursor: "pointer"}}
      onClick={handleConfiguratorOpen}
    >
      <SettingsIcon fontSize="default" color="inherit">
        settings
      </SettingsIcon>
    </SoftBox>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator/> */}
          {/* {configsButton} */}
        </>
      )}
      {layout === "page" && <Configurator/>}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to={RoutesNames.HOME}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.SIGN_IN}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.CLIENT_PROFILE}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.SIGNUP}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.NEW_BUSINESS}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.EDIT_BUSINESS}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.ADMIN_CRM}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.CLIENT_DASHBOARD}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.CLIENT_RESERVATIONS}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.BUSINESS_DASHBOARD}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.BUSINESS_RESERVATIONS}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.BUSINESS_PROFILE}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.ACCOUNT_ACTIVATION}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.PASSWORD_RESET_INIT}/>}/>
        <Route path="*" element={<Navigate to={RoutesNames.PASSWORD_RESET_CONFIRM}/>}/>
      </Routes>
    </ThemeProvider>
  );
}
