/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {formFields} from "layouts/pages/client/profile/schemas/form";

const {
  newPassword, repeatPassword
} = formFields;

const initialValues = {
  [newPassword.name]: "",
  [repeatPassword.name]: "",
};

export {
  initialValues,
};
