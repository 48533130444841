// [CHANGE CURRENCY TO LIST] Expand the list
// const countryOptions = getData().map((country)=> {return {value: country.code, label: country.name}})
const countryOptions = [
    { value: "RS", label: "Serbia" },
];

// [CHANGE COUNTRY TO LIST] remove this
function getCountryValue(label) {
    return countryOptions.find((country) => country.label == label).value
};

function getCountryLabel(value) {
    return countryOptions.find((country) => country.value == value).label
};

function calculateDiscountedPrice(price, discount) {
    const discountedPrice = price - (price * discount) / 100;
    return discountedPrice >= 0 ? Math.round(discountedPrice) : 0;
}


// Note: value is singular and label is plural because we need to match value with gender from user model
const genderOptions = [
    { value: "all", label: "All" },
    { value: "man", label: "Men", selected: true },
    { value: "woman", label: "Women" },
];

const dayOptions = [
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
];

function getDayOptionByValue(value) {
    return dayOptions.find((day) => day.value == value)
};

export {
    countryOptions,
    getCountryValue,
    getCountryLabel,
    calculateDiscountedPrice,
    genderOptions,
    dayOptions,
    getDayOptionByValue
}