/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";

const businessTypeCategoriesListData = [
  {
    color: "dark",
    icon: "event",
    name: "Events",
    description: (
      <>
        250 approved,{" "}
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          12 waiting
        </SoftTypography>
      </>
    ),
    route: "/admin/businesses-table/event",
  },
  {
    color: "dark",
    icon: "rowing",
    name: "Group activities",
    description: (
      <>
        110 approved,{" "}
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          15 waiting
        </SoftTypography>
      </>
    ),
    route: "/admin/businesses-table/group-activity",
  },
  {
    color: "dark",
    icon: "fitness-center",
    name: "Sport facilities",
    description: (
      <>
        247 approved,{" "}
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          10 waiting
        </SoftTypography>
      </>
    ),
    route: "/admin/businesses-table/sport-facility",
  },
  {
    color: "dark",
    icon: "coach",
    name: "Personal coaches",
    description: (
      <>
        75 approved,{" "}
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          3 waiting
        </SoftTypography>
      </>
    ),
    route: "/admin/businesses-table/personal-coach",
  },
];

const populateBusinessTypesCategoriesListData = (statisticsData) => {
  let populatedData = businessTypeCategoriesListData;
  populatedData[0].description = (
    <>
      {statisticsData.eventNum.active} approved,{" "}
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {statisticsData.eventNum.pending} waiting
      </SoftTypography>
    </>
  );

  populatedData[1].description = (
    <>
      {statisticsData.groupActivitiesNum.active} approved,{" "}
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {statisticsData.groupActivitiesNum.pending} waiting
      </SoftTypography>
    </>
  );

  populatedData[2].description = (
    <>
      {statisticsData.sportFacilitiesNum.active} approved,{" "}
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {statisticsData.sportFacilitiesNum.pending} waiting
      </SoftTypography>
    </>
  );

  populatedData[3].description = (
    <>
      {statisticsData.personalCoachesNum.active} approved,{" "}
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {statisticsData.personalCoachesNum.pending} waiting
      </SoftTypography>
    </>
  );
  return populatedData;
};

export { 
  businessTypeCategoriesListData,
  populateBusinessTypesCategoriesListData
};