/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";
import PieChart from "examples/Charts/PieChart";
import { useEffect, useState } from "react";

// Data

function ReservationsChart({reservationsByBusiness}) {
  const [businessesData, setBusinessesData] = useState({});

  useEffect(() => {
    (async () => {
      const chartData = {
        labels: reservationsByBusiness?.map((business)=> business.name),
        datasets: {
          label: "Projects",
          backgroundColors: ["primary", "secondary", "dark", "success", "info", "error"],
          // data: reservationsByBusiness?.map((business)=> business.reservations.length),
          data: [4,7,2,14]
        },
      };
      setBusinessesData(chartData);
    })();
}, []);

return (
  <Card sx={{ overflow: "visible" }}>
    <SoftBox pb={2}>
      <Grid container alignItems="center">
        <Grid item xs={7}>
          <PieChart chart={businessesData} height="100%" />
        </Grid>
        <Grid item xs={5}>
          <SoftBox px={1}>
            {reservationsByBusiness?.map((businessItem, key) => (
              <SoftBox mb={0.5} key={key}>
                <SoftBadgeDot color={businessesData.datasets.backgroundColors[key]} size="sm" badgeContent={businessItem.name} />
              </SoftBox>
            ))
            }
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  </Card>
);
}

export default ReservationsChart;
