/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";

// Form schemas
import { validations } from "layouts/pages/business/new-business/schemas/validations";
import { fields } from "layouts/pages/business/new-business/schemas/form";
import { initial } from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import ImageEdit from "components/common/ImageEdit";
import { useState } from "react";


function Media({ activeStep, isLastStep, handleNext, handleBack }) {
  const [showMainImageError, setShowMainImageError] = useState(false);
  // Form data
  const formValidation = validations[FormNames.MEDIA_FORM];
  const formFields = fields[FormNames.MEDIA_FORM];
  const initialValues = initial[FormNames.MEDIA_FORM];

  const handleSubmit = async (values, actions) => {
    try {
      console.log("MEDIA HANDLE SUBMIT")
      console.log(values)

      if (!values.mainImage) {
        setShowMainImageError(true);
        return;
      }

      handleNext({
        mediaPayload: {
          mainImage: values.mainImage,
          coverImage: values.coverImage,
          gallery: values.galleryImages,
        }
      })
    } catch (error) {
      // openErrorSB(GetErrorMessage(error));
    }
  };

  const onUpload = (fileId, fieldName, values, errors) => {
    if (fieldName == formFields.galleryImages.name) {
      values[fieldName].push(fileId)
    } else {
      values[fieldName] = fileId;
    }
    delete errors[fieldName]
  }

  const selectImageAction = (image, type, values) => {
    if (type == "main") {
      values[formFields.mainImage.name] = image;
      setShowMainImageError(false);
    }
    if (type == "cover") {
      values[formFields.coverImage.name] = image;
    }
    if (type == "gallery") {
      console.log(image)
      console.log(values[formFields.galleryImages.name]);
      if (!values[formFields.galleryImages.name]) {
        values[formFields.galleryImages.name] = [];
      }
      values[formFields.galleryImages.name].push(image);
    }
  }

  const removeSelectedImageAction = (image, type, values) => {
    console.log(values[formFields.galleryImages.name])
    if (type == "gallery") {
      const index = values[formFields.galleryImages.name].findIndex((item) =>
        item.name == image.name &&
        item.size == image.size &&
        item.lastModified == image.lastModified);

        values[formFields.galleryImages.name].splice(index, 1);
        console.log(values[formFields.galleryImages.name])
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
        <Form id={FormNames.MEDIA_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5">Media</SoftTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mt={3}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Main image *
                    </SoftTypography>
                  </SoftBox>
                  <ImageEdit type="main" initialImage={initialValues.mainImage} selectImageAction={selectImageAction} values={values} showMainImageError={showMainImageError} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mt={3}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cover image
                    </SoftTypography>
                  </SoftBox>
                  <ImageEdit type="cover" initialImage={initialValues.coverImage} selectImageAction={selectImageAction} values={values} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <SoftBox mt={1}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Gallery images
                    </SoftTypography>
                  </SoftBox>
                  <SoftDropzone
                    options={{ addRemoveLinks: true, autoProcessQueue: true, maxFiles: 7 }}
                    onUpload={onUpload}
                    fieldName={formFields.galleryImages.name}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    selectImageAction={selectImageAction}
                    initialImages={initialValues.gallery}
                    removeSelectedImageAction={removeSelectedImageAction}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} />
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default Media;
