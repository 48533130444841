/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import { formFields } from "./form";

const {
  goodMessage, badMessage, rating
} = formFields;

const validations = Yup.object().shape({
  [goodMessage.name]: Yup.string(),
  [badMessage.name]: Yup.string(),
  [rating.name]: Yup.number().min(1, rating.errorMsgForMin).typeError(rating.errorMsgForMin)
});

export {
  validations,
};

