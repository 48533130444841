/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useRef, useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Footer from "layouts/pages/home/components/Footer";
import Grid from "@mui/material/Grid";
import FiltersForm from "layouts/pages/home/components/Filters";
import PublicHeader from "./components/PublicHeader";

// Helpers
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { Container } from "@mui/material";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import SearchResultCard from "./components/SearchResultCard";
import loader from "assets/images/moveon/loader.gif";
import { CreatePostRequest } from "helpers/back-request/helpers";
import Pagination from '@mui/material/Pagination';
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { BusinessItemOrderOptions } from "helpers/enums/business-item.enum";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

function BusinessSearch({type}) {
  // const type = BusinessItemTypes.PERSONAL_COACH;
  const [dataToShow, setDataToShow] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(7);
  const [orderOption, setOrderOption] = useState({});
  const [hasResult, setHasResult] = useState(false);
  const myRef = useRef(null)

  const perPage = 10;
  const orderOptions = [
    { value: BusinessItemOrderOptions.SCORE_HIGH_TO_LOW, label: "Review score high to low", default: true },
    { value: BusinessItemOrderOptions.SCORE_LOW_TO_HIGH, label: "Review score low to high" },
  ]

  const executeScroll = () => myRef.current.scrollIntoView();

  const handleSearch = async (serachParamsValues) => {
    executeScroll();
    setSearchParams(serachParamsValues);
    setShowLoader(true);
    const data = await searchData(1, serachParamsValues);
    if (data) {
      setResultData(data);
    }
  };

  const handlePageChange = async (event, value) => {
    console.log("HANDLE PAGE CHANGE")
    console.log(value)
    executeScroll();
    setPage(value);
    setShowLoader(true);
    const data = await searchData(value);
    if (data) {
      setResultData(data);
    }
  };

  const handleOrderOptionChange = async (event, value) => {
    setOrderOption(event);
    setShowLoader(true);
    const data = await searchData(1, undefined, event);
    if (data) {
      setResultData(data);
    }
  };

  const setResultData = (searchResult) => {
    setDataToShow(searchResult.businesses);
    if (searchResult.businesses.length > 0) {
      setMaxPages(Math.ceil(searchResult.total / perPage));
      setHasResult(true);
    } else {
      setHasResult(false);
    }
    setShowLoader(false);
  }

  const searchData = async (pageNo, searchParamsData, orderOptionData) => {
    console.log("searchData")
    console.log(searchParamsData)
    try {
      const response = await CreatePostRequest(BackRequests.SEARCH_BUSINESS_ITEMS, {
        category: type,
        page: pageNo,
        perPage,
        ...(searchParamsData ? searchParamsData : searchParams),
        ...(orderOptionData ? { orderOption: orderOptionData.value } : { orderOption: orderOption?.value })
      });

      if (response?.data?.data?.businesses) {
        // Get media
        for (let i = 0; i < response.data.data.businesses.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + response.data.data.businesses[i].mainImage, { responseType: 'blob' });
          response.data.data.businesses[i].mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
      }
      console.log(response.data.data)
      return response.data.data;
    } catch (error) {
      /*console.log("error")
      console.log(error)
      // TODO: Sta na stranici prikazati ako se ne ucitaju podaci
      openErrorSB(setErrorMessage(error), true);*/
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setShowLoader(true);
      const data = await searchData(1);
      if (data) {
        setResultData(data)
      }
    }
    loadData();
  }, []);

  return (
    <PageLayout>
      <SoftBox align="center">
        <PublicHeader shouldShowSearchBox={false} type={type}/>
      </SoftBox>
      <Container>
        <SoftBox p={2} flex="1" display="flex">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={2.5} >
              <FiltersForm handleSearch={handleSearch} type={type}/>
            </Grid>
            <Grid item xs={12} md={9} lg={9.5} >
              <SoftBox>
                {hasResult && (
                  <Grid container spacing={2}>
                    <Grid item lg={12} ref={myRef}>
                      <SoftBox display="flex" justifyContent="end">
                        <SoftBox ml={0.5} lineHeight={0} display="flex" alignItems="center" mr={1} mb={1.5}>
                          <SwapVertIcon color="secondary" />
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Sort by
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          size="small"
                          defaultValue={orderOptions.find((option) => option.default == true)}
                          options={orderOptions}
                          onChange={handleOrderOptionChange}
                          sx={{ marginBottom: 0 }}
                        />
                      </SoftBox>
                    </Grid>
                    {dataToShow.map((item, index) =>
                      <Grid item key={index} xs={12} md={6} lg={6} >
                        <SoftBox height="100%">
                          <SearchResultCard
                            image={item.mainImageUrl}
                            title={item.name}
                            type={item.type}
                            addresses={item.addresses}
                            sports={item.sports}//{item.sports?.sort((a, b) => a.key > b.key ? 1 : -1).map((items, i) => { return <span><SoftBadge style={{ margin: '0 0 0 5px' }} key={items.id} variant="contained" color="info" badgeContent={items.value} /></span>})}
                            rating={item.rating}
                            ratingCount={item.ratingCount}
                            maxDiscount={item.maxDiscount}
                            eventId={item.id}
                            isSponsored={item.isSponsored}
                          />
                        </SoftBox>
                      </Grid>
                    )}
                    <Grid item xs={12} md={12} lg={12}>
                      <SoftBox display="flex" justifyContent="center" width="100%">
                        <Pagination count={maxPages} page={page} onChange={handlePageChange} color="primary" />
                      </SoftBox>
                    </Grid>
                  </Grid>
                )}
                {!hasResult && !showLoader && (
                  <SoftBox textAlign="center" mt={3}>
                    <ReportProblemIcon color="secondary" fontSize="large" />
                    <SoftTypography variant="h6">There is no result for the given parameters</SoftTypography>
                  </SoftBox>
                )}
                {/* {!hasResult && showLoader &&
                  <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
                    <img src={loader} alt="Loading..." className="loader" />
                  </SoftBox>} */}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
      <SoftBox mt={8}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default BusinessSearch;
