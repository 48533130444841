/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

function ImageCropper({ image, onCropDone, onCropCancel, title, aspectRatio }) {
    const { t, i18n } = useTranslation();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        // Store the cropped area in pixels
        setCroppedArea(croppedAreaPixels);
    }

    const cropperStyle ={
        position: "relative",
        width: "50vw",
        height: "70vh",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 10px 9px 0px rgb(0,0,0,0.09)"
    }
    
    if (!image) {
        return null;
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={true}
            sx={{transition: 'fade'}}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <SoftBox sx={cropperStyle}>
                        <SoftBox>
                            <Cropper
                                image={image}
                                aspect={aspectRatio}
                                crop={crop}
                                zoom={zoom}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                style={{
                                    containerStyle: {
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "#fff"
                                    }
                                }}
                            />
                        </SoftBox>
                    </SoftBox>
                </Box>
            </DialogContent>
            <DialogActions>
                <SoftButton color="primary" mr={2} onClick={onCropCancel}>
                    {t('uploadImage.cancel')}
                </SoftButton>
                <SoftButton color="secondary" onClick={() => {
                    onCropDone(croppedArea);
                }}>
                     {t('uploadImage.cropApply')}
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
}

export default ImageCropper;
