import SoftBox from "components/SoftBox";
import DefaultNavbarLink from "../DefaultNavbar/DefaultNavbarLink";
import { useTranslation } from "react-i18next";

function PublicDefaultNuvbarElements({ light }) {
    const { t, i18n } = useTranslation();
    return (
        <SoftBox display={{ xs: "none", lg: "flex" }} m={0} p={0} style={{fontSize:"40px"}}>
            <a href="/home">
                <DefaultNavbarLink
                    name={t('navbar.home')}
                    light={light}
                /></a>
            <a href="/group-activities">
                <DefaultNavbarLink
                    name={t('navbar.groupActivities')}
                    light={light}
                /></a>
            <a href="/personal-coaches">
                <DefaultNavbarLink
                    name={t('navbar.personalCoaches')}
                    light={light}
                /></a>
            <a href="/sport-facilities">
                <DefaultNavbarLink
                    name={t('navbar.sportFacilities')}
                    light={light}
                /></a>

            <a href="/event-activities">
                <DefaultNavbarLink
                    name={t('navbar.events')}
                    light={light}
                /></a>
        </SoftBox>
    );
}

export default PublicDefaultNuvbarElements;
