/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Helpers
import BackRequests from "helpers/back-request/back-requests.enum";
import BusinessDataTable from "../business-table";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from "react";
import { Card } from "@mui/material";
import { useTranslation } from "react-i18next";

function BusinessArchive() {
    const [value, setValue] = useState('deleted-businesses');
    const { t, i18n } = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={6} pb={3}>
                <Card>
                    <TabContext value={value} m={1}>
                        <Tabs onChange={handleChange} value={value} variant="scrollable" sx={{ margin: "16px", marginBottom: "-16px" }}>
                            <Tab label={t('admin.businesses.deleted')} icon={<DeleteIcon color="secondary" fontSize="small" />} value="deleted-businesses" />
                            <Tab label={t('admin.businesses.rejected')} icon={<HighlightOffIcon color="secondary" fontSize="small" />} value="rejected-business-requests" />
                        </Tabs>
                        <TabPanel value="deleted-businesses">
                            <BusinessDataTable title={t('admin.businesses.deleted')} request={BackRequests.GET_DELETED_BUSINESSES} />
                        </TabPanel>
                        <TabPanel value="rejected-business-requests">
                            <BusinessDataTable title={t('admin.businesses.rejected')} request={BackRequests.GET_REJECTED_BUSINESS_REQUESTS} />
                        </TabPanel>
                    </TabContext>
                </Card>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default BusinessArchive;
