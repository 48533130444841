/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { ReservationStatus } from "helpers/enums/reservation.enum";
import { formFields } from "layouts/pages/business/business-reservations/schemas/form";

const {
  reservationId, dateFrom, dateTo, name, lastname, email, businessName, reservationStatus
} = formFields;

const getInitialValues = (t) => {
  const reservationStatusInit = reservationStatus.options.find((item) => item.value == ReservationStatus.CREATED);
  return {
    [reservationId.name]: "",
    [dateFrom.name]: "",
    [dateTo.name]: "",
    [name.name]: "",
    [lastname.name]: "",
    [email.name]: "",
    [businessName.name]: "",
    [reservationStatus.name]: {...reservationStatusInit, label: t(reservationStatusInit.label)}
  }
}

export {
  getInitialValues,
};
