/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React helper functions
// import pxToRem from "assets/theme/functions/pxToRem";

const {size} = typography;
const {text} = colors;

const dialogContentText = {
  styleOverrides: {
    root: {
      fontSize: size.md,
      color: text.main,
    },
  },
};

export default dialogContentText;
