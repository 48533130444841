/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";
import { useState } from "react";
import { BusinessItemStatus } from "helpers/enums/business-item.enum";
import { getDisplayBusinessItemStatus } from "helpers/enums/business-item.enum";
import { getDisplayBusinessItemType } from "helpers/enums/business-item.enum";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import { CreatePostRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";

function BusinessItemCard({ id, image, name, status, type, showStatus, showActions, updateAfterDelete, isAdmin }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);


  const onEditBusiness = async () => {
    handleCloseMenu()
    navigate(RoutesNames.EDIT_BUSINESS, { state: { businessItemId: id } });
  }

  const onDeleteBusiness = async () => {
    handleCloseMenu();
    showDeleteAlert(
      t('businessItem.deleteConfirmDialog.title'),
      t('businessItem.deleteConfirmDialog.question'),
      t('businessItem.deleteConfirmDialog.successButton'),
      t('businessItem.deleteConfirmDialog.cancelButton'),
      t('businessItem.deleteConfirmDialog.successTitle'),
      t('businessItem.deleteConfirmDialog.successMsg') + name,
      t('businessItem.deleteConfirmDialog.errorTitle'),
      t('businessItem.deleteConfirmDialog.errorMsg'),
    );
  }

  const seeProfile = async () => {
    navigate(RoutesNames.EVENT, { state: { id } });
  }

  const showDeleteAlert = (title, question, confirmButtonText, cancelButtonText, successTitle, successMsg, errorTitle, errorMsg) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `<h5>${title}</h5>`,
        html: `<div>${question} <b> ${name} </b></div>`,
        showCancelButton: true,
        confirmButtonText:  confirmButtonText,
        cancelButtonText:  cancelButtonText,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await CreatePostRequest(BackRequests.DELETE_BUSINESS_ITEM,
              {
                  businessItemId: id,
                  userId: JSON.parse(localStorage.getItem("userId"))
              });
              newSwal.fire(successTitle, `<div>${successMsg} <b> ${name} </b></div>`, "success");
              updateAfterDelete(id);
          } catch (error) {
            console.log("ERRRRROROOR")
            console.log(error)
            newSwal.fire(errorTitle, `<div>${errorMsg} <b> ${name} </b></div>`, "error");
          }
        }
      });
  };

  // const renderMembers = authors.map(({ image: media, name }) => (
  //   <Tooltip key={name} title={name} placement="bottom">
  //     <SoftAvatar
  //       src={media}
  //       alt={name}
  //       size="xs"
  //       sx={({ borders: { borderWidth }, palette: { white } }) => ({
  //         border: `${borderWidth[2]} solid ${white.main}`,
  //         cursor: "pointer",
  //         position: "relative",
  //         ml: -1.25,

  //         "&:hover, &:focus": {
  //           zIndex: "10",
  //         },
  //       })}
  //     />
  //   </Tooltip>
  // ));

  // Dropdown menu from three dots
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={seeProfile}>See profile</MenuItem>
      {status == BusinessItemStatus.ACTIVE && <MenuItem onClick={onEditBusiness}>Edit</MenuItem>}
      {status == BusinessItemStatus.ACTIVE && <MenuItem onClick={onDeleteBusiness}>Delete</MenuItem>}
    </Menu>
  );

  return (

    <Card sx={{
      height: "100%",
      minWidth: "100%",
      boxShadow: "1.25rem 1.25rem 1.6875rem 1.25rem rgba(0, 0, 0, 0.05)"
    }} >
      <SoftBox p={2} sx={{ height: "100%" }} display="flex" flexDirection="column">
        <SoftBox display="flex" alignItems="center" justifyContent="flex-start" flexDirection="column">
          <SoftAvatar src={image} alt={name} size="xl" variant="rounded" 
            component={Link} to={RoutesNames.EVENT} state={{ id, isAdmin }}/>
          {renderMenu()}
          {showActions && 
          <SoftTypography
            color="text"
            onClick={handleOpenMenu}
            mr={1.5}
            sx={{ lineHeight: 0, cursor: "pointer", position: "absolute", right: 0 }}
          >
            <MoreVertIcon fontSize="default" />
          </SoftTypography>
          }
          <SoftBox lineHeight={0} mt={2} textAlign="center">
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize"  
              component={Link}
              to={RoutesNames.EVENT} state={{ id, isAdmin }}>
              {name}
            </SoftTypography>
            {type ? (
              <SoftBox>
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  {getDisplayBusinessItemType(type, t)}
                </SoftTypography>
              </SoftBox>
            ) : null}
          </SoftBox>
        </SoftBox>
        {showStatus &&
          <SoftBox mt={1}>
            <Divider />
            <SoftTypography variant="body2" color={
              status == BusinessItemStatus.ACTIVE ? "secondary" : 
              (status == BusinessItemStatus.REJECTED || status == BusinessItemStatus.DELETED) ? "error" : "primary"} textAlign="center">
              {getDisplayBusinessItemStatus(status, t)}
            </SoftTypography>
          </SoftBox>
        }
      </SoftBox>
    </Card >
  );
}

// Typechecking props for the BusinessItemCard
BusinessItemCard.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  type: PropTypes.string,
  showStatus: PropTypes.bool,
  showActions: PropTypes.bool
};

export default BusinessItemCard;
