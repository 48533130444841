import fields from "layouts/pages/home/schemas/form";
import FormNames from "layouts/pages/home/schemas/form-names.enum"

const {
    [FormNames.SEARCH]: {
        businessName, location, category, searchGender, searchAdditionals, searchSkillLevel, searchAgeCategory, searchSport, reviewScore

    },
    [FormNames.MAKE_RESERVATION]: {
        firstName,
        lastName,
        email,
        service,
    },
} = fields;


const initialClearData = {
    [FormNames.SEARCH]: {
        [businessName.name]: "",
        [location.name]: "",
        [category.name]: null,
        [searchGender.name]: searchGender.default,
        [searchAdditionals.name]: [],
        [searchSkillLevel.name]: [],
        [searchAgeCategory.name]: [],
        [searchSport.name]: [],
        [reviewScore.name]: 0
    },
    [FormNames.MAKE_RESERVATION]: {
        [firstName.name]: "",
        [lastName.name]: "",
        [email.name]: "",
        [service.name]: [],
    },
}


const initial = {
    ...initialClearData
}

const getInitialValuesForSearch = () => {
    return {
        [businessName.name]: "",
        [location.name]: "",
        [category.name]: null,
        [searchGender.name]: searchGender.default,
        [searchAdditionals.name]: [],
        [searchSkillLevel.name]: [],
        [searchAgeCategory.name]: [],
        [searchSport.name]: [],
        [reviewScore.name]: 0
    }
}

export {
    initial,
    getInitialValuesForSearch
};

