/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "components/SoftSelect/styles";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { ErrorMessage } from "formik";

const SoftSelect = forwardRef(({ size, error, success, name, ...rest }, ref) => {
  const { light } = colors;
  return (
    <SoftBox mb={1.5}>
      <Select
        {...rest}
        ref={ref}
        styles={styles(size, error, success)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
      {name && <SoftBox mt={0.75}>
          <SoftTypography component="div" variant="caption" color="error">
            <ErrorMessage name={name} />
          </SoftTypography>
      </SoftBox>}
      
    </SoftBox>
  );
});

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  name: PropTypes.string
};

export default SoftSelect;
