async function sleep(seconds) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

function clearUserDataFromLocalStorage() {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.clear();
}

function getUsetFirstNameLastName() {
    const userData = JSON.parse(localStorage.getItem("user"))
    if (!userData) return ""
    return userData.firstName + " " + userData.lastName;
}

function getPhoneNumberRegex() {
    return '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
}

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export {
    sleep,
    clearUserDataFromLocalStorage,
    getUsetFirstNameLastName,
    getPhoneNumberRegex,
    capitalizeFirstLetter,
}