/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SoftDropzone
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";
import { GetUploadImageUrl } from "helpers/back-request/helpers";
import SoftTypography from "components/SoftTypography";
import { ErrorMessage } from "formik";

function SoftDropzone({ options, onUpload, fieldName, values, errors, initialImages, selectImageAction, removeSelectedImageAction }) {
  const [populatedInitialImages, setPopulatedInitialImages] = useState(false);

  const dropzoneRef = useRef();

  useEffect(() => {
    async function setupData() {
      Dropzone.autoDiscover = false;
      function createDropzone() {
        return new Dropzone(dropzoneRef.current, {
          ...options,
          init: function () {
            this.on('success', function (file, response) {
              // onUpload(response.data.fileName, fieldName, values, errors);
            });
            this.on("addedfile", function (file) {
              console.log("addedfile")
              console.log(this.getQueuedFiles())
              if (this.getQueuedFiles().length > 0) {
                // If files are uploaded, hide instruction text
                document.querySelector('.dz-message').style.display = 'none';
              }
              console.log(file)
              selectImageAction(file, "gallery", values);
            });
            this.on("removedfile", function (file) {
              console.log("removedfile")
              if (this.getQueuedFiles().length > 0) {
                document.querySelector('.dz-message').style.display = 'none';
              }
              console.log("AHAAA")
              removeSelectedImageAction(file, "gallery", values);
            });
          },
        });
      }

      function removeDropzone() {
        if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
      }

      const myDropzone = createDropzone();
      if (initialImages && !populatedInitialImages) {
        console.log("ima inicijalnih slika")
        for (const img of initialImages) {
          const imgUrl = img instanceof File ? URL.createObjectURL(img) : img.imgUrl;
          const resizedImage = await resizeImage(imgUrl, 200, 200);
          const initialImageFile = { name: "initialImage", imgUrl: imgUrl }; // Create a mock file object
          myDropzone.emit("addedfile", initialImageFile); // Emit the addedfile event
          myDropzone.emit("thumbnail", initialImageFile, resizedImage); // Emit the thumbnail event
          myDropzone.emit("complete", initialImageFile); // Emit the complete event
        }
        setPopulatedInitialImages(true);
      }
      return () => removeDropzone();
    }
    setupData();
  }, []);

  async function dataURLToBlob(dataUrl) {
    // Convert data URL to ArrayBuffer
    const response = await fetch(dataUrl);
    const arrayBuffer = await response.arrayBuffer();

    // Convert ArrayBuffer to Blob
    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Adjust type if needed

    return blob;
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
      );
    });

  async function resizeImage(file, maxWidth, maxHeight) {
    return new Promise(async (resolve, reject) => {
      var reader = new FileReader();
      reader.onload = async function (event) {
        var image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          var canvas = document.createElement('canvas');
          var width = image.width;
          var height = image.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);
          var resizedDataUrl = canvas.toDataURL("image/jpeg");
          resolve(resizedDataUrl);
        };
      };

      const blobData = await dataURLToBlob(file);
      reader.readAsDataURL(blobData);
    });
  }

  return (
    <SoftBox mb={1.5}>
      <SoftDropzoneRoot
        action={GetUploadImageUrl()}
        ref={dropzoneRef}
        className="form-control dropzone"
      >
        <SoftBox className="fallback">
          <input
            id="icon"
            type="file"
            name="file"
            hidden
            multiple
          />
        </SoftBox>
      </SoftDropzoneRoot>
      {(fieldName && errors) &&
        <SoftBox mt={0.75}>
          <SoftTypography component="div" variant="caption" color="error">
            <ErrorMessage name={fieldName} />
          </SoftTypography>
        </SoftBox>}
    </SoftBox>
  );
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpload: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SoftDropzone;
