/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const formFields = {
  newPassword: {
    name: "newPassword",
    label: "new password",
    type: "password",
    placeholder: "New Password",
    errorMsg: "New password is required.",
    invalidMsg: "Your new password should be more than 6 characters.",
  },
  repeatPassword: {
    name: "repeatPassword",
    label: "confirm new password",
    type: "password",
    placeholder: "Confirm Password",
    errorMsg: "Confirm Password is required.",
    invalidMsg: "Your password doesn't match.",
  },
};

export {
  formFields
};
