/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import { Rating } from "@mui/material";

function RatingStars({ rating, size, precision }) {
    // small -> size = "small"
    // medium -> default, don't set size property
    // large -> size = "large"

    return (
        <Rating name="read-only" value={rating} readOnly size={size} precision={precision}/>
    )

}

// typechecking props for FormField
RatingStars.propTypes = {
    rating: PropTypes.number.isRequired,
    size: PropTypes.string,
    precision: PropTypes.number
};

export default RatingStars;

