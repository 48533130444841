import checkout from "layouts/authentication/sign-up/basic/schemas/form";
const {
    formField: {
        firstName,
        lastName,
        email,
        password,
        repeatPassword,
    },
} = checkout;

const initialValues = {
    [firstName.name]: "",
    [lastName.name]: "",
    [email.name]: "",
    [password.name]: "",
    [repeatPassword.name]: "",
};

export default initialValues;
