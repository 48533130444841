const ReservationStatus = {
    CREATED: "created",
    REALIZED: "realized",
    CANCELED: "canceled",
}

function getDisplayReservationStatus(status, t) {
    switch (status) {
        case ReservationStatus.CREATED:
            return t('reservation.status.created')
        case ReservationStatus.REALIZED:
            return t('businessItem.status.realized')
        case ReservationStatus.CANCELED:
            return t('businessItem.status.canceled')
    }
}

export {
    ReservationStatus,
    getDisplayReservationStatus,
};