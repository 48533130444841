/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import colors from "assets/theme/base/colors";
import { useTranslation } from "react-i18next";
import { getDisplayBusinessItemType } from "helpers/enums/business-item.enum";
import SoftBadge from "components/SoftBadge";

// sweetalert2 components
import Swal from "sweetalert2";
import { CreatePostRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";

function ReservationCard({ id, image, title, dateTime, action, disabled, reservation, type, actionFunction,
  userName, userLastName, userEmail, showUserData, actionUse, actionCancel, updateInitialValues }) {
  const { t, i18n } = useTranslation();


  const showAlert = (title, question, confirmButtonText, cancelButtonText, successTitle, successMsg, errorTitle, errorMsg) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `<h5>${title}</h5>`,
        text: question,
        showCancelButton: true,
        confirmButtonText:  confirmButtonText,
        cancelButtonText:  cancelButtonText,
        reverseButtons: true,
      })
      .then(async (result) => {

        if (result.value) {
          try {
            if(actionCancel) {
              await CreatePostRequest(BackRequests.CANCEL_RESERVATION + id);
              newSwal.fire(successTitle, successMsg, "success");
              updateInitialValues(id)
            }
            if(actionUse) {
              await CreatePostRequest(BackRequests.USE_RESERVATION + id);
              newSwal.fire(successTitle, successMsg, "success");
              updateInitialValues()
            }
          } catch (error) {
            console.log("ERRRRROROOR")
            console.log(error)
            newSwal.fire(errorTitle, errorMsg, "error");
          }
        }
      });
  };

  const onActionClick = async () => {
    showAlert(
      "#" + id,
      actionUse ? t('reservations.card.useDialog.question') : t('reservations.card.cancelDialog.question'),
      actionUse ? t('reservations.card.useDialog.successButton') : t('reservations.card.cancelDialog.successButton'),
      actionUse ? t('reservations.card.useDialog.cancelButton') : t('reservations.card.cancelDialog.cancelButton'),
      actionUse ? t('reservations.card.useDialog.successTitle') : t('reservations.card.cancelDialog.successTitle'),
      actionUse ? t('reservations.card.useDialog.successMsg') + id : t('reservations.card.cancelDialog.successTitle') + id,
      actionUse ? t('reservations.card.useDialog.errorTitle') : t('reservations.card.cancelDialog.errorTitle'),
      actionUse ? t('reservations.card.useDialog.errorMsg') + id : t('reservations.card.cancelDialog.errorMsg') + id ,
    );
  }
  
  return (

    <Card sx={{
      height: "100%",
      minWidth: "100%",
      ...(disabled && {
        backgroundColor: colors.light.main
      }),
    }} >
      <SoftBox p={2} sx={{ height: "100%" }} display="flex" flexDirection="column">
        <SoftBox display="flex" alignItems="center">
          <SoftAvatar src={image} alt={title} size="lg" variant="rounded" />
          <SoftBox ml={1} lineHeight={0}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </SoftTypography>
            {type ? (
              <SoftBox>
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  {getDisplayBusinessItemType(type, t)}
                </SoftTypography>
              </SoftBox>
            ) : null}

          </SoftBox>
        </SoftBox>
        <SoftBox mt={1}>
          <SoftTypography component="span" variant="body3" textTransform="capitalize" fontWeight="bold" color="text">
            {t('reservations.card.reservationNo')}:&nbsp;
          </SoftTypography>
          <SoftBadge color="light" size="xs" badgeContent={id} container />
        </SoftBox>
        <SoftBox my={2}>
          <SoftTypography variant="body3" color="text">
            {reservation.description}
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftTypography component="span" variant="body3" textTransform="capitalize" fontWeight="bold" color="text">
            {t('reservations.card.price')}:&nbsp;
          </SoftTypography>
          <SoftTypography component="span" variant="body3" color="text" sx={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }} textTransform="uppercase">
            {reservation.price} {reservation.currency}
          </SoftTypography>
          <SoftTypography component="span" variant="body3" color="secondary" fontWeight="bold" ml={1} textTransform="uppercase">
            {reservation.discountedPrice} {reservation.currency}
          </SoftTypography>
        </SoftBox>
        {dateTime ? (
          <SoftBox>
            <SoftTypography component="span" variant="body3" textTransform="capitalize" fontWeight="bold" color="text">
              {t('reservations.card.createdAt')}:&nbsp;
            </SoftTypography>
            <SoftTypography component="span" variant="body3" color="text">
              {dateTime}
            </SoftTypography>
          </SoftBox>
        ) : null}
        {showUserData &&
          <SoftBox>
            <Divider />
            {userName || userLastName ? (
              <SoftBox>
                <SoftTypography component="span" variant="body3" textTransform="capitalize" fontWeight="bold" color="text">
                  {t('reservations.card.name')}:&nbsp;
                </SoftTypography>
                <SoftTypography component="span" variant="body3" color="text">
                  {userName} {userLastName}
                </SoftTypography>
              </SoftBox>
            ) : null}
            {userEmail ? (
              <SoftBox>
                <SoftTypography component="span" variant="body3" textTransform="capitalize" fontWeight="bold" color="text">
                  {t('reservations.card.email')}:&nbsp;
                </SoftTypography>
                <SoftTypography component="span" variant="body3" color="text">
                  {userEmail}
                </SoftTypography>
              </SoftBox>
            ) : null}
          </SoftBox>}

        <Divider />
        <SoftBox display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "auto" }}>
          {action.type === "clickAction" && (
            <SoftButton
              color={action.color}
              size="small"
              onClick={onActionClick}
            >
              {action.label}
            </SoftButton>
          )}
          {action.type === "label" && (
            <SoftTypography color={action.color} textTransform="capitalize" variant="body2" fontWeight="bold">{action.label}</SoftTypography>
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ReservationCard
ReservationCard.defaultProps = {
  id: "",
  dateTime: "",
  disabled: false
};

// Typechecking props for the ReservationCard
ReservationCard.propTypes = {
  image: PropTypes.string.isRequired,
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["label", "clickAction"]).isRequired,
    route: PropTypes.string,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  reservation: PropTypes.shape({
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    discountedPrice: PropTypes.number.isRequired,
  }),
  // actionFunction: PropTypes.func
};

export default ReservationCard;
