/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive'
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import WavedBlogCardGym from "../WavedBlogCardGym";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React example components
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


function GymContent({ sponsoredSportFacilities }) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const SportFacilities = Object.values(sponsoredSportFacilities);
    const { t, i18n } = useTranslation();
    return (
        <SoftBox position="relative"
            zIndex={0} mt={5}
            px={{ xs: 1, sm: 0 }}
            //width="80%"
            align="justify"
            sx={{
                width: {
                    xs: "100%", // theme.breakpoints.up('xs')
                    sm: "100%", // theme.breakpoints.up('sm')
                    md: "100%", // theme.breakpoints.up('md')
                    lg: "90%", // theme.breakpoints.up('lg')
                    xl: "90%", // theme.breakpoints.up('xl')
                },
            }}>
            <Grid item xs={12} md={12} lg={12} padding="10px">
                <MediaQuery maxWidth={430}>
                    <SoftTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
                        {t('headers.sportFacilities_prim')}
                    </SoftTypography>
                </MediaQuery>
                <MediaQuery minWidth={1430}>
                    <SoftTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                        {t('headers.sportFacilities_prim')}
                    </SoftTypography>
                </MediaQuery>
                <SoftBox mb={2}>
                    <SoftTypography variant="body1" align="center" color="text">
                        {t('headers.sportFacilities_sec')}
                    </SoftTypography>
                </SoftBox>
            </Grid>


        <Grid item xs={12} md={12} lg={12} padding="10px">
            <Grid item xs={12} md={6} lg={10} >
                <SoftBox mt={2}>
                    <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                        
                    {SportFacilities.map((item,index) => 
                        <div key={index} style={{margin:15}}><Grid item xs={12} md={6} lg={2}>
                            <WavedBlogCardGym
                                image={item.mediaPayload?.mainImageUrl}
                                title={item.name}
                                addresses= {item.address?.city}
                                sports= {item.sports}
                                rating={item.rating}
                                ratingCount={item.ratingCount}
                                maxDiscount={item.maxDiscount}
                                eventID={item.id}                                
                            />
                        </Grid></div>
                        )}
                    </Carousel>
                </SoftBox>
            </Grid>
        </Grid>

        </SoftBox>
    );
}

// Typechecking props for the PricingCards
GymContent.propTypes = {
    sponsoredSportFacilities: PropTypes.array.isRequired,
};


export default GymContent;
