import SoftBox from "components/SoftBox";
import DefaultNavbarLink from "../DefaultNavbar/DefaultNavbarLink";
import { useTranslation } from "react-i18next";
import { clearUserDataFromLocalStorage } from "helpers/general/helpers";
import { useEffect, useState } from "react";

function PublicDefaultNuvbarElementsMobile() {
    const { t, i18n } = useTranslation();
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    useEffect(() => {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData) {
          setUserLoggedIn(true);
          //   if(userData.profileImage) {
          //     console.log(userData.profileImage)
          //     const profileImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.profileImage, { responseType: 'blob' });
          //     setUserProfileImage(URL.createObjectURL(profileImageResponse.data));
          //   }
      } else {
          setUserLoggedIn(false);
      }
  }, []); 

    return (
        <SoftBox px={0.5}>
            {!userLoggedIn && <a href="/authentication/sign-in">
                <DefaultNavbarLink
                    name={t('navbar.login')}
                    light
                /></a>}
            <a href="/home">
                <DefaultNavbarLink
                    name={t('navbar.home')}
                    // light={light}
                /></a>
            <a href="/group-activities">
                <DefaultNavbarLink
                    name={t('navbar.groupActivities')}
                    // light={light}
                /></a>
            <a href="/personal-coaches">
                <DefaultNavbarLink
                    name={t('navbar.personalCoaches')}
                    // light={light}
                /></a>
            <a href="/sport-facilities">
                <DefaultNavbarLink
                    name={t('navbar.sportFacilities')}
                    // light={light}
                /></a>

            <a href="/event-activities">
                <DefaultNavbarLink
                    name={t('navbar.events')}
                    // light={light}
                /></a>
        </SoftBox>
    );
}

export default PublicDefaultNuvbarElementsMobile;
