/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

 // react 18

import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";

// Soft UI Context Provider
import {SoftUIControllerProvider} from "context";
import App from "./App";
import './translate/translate';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App/>
    </SoftUIControllerProvider>
  </BrowserRouter>
);

