/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import FormField from "components/common/FormField";

// Form schemas
import { preventMinus, preventPasteNegative } from "layouts/pages/business/new-business/schemas/validations";
import { formFields } from "layouts/pages/business/edit-business/schemas/form";

// Helpers
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import SoftBadge from "components/SoftBadge";
import { getDisplayBusinessItemType } from "helpers/enums/business-item.enum";
import { useTranslation } from "react-i18next";


function BusinessInfoEdit({ values, errors, touched, setFieldValue, type }) {
  const { t, i18n } = useTranslation();

  const renderAdditionalData = (values, errors, touched, setFieldValue) => {
    switch (type) {
      case BusinessItemTypes.EVENT:
        return (
          <SoftBox mb={1} lineHeight={0} >
            <SoftBox mb={1} mr={1} lineHeight={0} width="30%">
              <FormField
                type={formFields.eventDateStart.type}
                label={formFields.eventDateStart.label}
                name={formFields.eventDateStart.name}
                value={values.eventDateStart}
                error={errors.eventDateStart && touched.eventDateStart}
              />
            </SoftBox>
            <SoftBox mb={1} lineHeight={0} width="30%">
              <FormField
                type={formFields.eventDateFinish.type}
                label={formFields.eventDateFinish.label}
                name={formFields.eventDateFinish.name}
                value={values.eventDateFinish}
                error={errors.eventDateFinish && touched.eventDateFinish}
              />
            </SoftBox>
          </SoftBox>)
      case BusinessItemTypes.PERSONAL_COACH:
        return (
          <SoftBox mb={1} lineHeight={0}>
            <SoftBox mb={1} mt={1} lineHeight={0}>
              <SoftBox mb={1} ml={0.5} lineHeight={0}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  {formFields.aboutMe.label} &nbsp;&nbsp;
                </SoftTypography>
              </SoftBox>
              <SoftEditor
                name={formFields.aboutMe.name}
                placeholder={formFields.aboutMe.placeholder}
                display="flex"
                value={values.aboutMe}
                onChange={(event) => {
                  setFieldValue(formFields.aboutMe.name, event);
                }}
              />
            </SoftBox>
            <SoftBox mb={1} mt={2} lineHeight={0} display="inline-block">
              <FormField
                type={formFields.yearOfBirth.type}
                label={formFields.yearOfBirth.label}
                name={formFields.yearOfBirth.name}
                value={values.yearOfBirth}
                placeholder={formFields.yearOfBirth.placeholder}
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ min: 0 }}
              />
            </SoftBox>
          </SoftBox>
        )
      case BusinessItemTypes.GROUP_ACTIVITY:
        return (
          <SoftBox mb={1} lineHeight={0}>
            <SoftBox mb={1} mt={0.5} lineHeight={0}>
              <SoftBox mb={1} ml={0.5} lineHeight={0}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  {formFields.aboutPrograms.label} &nbsp;&nbsp;
                </SoftTypography>
              </SoftBox>
              <SoftEditor
                name={formFields.aboutPrograms.name}
                placeholder={formFields.aboutPrograms.placeholder}
                display="flex"
                value={values.aboutPrograms}
                onChange={(event) => {
                  setFieldValue(formFields.aboutPrograms.name, event);
                }}
              />
            </SoftBox>
          </SoftBox>
        )
      default:
        break;
    }
  }

  return (
    <SoftBox p={3} display="grid">
      <SoftBox display="flex" justifyContent="space-between">
        <SoftTypography variant="h5">{t('businessItem.editPage.businessInformation')}</SoftTypography>
        <SoftBadge
          variant="contained"
          color="secondary"
          size="sm"
          badgeContent={getDisplayBusinessItemType(type, t)}
          circular
          container
        />
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={formFields.name.type}
              label={formFields.name.label}
              name={formFields.name.name}
              value={values.name}
              placeholder={formFields.name.placeholder}
              error={errors.name && touched.name}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                {formFields.description.label} &nbsp;&nbsp;
              </SoftTypography>
            </SoftBox>
            <SoftEditor
              name={formFields.description.name}
              placeholder={formFields.description.placeholder}
              display="flex"
              value={values.description}
              onChange={(event) => {
                setFieldValue(formFields.description.name, event);
              }}
            />
          </Grid>
          <Grid item xs={12} mt={1.5} >
            {renderAdditionalData(values, errors, touched, setFieldValue)}
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default BusinessInfoEdit;
