
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SoftButton from 'components/SoftButton';
import IconButton from "@mui/material/IconButton";
import SoftBox from "components/SoftBox";
import { CreatePostRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { useTranslation } from "react-i18next";
import SoftInput from "components/SoftInput";
import { useState } from "react";

const RejectBusinessConfirmDialog = ({ businessId, businessName, handleClose, openSuccessSB, openErrorSB, action, getBusinessesData }) => {
    const { t, i18n } = useTranslation();
    const [reason, setReason] = useState("");

    const onRejectBusiness = async () => {
        try {
            await CreatePostRequest(BackRequests.REJECT_BUSINESS_REQUEST,
                { businessItemId: businessId, reason });
            getBusinessesData();
            handleClose();
            openSuccessSB(t('rejectConfirmDialog.successMessage'));
        } catch (error) {
            console.log(error)
            handleClose()
            openErrorSB(GetErrorMessage(error));
        }
    }

    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <DialogTitle> {t('rejectConfirmDialog.title')}</DialogTitle>
            <SoftBox position="absolute" top={0} right={0}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </SoftBox>
            <DialogContent>
                <DialogContentText>
                    {t('rejectConfirmDialog.textPart1')} {businessName}, {t('rejectConfirmDialog.textPart2')}
                </DialogContentText>
                <SoftBox mt={2}>
                    <SoftInput type="text"
                        onChange={(event) => {
                            setReason(event.target.value);
                        }} />
                </SoftBox>
            </DialogContent>
            <DialogActions>
                <SoftButton color="primary" variant="contained" onClick={handleClose}>
                    {t('rejectConfirmDialog.cancel')}
                </SoftButton>
                <SoftButton color="error" variant="contained"
                    onClick={onRejectBusiness}>
                    {t('rejectConfirmDialog.reject')}
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
};

export default RejectBusinessConfirmDialog;