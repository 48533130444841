/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

// Components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftBadge from 'components/SoftBadge';
import ProductCell from "layouts/pages/product-page/components/ProductCell";

import { GetImageUrl } from "helpers/back-request/helpers";
import { BusinessItemStatus } from "helpers/enums/business-item.enum";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";


// Collumns for business requests and active businesses
const dataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "name",
      width: "30%",
      Cell: ({ value: [name, image] }) => (
        <ProductCell image={image} name={name} />
      ),
    },
    { Header: "type", accessor: "type", width: "20%" },
    { Header: "is sponsored", accessor: "isSponsored", width: "10%" },
    { Header: "rating", accessor: "rating", width: "10%" },
    { Header: "actions", accessor: "actions", width: "20%" },
  ],
  rows: []
};

// Collumns for rejected business requests
const rejectedRequestsDataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "name",
      width: "30%",
      Cell: ({ value: [name, image] }) => (
        <ProductCell image={image} name={name} />
      ),
    },
    { Header: "type", accessor: "type", width: "20%" },
    { Header: "reason", accessor: "reason", width: "30%" },
    { Header: "actions", accessor: "actions", width: "20%" },
  ],
  rows: []
};

const populateTableRows = (data, onEditBusiness, seeBusinessProfile, openBusinessSettings,
  setConfirmApproveDialogOpened, setConfirmRejectionDialogOpened, setBusinessName, setBusinessId) => {
  let status;
  const rowsData = data.map((business) => {
    status = business.status;
    return {
      id: business.id,
      name: [business.name, GetImageUrl(business.mediaPayload?.mainImage)],
      type: getTypeBadge(business.type),
      isSponsored: getIsSponsoredFlag(business.isSponsored),
      rating: getRatingBadge(business.rating),
      reason: business.rejectionReason,
      actions: ActionCell(business.status, onEditBusiness, business.id, business.name, seeBusinessProfile, openBusinessSettings, 
        setConfirmApproveDialogOpened, setConfirmRejectionDialogOpened, setBusinessName, setBusinessId)
    }
  });
  const resp = {
    columns: status == BusinessItemStatus.REJECTED ? rejectedRequestsDataTableData.columns : dataTableData.columns,
    rows: rowsData,
  }
  return resp;
};

const getIsSponsoredFlag = (isSponsored) => {
  return isSponsored ? "yes" : "no";
};

const getRatingBadge = (rating) => {
  let backgroundColor = "gray";
  switch (rating) {
    case 1:
      backgroundColor = "#E34234";
      break;
    case 2:
      backgroundColor = "#FFAC1C";
      break;
    case 3:
      backgroundColor = "#FDDA0D";
      break;
    case 4:
      backgroundColor = "#93C572";
      break;
    case 5:
      backgroundColor = "#00A36C";
      break;
  }
  return (
    <SoftBadge variant="contained" color="primary" size="sm" badgeContent={rating} container circular sx={{
      "& .MuiBadge-badge": {
        color: "white !important",
        backgroundColor: backgroundColor
      }
    }} />)
};

const getTypeBadge = (type) => {
  switch (type) {
    case BusinessItemTypes.EVENT:
      return (
        <SoftBadge variant="contained" color="dark" size="xs" badgeContent="event" container />
      )
    case BusinessItemTypes.PERSONAL_COACH:
      return (
        <SoftBadge variant="contained" color="info" size="xs" badgeContent="personal coach" container />
      )
    case BusinessItemTypes.SPORT_FACILITY:
      return (
        <SoftBadge variant="contained" color="success" size="xs" badgeContent="sport facility" container />
      )
    case BusinessItemTypes.GROUP_ACTIVITY:
      return (
        <SoftBadge variant="contained" color="error" size="xs" badgeContent="group activity" container />
      )
  }
};

function ActionCell(status, onEditBusiness, businessItemId, businessItemName, seeBusinessProfile, openBusinessSettings, 
  setConfirmApproveDialogOpened, setConfirmRejectionDialogOpened, setBusinessName, setBusinessId) {

  if (status == BusinessItemStatus.ACTIVE) {
    return (
      <SoftBox display="flex" alignItems="center">
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Preview business data" placement="top">
            <IconButton variant="gradient" color="primary" size="small"
              onClick={() => {
                seeBusinessProfile(businessItemId)
              }}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </SoftTypography>
        <SoftBox ml={1}>
            <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Settings" placement="left">
                <IconButton color="secondary" size="small"
                  onClick={() => {
                    openBusinessSettings(businessItemId)
                  }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  } else if (status == BusinessItemStatus.PENDING_APPROVAL) {
    return (
      <SoftBox display="flex" alignItems="center">
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Preview business data" placement="top">
            <IconButton variant="gradient" color="primary" size="small"
              onClick={() => {
                seeBusinessProfile(businessItemId)
              }}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </SoftTypography>
        <SoftBox mx={2}>
          <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Approve" placement="top" arrow>
              <IconButton variant="gradient" color="success" size="small"
                onClick={() => {
                  setConfirmApproveDialogOpened(true);
                  setBusinessName(businessItemName);
                  setBusinessId(businessItemId);
                }}>
                <CheckCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Reject" placement="left">
            <IconButton variant="gradient" color="error" size="small"
              onClick={() => {
                setConfirmRejectionDialogOpened(true);
                setBusinessName(businessItemName);
                setBusinessId(businessItemId);
              }}>
              <ClearIcon color="error" />
            </IconButton>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center">
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Preview business data" placement="top">
            <IconButton variant="gradient" color="primary" size="small"
              onClick={() => {
                seeBusinessProfile(businessItemId)
              }}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    );
  }


};

export {
  dataTableData,
  populateTableRows
};

