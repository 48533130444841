/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { CreateDeleteRequest } from "helpers/back-request/helpers";

function DeleteUser({ firstName, lastName, userId }) {
  const { t, i18n } = useTranslation();

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-error",
        cancelButton: "button button-secondary",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `<h5>${t('user.settings.deleteSection.deleteConfirmDialog.title')}</h5>`,
        text: `${t('user.settings.deleteSection.deleteConfirmDialog.question')} ${firstName} ${lastName}`,
        showCancelButton: true,
        confirmButtonText: t('user.settings.deleteSection.deleteConfirmDialog.delete'),
        cancelButtonText: t('user.settings.deleteSection.deleteConfirmDialog.cancel'),
        reverseButtons: true,
      })
      .then(async (result) => {

        if (result.value) {
          try {
            await CreateDeleteRequest(BackRequests.DELETE_USER_PROFILE + userId);
            newSwal.fire(
                t('user.settings.deleteSection.deleteConfirmDialog.successTitle'), 
                t('user.settings.deleteSection.deleteConfirmDialog.successMsg'), 
                "success");
          } catch (error) {
            console.log("ERRRRROROOR")
            console.log(error)
            newSwal.fire(
                t('user.settings.deleteSection.deleteConfirmDialog.errorTitle'), 
                `${t('user.settings.deleteSection.deleteConfirmDialog.errorMsg')} ${firstName} ${lastName}`,
                "error");
          }
        }
      });
  };

  return (
    <Card id="delete-account" sx={{width:"100%"}}>
      <SoftBox p={3} pb={0} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">
            {t('user.settings.deleteSection.title')}
          </SoftTypography>
        </SoftBox>

      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >

        <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
          <SoftTypography variant="body2" color="text" fontWeight="regular">
            {t('user.settings.deleteSection.text')}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton variant="gradient" color="error" sx={{ height: "100%" }} onClick={showAlert}>
              {t('user.settings.deleteSection.button')}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default DeleteUser;
