/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";


function MainInformationTab({ businessType, businessDescription, personalCoachPayload, personalCoachImage }) {
    return (
        <SoftBox m={2}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={1}>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    <Grid item xs={12} lg={12} sx={{ mx: "auto" }}>
                        <ProductInfo businessType={businessType} businessDescription={businessDescription} personalCoachPayload={personalCoachPayload} personalCoachImage={personalCoachImage}/>
                    </Grid>
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    )
}

export default MainInformationTab;

