import * as Yup from "yup";
import fields from "layouts/pages/home/schemas/form";
import FormNames from "layouts/pages/home/schemas/form-names.enum"

const {
    [FormNames.SEARCH]: {
        businessName, location, category, searchGender, searchAdditionals, searchSkillLevel, searchAgeCategory, searchSport, reviewScore
    },
    [FormNames.MAKE_RESERVATION]: {
        firstName,
        lastName,
        email,
        service,
    },
} = fields;


const validations = {
    [FormNames.MAKE_RESERVATION]: Yup.object().shape({
        [firstName.name]: Yup.string().required(firstName.errorMsg),
        [lastName.name]: Yup.string().required(lastName.errorMsg),
        [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    }),
}

export default validations;
