/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

const {dark} = colors;

const formLabel = {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
};

export default formLabel;
