/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CategoriesList from "examples/Lists/CategoriesList";
import MessageCard from "examples/Cards/MessageCard";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import PieChart from "examples/Charts/PieChart";


// Images
import belgradeMarathon from "assets/images/belgrade-city-marathon.jpg";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { populateBusinessTypesPieChartData } from "./data/pieChartData";
import { populateUsersDoughnutChartData } from "./data/defaultDoughnutChartData";
import { populateBusinessTypesCategoriesListData } from "./data/categoriesListData";

function CRM() {
  const [businessTypesPieData, setBusinessTypesPieData] = useState(null);
  const [categoriesListData, setCategoriesListData] = useState(null);
  const [userRolesStatistics, setUserRolesStatistics] = useState(null);

  const getBusinessStatistics = async () => {
    try {
      // Get businesses data from database
      const response = await CreatePostRequest(BackRequests.GET_BUSINESS_STATISTICS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // openErrorSB(GetErrorMessage(error), true);
    }
  };

  const getUserStatistics = async () => {
    try {
      // Get businesses data from database
      const response = await CreatePostRequest(BackRequests.GET_USER_STATISTICS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // openErrorSB(GetErrorMessage(error), true);
    }
  };

  useEffect(() => {
    (async () => {
      const businessesData = await getBusinessStatistics();
      if(businessesData) {
        setBusinessTypesPieData(populateBusinessTypesPieChartData(businessesData));
        setCategoriesListData(populateBusinessTypesCategoriesListData(businessesData));
      }

      const usersData = await getUserStatistics();
      if(usersData) {
        setUserRolesStatistics(populateUsersDoughnutChartData(usersData));
      }
    })();
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  {
                    userRolesStatistics && (
                      <DefaultDoughnutChart title="User types" chart={userRolesStatistics} />
                    )
                  }
                </Grid>
                <Grid item xs={12} sm={6}>
                  {
                    businessTypesPieData && (
                      <PieChart title="Business types total" chart={businessTypesPieData} />
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  {
                    categoriesListData && (
                      <CategoriesList title="Business types by status" categories={categoriesListData} />
                    )
                  }
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <MessageCard
                    image={belgradeMarathon}
                    text="Today is Belgrade Marathon!"
                    button={{ color: "dark", text: "see details" }}
                    action={{
                      type: "internal",
                      route: "/dashboards/crm",
                      color: "dark",
                      label: "see details",
                    }}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CRM;
