/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import HomeIcon from '@mui/icons-material/Home';

// @mui material components
import {Breadcrumbs as MuiBreadcrumbs} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function Breadcrumbs({icon, title, route, light, showTitle}) {
  const routes = route.slice(0, -1);

  return (
    <SoftBox mr={{xs: 0, xl: 8}}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({palette: {white, grey}}) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <SoftTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{lineHeight: 0}}
          >
            <HomeIcon>{icon}</HomeIcon>
            {/*<Icon>{icon}</Icon>*/}
          </SoftTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <SoftTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{lineHeight: 0}}
            >
              {el}
            </SoftTypography>
          </Link>
        ))}
        <SoftTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{lineHeight: 0}}
        >
          {title.replace("-", " ")}
        </SoftTypography>
      </MuiBreadcrumbs>
      { showTitle && <SoftTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </SoftTypography>}
    </SoftBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default Breadcrumbs;
