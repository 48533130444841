/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";

import { formFields } from "layouts/pages/business/business-reservations/schemas/form";

const {
  reservationId, dateFrom, dateTo, name, lastname, email, businessName, reservationStatus
} = formFields;

const validations = Yup.object().shape({
  [email.name]: Yup.string().email(email.errorMessage),
  [name.name]: Yup.string().when('lastname', {
    is: (lastname) => lastname,
    then: Yup.string().required(name.errorMessage)
  }),
  [lastname.name]: Yup.string().when('name', {
    is: (name) => name,
    then: Yup.string().required(lastname.errorMessage)
  }),
},[[name.name, lastname.name]]
);

export { validations };

