/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "neutral", label: "Neutral" },
];

const interestOptions = [
  { value: "yoga", label: "Yoga" },
  { value: "swimming", label: "Swimming" },
  { value: "running", label: "Running" },
  { value: "crossfit", label: "Crossfit" },
  { value: "martial-arts", label: "Martial arts" },
];

const formFields = {
  firstName: {
    name: "firstName",
    label: "first name *",
    type: "text",
    placeholder: "eg. John",
    errorMsg: "First name is required.",
  },
  lastName: {
    name: "lastName",
    label: "last name *",
    type: "text",
    placeholder: "eg. Doe",
    errorMsg: "Last name is required.",
  },
  email: {
    name: "email",
    label: "email address *",
    type: "email",
    placeholder: "eg. example@domain.come",
    errorMsg: "Email address is required.",
    invalidMsg: "Your email address is invalid",
  },
  gender: {
    name: "gender",
    options: genderOptions,
    errorMsg: "Gender is required.",
  },
  age: {
    name: "age",
    label: "age *",
    type: "number",
    errorMsg: "Age is required.",
    invalidMsg: "Your age is invalid.",
    bounderyInvalidMsg: "Your age should be more than 10.",
  },
  phoneNumber: {
    name: "phoneNumber",
    label: "phone number",
    type: "text",
    placeholder: "+000 00 000000",
    invalidMsg: "Your phone number is invalid.",
  },
  currentPassword: {
    name: "currentPassword",
    label: "current password",
    type: "password",
    placeholder: "Current Password",
    errorMsg: "Current password is required.",
    invalidMsg: "Your current password should be more than 6 characters.",
  },
  newPassword: {
    name: "newPassword",
    label: "new password",
    type: "password",
    placeholder: "New Password",
    errorMsg: "New password is required.",
    invalidMsg: "Your new password should be more than 6 characters.",
  },
  repeatPassword: {
    name: "repeatPassword",
    label: "confirm new password",
    type: "password",
    placeholder: "Confirm Password",
    errorMsg: "Confirm Password is required.",
    invalidMsg: "Your password doesn't match.",
  },
  profileImage: {
    name: "profileImage",
    type: "file",
    accept: "image/*"
  },
};

const getGenderOption = (optionName) => {
  return genderOptions.find((option)=> option.value?.toLowerCase() == optionName?.toLowerCase())
}

export {
  formFields,
  interestOptions,
  getGenderOption
};
