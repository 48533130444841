import * as Yup from "yup";
import checkout from "layouts/authentication/sign-in/basic/schemas/form";

const {
    formField: { email, password },
} = checkout;

const validations =
    Yup.object().shape({
        [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
        [password.name]: Yup.string().required(password.errorMsg).min(5, password.invalidMsg), 
        // TODO: What is the rule for the password

    });

export default validations;
