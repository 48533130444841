import axios from "axios";
import MessagesTexts from "helpers/messages/messages-texts";
import BackRequests from "./back-requests.enum";

import unknown from "assets/images/business-item-unknown.png";

// const BACK_SERVICE_URL = process.env.REACT_APP_BACK_SERVICE_URL;
const BACK_SERVICE_URL = "https://moveon.fit/api/";

async function CreatePostRequest(routeName, body) {
  let headers = {};
  if (body) {
      headers = {
          "Content-Type": "application/json",
          "Accept": "application/json",
      }
  }
  return await axios.post(BACK_SERVICE_URL + routeName, body, { headers } );
}

async function CreateMultipartPostRequest(routeName, body) {
  return await axios.post(BACK_SERVICE_URL + routeName, body, {headers: {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
  }});
}

async function CreateGetRequest(routeName, config = {}) {
  return await axios.get(BACK_SERVICE_URL + routeName, config);
}

async function CreateDeleteRequest(routeName) {
  return await axios.delete(BACK_SERVICE_URL + routeName);
}

function GetBackUrl() {
  return BACK_SERVICE_URL;
}

function checkIfResponseIsForClient(error) {
  return error.response?.data?.responseCode > 100 && error.response?.data?.responseCode < 200
}

function GetErrorMessage(error) {
  if (checkIfResponseIsForClient(error)) {
    return error.response.data.message;
  } else {
    return MessagesTexts.UNEXPECTED_ERROR;
  }
}

const GetImageUrl = (imageId) => {
  if (imageId) {
    return GetBackUrl() + BackRequests.GET_MEDIA + imageId;
  }
  return unknown;
}

const GetUploadImageUrl = () => {
  return GetBackUrl() + BackRequests.UPLOAD_MEDIA;
}

export {CreatePostRequest, CreateMultipartPostRequest, CreateGetRequest, CreateDeleteRequest, GetErrorMessage, GetBackUrl, GetImageUrl, GetUploadImageUrl};
