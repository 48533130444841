/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const businessTypePieChartData = {
  labels: ["Events", "Group activities", "Personal coaches", "Sport facilities"],
  datasets: {
    label: "Business types",
    backgroundColors: ["info", "dark", "secondary", "warning"],
    data: [15, 20, 12, 60],
  },
};

const populateBusinessTypesPieChartData = (statisticsData) => {
  const pieChartData = businessTypePieChartData;
  pieChartData.datasets.data = [
    statisticsData.eventNum.total, 
    statisticsData.groupActivitiesNum.total, 
    statisticsData.personalCoachesNum.total, 
    statisticsData.sportFacilitiesNum.total];

    console.log(pieChartData)
  return pieChartData;
};

export { 
  businessTypePieChartData,
  populateBusinessTypesPieChartData
};
