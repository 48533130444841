/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "./components/Header";
import GeneralInfo from "./components/general-info";
import AccountInfo from "./components/account-info";
import DeleteUser from "./components/delete-user";
import Ownership from "./components/ownership";
import BlockUser from "./components/block-user";
import UserRoles from "helpers/enums/user-role.enum";
import Footer from "examples/Footer";
import UserStatus from "helpers/enums/user-status.enum";
import UnblockUser from "./components/unblock-user";
import placeholderMainImage from "assets/images/moveon/placeholder-main-image.svg";

function UserSettings() {
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(false);
    const [userDataObj, setUserDataObj] = useState();

    const getUserData = async () => {
        try {
            console.log("getUserData")
            // Get business data from database
            const response = await CreateGetRequest(BackRequests.GET_USER + location.state.userId);
            const userData = response.data.data;

            if (userData.accountData?.profileImage) {
                const profileImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.accountData?.profileImage, { responseType: 'blob' });
                userData.accountData.profileImageUrl = URL.createObjectURL(profileImageResponse.data);
            }

            if (userData.accountData.role == UserRoles.BUSINESS_ADMIN) {
                const ownershipResp = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEMS_FOR_USER + location.state.userId);
                const ownership = ownershipResp.data.data;

                for (let i = 0; i < ownership.length; i++) {
                    if (ownership[i].mediaPayload?.mainImage) {
                        const businessImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + ownership[i].mediaPayload?.mainImage, { responseType: 'blob' });
                        ownership[i].mediaPayload.mainImageUrl = URL.createObjectURL(businessImageResponse.data);
                    } else {
                        ownership[i].mediaPayload.mainImageUrl = placeholderMainImage;
                    }
                }
                userData.ownership = ownership;
            }

            return userData;
        } catch (error) {
            console.log("error")
            console.log(error)
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setShowLoader(true);
            const userDataResp = await getUserData();
            console.log(userDataResp)

            setUserDataObj(userDataResp);
            setShowLoader(false);
        }
        loadData();
    }, []);

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSB, setErrorSB] = useState(false);

    const openErrorSB = (message) => {
        setErrorMessage(message);
        setErrorSB(true);
    };
    const closeErrorSB = () => setErrorSB(false);

    // Tabovi: Logovi, uplate
    // Sekcija: kada je registrovan, kada je poslednja izmena
    //loader

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mt={4} mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Header
                            id={userDataObj?.id}
                            firstName={userDataObj?.firstName}
                            lastName={userDataObj?.lastName}
                            image={userDataObj?.accountData?.profileImageUrl}
                            role={userDataObj?.accountData?.role}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6} display="flex">
                        <GeneralInfo
                            id={userDataObj?.id}
                            email={userDataObj?.email}
                            phoneNumber={userDataObj?.phoneNumber}
                            gender={userDataObj?.gender}
                            age={userDataObj?.age}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6} display="flex">
                        <AccountInfo
                            id={userDataObj?.id}
                            status={userDataObj?.accountData?.status}
                            dateOfRegistration={userDataObj?.accountData?.dateOfRegistration}
                            dateOfLastAction={userDataObj?.accountData?.dateOfLastAction}
                        />
                    </Grid>
                    {
                        userDataObj?.accountData?.role != UserRoles.SYSTEM_ADMIN &&
                        userDataObj?.accountData?.status != UserStatus.DELETED &&
                        <Grid item xs={6} lg={6} display="flex">
                            <DeleteUser
                                userId={userDataObj?.id}
                                firstName={userDataObj?.firstName}
                                lastName={userDataObj?.lastName}
                            />
                        </Grid>
                    }
                    {
                        userDataObj?.accountData?.role != UserRoles.SYSTEM_ADMIN &&
                        userDataObj?.accountData?.status != UserStatus.BLOCKED &&
                        userDataObj?.accountData?.status != UserStatus.DELETED &&
                        (<Grid item xs={6} lg={6} display="flex">
                            <BlockUser
                                userId={userDataObj?.id}
                                firstName={userDataObj?.firstName}
                                lastName={userDataObj?.lastName}
                            />
                        </Grid>)
                    }
                    {
                        userDataObj?.accountData?.role != UserRoles.SYSTEM_ADMIN &&
                        userDataObj?.accountData?.status == UserStatus.BLOCKED &&
                        (<Grid item xs={6} lg={6} display="flex">
                            <UnblockUser
                                userId={userDataObj?.id}
                                firstName={userDataObj?.firstName}
                                lastName={userDataObj?.lastName}
                            />
                        </Grid>)
                    }
                    {
                        userDataObj?.ownership?.length > 0 &&
                        <Grid item xs={12}>
                            <Ownership
                                id={userDataObj?.id}
                                businessItems={userDataObj?.ownership}
                            />
                        </Grid>
                    }
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default UserSettings;
