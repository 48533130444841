/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";
import {ErrorMessage, Form, Formik} from "formik";
import Moment from 'moment';


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import FormField from "components/common/FormField";
import SoftSelect from "components/SoftSelect";
import SoftBadge from "components/SoftBadge";

// Helpers
import {CreatePostRequest, GetErrorMessage} from "helpers/back-request/helpers"
import MessagesTexts from "helpers/messages/messages-texts";
import BackRequests from "helpers/back-request/back-requests.enum";

// Form schemas
import {preventMinus, preventPasteNegative, validations} from "layouts/pages/client/profile/schemas/validations";
import {formFields, getGenderOption, interestOptions} from "layouts/pages/client/profile/schemas/form";

// Default avatar
import clientAvatar from "assets/images/client-avatar.png";
import { CreateMultipartPostRequest } from "helpers/back-request/helpers";

function UserDataForm({initialValuesData, setResponseMessage}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const formId = "edit-client-profile-form";
  const formValidation = validations[formId];

  useEffect(() => {
    if (initialValuesData?.profileImage) {
      setSelectedFile(URL.createObjectURL(initialValuesData?.profileImage));
    }
  }, [initialValuesData]);


  const updateLocalStorageUserData = async (changedValues, localStorageUser) => {
    for (const key in changedValues) {
      if (Object.hasOwnProperty.call(changedValues, key) && Object.hasOwnProperty.call(localStorageUser, key)) {
        localStorageUser[key] = changedValues[key];
      }
    }
    localStorage.setItem("user", JSON.stringify(localStorageUser))
  }

  const saveEditedProfileData = async (changedValues, profileImageName) => {
    try {
      if (profileImageName) changedValues['profileImage'] = profileImageName;
      const localStorageUserData = JSON.parse(localStorage.getItem("user"));
      await CreatePostRequest(BackRequests.EDIT_USER,
        {
          userId: localStorageUserData.userId,
          ...changedValues
        });
      setResponseMessage(MessagesTexts.SUCCESSFULLY_UPDATED_PROFILE, false);
      await updateLocalStorageUserData(changedValues, localStorageUserData);
    } catch (error) {
      setResponseMessage(GetErrorMessage(error), true);
    }
  }

  const checkForChangedValues = (values) => {
    let changedValues = {};
    for (const key in initialValuesData) {
      if (Object.hasOwnProperty.call(values, key) && values[key] !== initialValuesData[key]) {
        let newValue = {};
        if (key === formFields.gender.name) {
          newValue[key] = values[key].value;
        } else {
          newValue[key] = values[key];
        }
        changedValues = {...changedValues, ...newValue};
      }
    }
    return changedValues
  }

  const handleSubmit = async (values, actions) => {
    if (isEditMode) {
      const changedValuesObj = checkForChangedValues(values);
      let newProfileImage = "";
      if (Object.hasOwnProperty.call(changedValuesObj, "profileImage")) {
        const body = new FormData();
        body.append('file', values.profileImage);
        const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
        newProfileImage = response?.data?.data?.fileName;
      }

      await saveEditedProfileData(checkForChangedValues(values), newProfileImage);
      actions.setSubmitting(false);
      setIsEditMode(false);
    }
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesData}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
      onReset={handleCancel}
    >
      {({values, errors, touched, isSubmitting, resetForm, setFieldValue}) => (
        <Form id={formId} autoComplete="off">
          <Card id={formId}>
            <SoftBox p={3}>
              <SoftTypography variant="h5">Profile information</SoftTypography>
            </SoftBox>
            <SoftBox p={2}>
              <SoftBox mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} mt={3} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                        <SoftAvatar src={selectedFile ? selectedFile : clientAvatar}
                        alt="profile picture" size="xxl" variant="rounded"/>
                        
                      {isEditMode &&
                        (
                          <SoftBox alt="upload image" position="absolute" right={0} bottom={0} mb={-1}>
                            <SoftButton htmlFor="icon" variant="contained" component="label" color="light" size="small" iconOnly>
                              <EditIcon/>
                              <input
                                id="icon"
                                name={formFields.profileImage.name}
                                type={formFields.profileImage.type}
                                accept={formFields.profileImage.accept}
                                onChange={(event) => {
                                  setFieldValue(formFields.profileImage.name, event.currentTarget.files[0]);
                                  setSelectedFile(URL.createObjectURL(event.currentTarget.files[0]));
                                }}
                                hidden
                                />
                            </SoftButton>
                          </SoftBox>
                        )
                      }
                    </SoftBox>
                    <SoftBox width="100%" display="flex" flexDirection="column" lineHeight={1} alignItems="center">
                      <SoftBox mb={2}>
                        <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                          <SoftBadge variant="contained" color="success" size="sm"
                                     badgeContent={initialValuesData.status} container/>
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox mb={1} lineHeight={0} display="inline-block">
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                          display="flex"
                          mb={0.5}
                        >
                          Date of registration
                        </SoftTypography>
                        <SoftTypography variant="caption" fontWeight="medium" color="text" textTransform="capitalize">
                          {Moment(initialValuesData.dateOfRegistration).fromNow()}
                          {": "}
                          {Moment(initialValuesData.dateOfRegistration).format("DD. MMMM YYYY, HH:mm:ss")}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox mb={1} lineHeight={0} display="inline-block">
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                          display="flex"
                          mb={0.5}
                        >
                          Date of last action
                        </SoftTypography>
                        {/*TODO: Date is not refreshing on save*/}
                        <SoftTypography variant="caption" fontWeight="medium" color="text" textTransform="capitalize">
                          {Moment(initialValuesData.dateOfLastAction).fromNow()}
                          {": "}
                          {Moment(initialValuesData.dateOfLastAction).format("DD. MMMM YYYY, HH:mm:ss")}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={6}>
                        <FormField
                          type={formFields.firstName.type}
                          label={formFields.firstName.label}
                          name={formFields.firstName.name}
                          value={values.firstName}
                          placeholder={formFields.firstName.placeholder}
                          error={errors.firstName && touched.firstName && isEditMode}
                          success={values.firstName.length > 0 && !errors.firstName && isEditMode}
                          disabled={!isEditMode}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type={formFields.lastName.type}
                          label={formFields.lastName.label}
                          name={formFields.lastName.name}
                          value={values.lastName}
                          placeholder={formFields.lastName.placeholder}
                          error={errors.lastName && touched.lastName && isEditMode}
                          success={values.lastName.length > 0 && !errors.lastName && isEditMode}
                          disabled={!isEditMode}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={6}>
                        <FormField
                          type={formFields.email.type}
                          label={formFields.email.label}
                          name={formFields.email.name}
                          value={values.email}
                          placeholder={formFields.email.placeholder}
                          error={errors.email && touched.email && isEditMode}
                          success={values.email.length > 0 && !errors.email && isEditMode}
                          disabled={!isEditMode}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {/* TODO: add mask for this filed */}
                        <FormField
                          type={formFields.phoneNumber.type}
                          label={formFields.phoneNumber.label}
                          name={formFields.phoneNumber.name}
                          value={values.phoneNumber}
                          placeholder={formFields.phoneNumber.placeholder}
                          error={errors.phoneNumber && touched.phoneNumber && isEditMode}
                          success={values.phoneNumber.length > 0 && !errors.phoneNumber && isEditMode}
                          disabled={!isEditMode}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Gender *
                        </SoftTypography>
                        <SoftSelect
                          name={formFields.gender.name}
                          value={values.gender}
                          options={formFields.gender.options}
                          onChange={value => setFieldValue(formFields.gender.name, getGenderOption(value.value))}
                          isDisabled={!isEditMode}
                        />
                        <SoftBox mt={0.75}>
                          <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={formFields.gender.name} />
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={6}>
                        <FormField
                          type={formFields.age.type}
                          label={formFields.age.label}
                          name={formFields.age.name}
                          value={values.age}
                          error={errors.age && touched.age && isEditMode}
                          success={values.age.length > 0 && !errors.age && isEditMode}
                          inputProps={{type: 'number', min: 10}}
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          disabled={!isEditMode}
                        />
                      </Grid>
                    </Grid>
                    {/* <SoftBox mb={2}>
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Interests
                        </SoftTypography>
                      </SoftBox>
                      TODO:  Display problem because it is the last one on the page
                      <SoftSelect options={interestOptions} isMulti isDisabled={!isEditMode}/>
                    </SoftBox> */}
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mt={3} width="100%" display="flex" justifyContent="right">
                {isEditMode ? (
                  <SoftBox display="flex" flexDirection={{xs: "column", sm: "row"}}>
                    <SoftButton key="notsubmit" variant="gradient" color="dark" type="button" onClick={resetForm}
                                mr={2}> cancel </SoftButton>
                    <SoftBox ml={{xs: 0, sm: 1}} mt={{xs: 1, sm: 0}}>
                      <SoftButton key="submit" variant="gradient" color="success" type="submit"
                                  disabled={isSubmitting}> save </SoftButton>
                    </SoftBox>
                  </SoftBox>
                ) : (
                  <SoftButton key="notsubmit" variant="gradient" color="dark" type="button"
                              onClick={handleEdit}> edit </SoftButton>
                )}
              </SoftBox>
            </SoftBox>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default UserDataForm;