/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import moment from 'moment'

// @mui material components
import AddIcon from '@mui/icons-material/Add';


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import clientAvatar from "assets/images/client-avatar.png";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import StarIcon from '@mui/icons-material/Star';


// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

// Images
import RatingStars from "../../../../../components/common/RatingStars";
import ReviewModal from "./modal";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import { useTranslation } from "react-i18next";

function ReviewsTab({ reviews, rating, ratingCount, businessItemId, refreshReviews }) {
  const { t, i18n } = useTranslation();
  const { borderWidth, borderColor } = borders;

  // Modal states
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);

  // Success messages - Use this only for adding new review because of refreshReviews invocation
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
    refreshReviews();
  };
  const closeSuccessSB = () => setSuccessSB(false);

  const renderReviews = reviews?.map((review, key) => (
    <SoftBox key={key} display="flex" mt={key === 0 ? 0 : 3} mb={3}>
      <SoftBox flexShrink={0}>
        <SoftAvatar src={review.user.profileImageContent ? review.user.profileImageContent : clientAvatar}
          alt="profile picture" />
          <SoftBox display="flex" alignItems="center" mt={1}>
            <StarIcon color="secondary"/> 
            <SoftTypography variant="button" ml={0.3}>{review.rating}/5</SoftTypography>
          </SoftBox>
      </SoftBox>
      <SoftBox flexGrow={1} ml={2}>
        <SoftTypography variant="body2" fontWeight="medium" textTransform="capitalize" mb={-1}>
          {review.user.firstName + " " + review.user.lastName}
        </SoftTypography>
        <SoftTypography variant="caption" pb={1} borderBottom={`${borderWidth[1]} solid ${borderColor}`}>
          Reviewed: {moment(review.createdAt).format("DD/MM/yyyy HH:mm")}
        </SoftTypography>
        <SoftBox mt={2} mb={2} lineHeight={0.75} display="flex" alignItems="top">
          <SentimentSatisfiedAltIcon sx={{ marginRight: 1 }} fontSize="medium" color="success" />
          <SoftTypography variant="body2" fontWeight="regular" color="text">
            {review.goodMessage ? review.goodMessage : "No message"}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={2} mb={2} lineHeight={0.75} display="flex" alignItems="top">
          <SentimentVeryDissatisfiedIcon sx={{ marginRight: 1 }} fontSize="medium" color="dark" />
          <SoftTypography variant="body2" fontWeight="regular" color="text">
            {review.badMessage ? review.badMessage : "No message"}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  ));

  return (
    <SoftBox>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        py={2}
        px={3}
      >
        <SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="primary"
              color="white"
              width="4rem"
              height="4rem"
              shadow="md"
              borderRadius="lg"
            >
              {rating}
            </SoftBox>
            <SoftBox ml={2} lineHeight={0}>
              <SoftBox lineHeight={0}>
                <SoftTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                  <RatingStars precision={0.5} rating={Number(rating)} />
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                {ratingCount} {t('reviews.reviews')}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
          <ReviewModal open={openModal} handleClose={handleCloseModal} openErrorSB={openErrorSB} openSuccessSB={openSuccessSB}
            businessItemId={businessItemId}>
          </ReviewModal>
          <SoftButton variant="gradient" color="info" size="small" onClick={handleOpenModal}>
            <AddIcon sx={{ fontWeight: "bold" }} />&nbsp; {t('reviews.btnAddReview')}
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox p={3}>
        <SoftBox mt={3} mb={1} px={1}>
          {renderReviews}
          {reviews?.length == 0 && (
            <SoftBox textAlign="center">
              <SoftTypography variant="body2" align="center">
                {t('reviews.noReviews')}
              </SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
      {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
    </SoftBox>
  );
}

export default ReviewsTab;
