/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import SoftBadge from "components/SoftBadge";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function PricelistTab({ pricelist }) {
    const { t, i18n } = useTranslation();
    const dataTableData = {
        columns: [
            { Header: t('tabs.tab_price_desc'), accessor: "description", width: "49%" },
            { Header: t('tabs.tab_price_reg'), accessor: "regularPrice", width: "17%", align: "center" },
            { Header: t('tabs.tab_price_disc'), accessor: "moveOnDiscount", width: "17%", align: "center" },
            { Header: t('tabs.tab_price_new'), accessor: "moveOnPrice", width: "17%", align: "center" },
        ],
        rows: [],
    };
    const [tableData, setTableData] = useState(dataTableData);
    const options = {  maximumFractionDigits: 2   } 
    useEffect(() => {
        const rows = pricelist.map((item)=> {
            return {
                description: (
                    <SoftBox display="inline-block" sx={{verticalAlign: "middle", wordbreak: "break-all"}}>
                        <SoftTypography display="block" variant="body2"> {item.description}</SoftTypography>
                    </SoftBox>
                ),
                regularPrice: (
                    <SoftTypography textTransform="uppercase" variant="body2"> {Intl.NumberFormat("en-US",options).format(item.price) + " " + item.currency}</SoftTypography>
                ),
                moveOnDiscount: (
                    <SoftBadge container variant="contained" color="secondary" badgeContent={item.discount + "%"} />
                ) ,
                moveOnPrice: (
                    <SoftTypography color="secondary" variant="body2"  fontWeight="bold" textTransform="uppercase"> {Intl.NumberFormat("en-US",options).format(item.discountedPrice) + " " + item.currency}</SoftTypography>
                ),
            }
        })
        setTableData({
            columns: dataTableData.columns,
            rows
        });
    }, []);

    return (
        <SoftBox>
            {/* <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={1}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {//<u><b>Cenovnik</b></u>
                        }
                    </SoftTypography>
                </SoftBox> */}
            {/* <TableContainer sx={{ boxShadow: "none" }}>
                        <Table>
                            <SoftBox component="thead">
                                <TableRow>
                                    <PagesHeaderCell>Opis usluge</PagesHeaderCell>
                                    <PagesHeaderCell>Regularna cena</PagesHeaderCell>
                                    <PagesHeaderCell>MoveOn Popust</PagesHeaderCell>
                                    <PagesHeaderCell>MoveOn cena</PagesHeaderCell>
                                </TableRow>
                            </SoftBox>
                            <TableBody>
                                {pricelist?.sort((a, b) => a.key > b.key ? 1 : -1).map((item, i) => {
                                    return <PagesBodyCell key={i} rows={[i + 1 + ". " + item.description, item.price + " " + item.currency, item.discount + "%", item.discountedPrice + " " + item.currency]} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> */}

            <DataTable table={tableData} showTotalEntries={false} entriesPerPage={false} />
        </SoftBox>
    )
}

export default PricelistTab;

