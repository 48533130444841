/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Formik, Form, FieldArray } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import FormField from "components/common/FormField";
import SoftBox from "components/SoftBox";

// Form schemas
import { validations } from "layouts/pages/business/new-business/schemas/validations";
import { fields } from "layouts/pages/business/new-business/schemas/form";
import { initial } from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import Tooltip from "@mui/material/Tooltip";
import SoftButton from "components/SoftButton";
import { IconButton } from "@mui/material";
import { countryOptions } from "layouts/pages/business/business-form-helpers";
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from "@mui/material";

function Address({ activeStep, isLastStep, handleNext, handleBack }) {
  // Form data
  const formValidation = validations[FormNames.ADDRESS_FORM];
  const formFields = fields[FormNames.ADDRESS_FORM];
  const initialValues = initial[FormNames.ADDRESS_FORM];

  const handleSubmit = async (values, actions) => {
    try {
      console.log("ADDRESS HANDLE SUBMIT")
      console.log(values)

      handleNext({
        addresses: values.addresses
      })
    } catch (error) {
      console.log(error)
      // TODO
      // openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
        <Form id={FormNames.ADDRESS_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="bold">
              Address
            </SoftTypography>
            <FieldArray
              name={formFields.addresses.name}
              error={errors.addresses}
              render={arrayHelpers => (
                <SoftBox mt={1.625}>
                  {values.addresses?.map((addressElem, index) => (
                    <SoftBox key={"address=" + index}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={11}>
                          <Grid container columnSpacing={3}>
                            <Grid item xs={12} sm={7}>
                              <FormField
                                type={formFields.addresses.props.addressLine.type}
                                label={formFields.addresses.props.addressLine.label}
                                name={`addresses[${index}].addressLine`}
                                value={values.addresses[index].addressLine}
                                placeholder={formFields.addresses.props.addressLine.placeholder}
                                error={errors.addressLine}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormField
                                type={formFields.addresses.props.addressNumber.type}
                                label={formFields.addresses.props.addressNumber.label}
                                name={`addresses[${index}].addressNumber`}
                                value={values.addresses[index].addressNumber}
                                placeholder={formFields.addresses.props.addressNumber.placeholder}
                                error={errors.addressNumber}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormField
                                type={formFields.addresses.props.city.type}
                                label={formFields.addresses.props.city.label}
                                name={`addresses[${index}].city`}
                                value={values.addresses[index].city}
                                placeholder={formFields.addresses.props.city.placeholder}
                                error={errors.city}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <FormField
                                type={formFields.addresses.props.zipCode.type}
                                label={formFields.addresses.props.zipCode.label}
                                name={`addresses[${index}].zipCode`}
                                value={values.addresses[index].zipCode}
                                placeholder={formFields.addresses.props.zipCode.placeholder}
                                error={errors.zipCode}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              {/* [CHANGE COUNTRY TO LIST] remove below FormField and uncomment previous */}
                              <FormField
                                type={formFields.addresses.props.country.type}
                                label={formFields.addresses.props.country.label}
                                name={`addresses[${index}].country`}
                                value={values.addresses[index].country}
                                placeholder={formFields.addresses.props.country.placeholder}
                                error={errors.country}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Action
                    </SoftTypography>
                  </SoftBox>
                          <Tooltip title="Remove address" placement="bottom" arrow>
                            <SoftBox>
                              <IconButton variant="gradient" color="error" size="medium"
                                disabled={values.addresses.length <= 1}
                                onClick={() => {
                                  if (values.addresses.length > 1) {
                                    arrayHelpers.remove(index)
                                  }
                                }}>
                                <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                              </IconButton>
                            </SoftBox>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Divider />
                    </SoftBox>
                  ))}
                  <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                    <Tooltip title="Add address" placement="bottom" arrow>
                      <SoftButton color="secondary" size="small"
                        onClick={() => arrayHelpers.push({
                          addressLine: "",
                          addressNumber: "",
                          city: "",
                          country: countryOptions[0].label,
                          zipCode: "",
                        })}
                      >
                        + add
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              )}
            />
            <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} />
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default Address;
