/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";

function PromoteBusiness({initPromotionStatus, businessId, businessName}) {
  const { t, i18n } = useTranslation();
  const [promotionStatus, setPromotionStatus] = useState(initPromotionStatus);

  const handleSetPromotionStatus = () => setPromotionStatus(!promotionStatus);

  useEffect(() => {
    setPromotionStatus(initPromotionStatus)
  }, []);

  const showAlert = (title, questionTurnOn, questionTurnOff, confirmButtonText, cancelButtonText, successTitle, successMsg, errorTitle, errorMsg) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-secondary",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `<h5>${title}</h5>`,
        text: promotionStatus ? questionTurnOn :  questionTurnOff,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: true,
      })
      .then(async (result) => {

        if (result.value) {
          try {

            await CreatePostRequest(BackRequests.PROMOTE_BUSINESS_ITEM,
              {
                businessItemId: businessId,
                userId: JSON.parse(localStorage.getItem("userId")),
                status: promotionStatus
              });
            newSwal.fire(successTitle, successMsg, "success");
          } catch (error) {
            console.log("ERRRRROROOR")
            console.log(error)
            newSwal.fire(errorTitle, errorMsg, "error");
          }
        }
      });
  };

  const onPromoteClick = async () => {
    showAlert(
      t('businessItem.settings.promoteSection.confirmDialog.title'),
      t('businessItem.settings.promoteSection.confirmDialog.questionTurnOn') + businessName + "?",
      t('businessItem.settings.promoteSection.confirmDialog.questionTurnOff') + businessName + "?",
      t('businessItem.settings.promoteSection.confirmDialog.promote'),
      t('businessItem.settings.promoteSection.confirmDialog.cancel'),
      t('businessItem.settings.promoteSection.confirmDialog.successTitle'),
      t('businessItem.settings.promoteSection.confirmDialog.successMsg') + businessName,
      t('businessItem.settings.promoteSection.confirmDialog.errorTitle'),
      t('businessItem.settings.promoteSection.confirmDialog.errorMsg') + businessName,
    );
  }

  return (
    <Card id="delete-account">
      <SoftBox p={3} pb={0} lineHeight={1} mb={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">{t('businessItem.settings.promoteSection.title')}</SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" color="text" fontWeight="regular">
          {t('businessItem.settings.promoteSection.text')}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
          <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
            <SoftBox mr={2} lineHeight={0}>
              <SoftTypography display="block" color="text" variant="button" fontWeight="regular">
                {t('businessItem.settings.promoteSection.status')}
              </SoftTypography>
            </SoftBox>
            <Switch checked={promotionStatus} onChange={handleSetPromotionStatus}/>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton variant="gradient" color="secondary" sx={{ height: "100%" }} onClick={onPromoteClick}>
              {t('businessItem.settings.promoteSection.button')}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default PromoteBusiness;
